import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useMutateRenameMyFolder } from "../../../api/MyLibrary/MyFolders.api";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useMutateRenameExternalFolder } from "../../../api/MyLibrary/Links.api";
import { notify } from "../../../utils/Notify";

interface Props {
  show: boolean;
  title: string;
  foldername: string;
  folderId: string;
  isExternalLinks: boolean;
}

const RenameFolderModal = ({
  show,
  title,
  foldername,
  folderId,
  isExternalLinks,
}: Props) => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const [name, setName] = useState<string>("");

  const { mutate: renameFolder } = useMutateRenameMyFolder();
  const { mutate: renameExternalFolder } = useMutateRenameExternalFolder();

  const handleDelete = async () => {
    if (name && name.length > 20) {
      notify("warning", "Folder Name should not be greater than 20 characters");
      return;
    }
    if (name.length === 0) {
      notify("error", "Please enter Folder Name");
      return;
    }
    if (name === foldername) {
      notify("error", "Folder name can't be same");
      return;
    }

    const formData = new FormData();
    formData.append("folder_id", folderId);
    formData.append("new_folder_name", name);

    if (isExternalLinks) {
      renameExternalFolder({
        formData: formData,
        csrf: selector.csrf_token,
      });
      dispatch(hideModal());
    } else {
      renameFolder({ formData: formData, csrf: selector.csrf_token });
      dispatch(hideModal());
    }
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="RenameFolderModal-Title"
      aria-describedby="RenameFolderModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          // width: "35vw",
          width: { xs: "100%", sm: "60%", md: "40%", lg: "35vw", xl: "35vw" },
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {title}&nbsp;-&nbsp;{foldername}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Text sx={{ mb: 1 }}>Please Enter Name</Text>
          <TextField
            size="small"
            placeholder="Enter Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Box>

        <Box sx={{ width: "100%", my: 2 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleDelete}
          >
            Rename
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RenameFolderModal;
