import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { prefernecesStyles as styles } from "./Preferences.styles";
import { useAppSelector } from "../../store/hooks";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  GetMyPreferenceData,
  GetMyPreferenceDataType,
} from "../../types/GetMySubjects.types";
import {
  useMutateDeleteMySources,
  useMutateModifyMySources,
} from "../../api/Preferences/Subjects.api";

interface Props {
  l1l2Data: GetMyPreferenceData;
}

const SourcePreference = ({ l1l2Data }: Props) => {
  const selector = useAppSelector((state) => state.login);

  const modifySourceHandler = useMutateModifyMySources();
  const deleteSourceHandler = useMutateDeleteMySources();
  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );
  //Select Dropdown
  const [selectedDataTypes, setSelectedDataTypes] = useState<number[]>([]);
  const [selectedDataTypeNames, setSelectedDataTypeNames] = useState<string[]>([
    "Select Sources",
  ]);
  const [isOpen, setIsOpen] = useState(false);

  //Handle Getting names of selected datatypes
  const handleDataTypeName = useCallback(
    (arr: number[]) => {
      let names: string[] = [];
      if (l1l2Data) {
        l1l2Data?.DataTypes?.filter((item) => item?.datatype_id !== 2)?.forEach(
          (item, i) => {
            if (arr.includes(item?.datatype_id))
              names.push(item?.datatype_name);
          }
        );
      }
      names?.length > 0
        ? setSelectedDataTypeNames(() => names)
        : setSelectedDataTypeNames(() => [
            "Select Document type you want to add to your preferences",
          ]);
    },
    [l1l2Data]
  );

  useEffect(() => {
    if (l1l2Data?.DataTypes?.length > 0) {
      const arr = l1l2Data?.DataTypes?.filter((x) => x.is_favorite)?.map(
        (y) => y.datatype_id
      );
      setSelectedDataTypes(() => arr);
      handleDataTypeName(arr);
    }
  }, [handleDataTypeName, l1l2Data]);

  const handleSourceDelete = (id: number) => {
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("resource_type_ids", id.toString());
    deleteSourceHandler.mutate({ formData });
  };

  function clearAllSourceType() {
    let ids = "1,2,3,4,5,8,24";
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("resource_type_ids", ids);
    deleteSourceHandler.mutate({ formData });
  }

  const handleDataTypeSelect = (data: GetMyPreferenceDataType) => {
    //If id exists in selectedDatatypes
    if (selectedDataTypes.includes(data.datatype_id)) {
      //Remove
      const newArr = selectedDataTypes.filter((x) => x !== data.datatype_id);
      handleDataTypeName(newArr);
      setSelectedDataTypes(() => newArr);
    } else {
      //Add
      const newArr = [...selectedDataTypes, data.datatype_id];

      handleDataTypeName(newArr);
      setSelectedDataTypes(() => newArr);
    }
  };

  const handleAddDataTypes = () => {
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("resource_type_ids", selectedDataTypes.join(","));
    modifySourceHandler.mutate({ formData });
    setIsOpen(false);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Select
          multiple
          value={selectedDataTypeNames}
          input={<OutlinedInput onClick={() => setIsOpen(!isOpen)} />}
          renderValue={(selected) => selected.join(", ")}
          sx={{
            // width: "100%",
            width: { xs: "100%", sm: "50%", md: "50%", lg: "50%", xl: "50%" },
            color:
              selectedDataTypeNames?.length === 1 &&
              selectedDataTypeNames[0] ===
                "Select Document type you want to add to your preferences"
                ? "#9b9b9b"
                : "",
          }}
          displayEmpty
          open={isOpen}
          MenuProps={{
            anchorReference: "anchorPosition",
            anchorPosition: {
              top: window.innerHeight / 2,
              left: window.innerWidth / 2,
            },
            transformOrigin: {
              vertical: "center",
              horizontal: "right",
            },
          }}
        >
          {l1l2Data &&
            l1l2Data.DataTypes?.map((type) => (
              <>
                {type.datatype_id !== 2 &&
                type.datatype_id !== 5 &&
                type.datatype_id !== 24 &&
                customerData?.datatypes?.find(
                  (ele: any) => ele?.dat_resourcetype_id == type?.datatype_id
                )?.dat_resourcetype_id ? (
                  <MenuItem
                    key={type.datatype_id}
                    value={type.datatype_id}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDataTypeSelect(type);
                    }}
                  >
                    <Checkbox
                      checked={selectedDataTypes.includes(type.datatype_id)}
                    />
                    <Text>{type.datatype_name}</Text>
                  </MenuItem>
                ) : null}
              </>
            ))}

          <Box sx={{ width: "100%", px: 2, py: 1 }}>
            <Button
              sx={styles.addDataTypes}
              variant="contained"
              onClick={handleAddDataTypes}
            >
              Add
            </Button>
            <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
          </Box>
        </Select>
      </Box>

      {l1l2Data?.DataTypes?.length > 0 ? (
        <Box sx={styles.chipsWrapper}>
          <Button sx={styles.chipClearAll} onClick={clearAllSourceType}>
            Clear All
          </Button>

          {l1l2Data?.DataTypes?.filter((y) => y.is_favorite)?.map((x, i) => (
            <>
              {x.datatype_id !== 2 ? (
                <Chip
                  key={i}
                  label={x.datatype_name}
                  variant="outlined"
                  onDelete={() => handleSourceDelete(x.datatype_id)}
                />
              ) : null}
            </>
          ))}
        </Box>
      ) : (
        <p
          style={{
            padding: "1rem",
          }}
        >
          Add a Source
        </p>
      )}
    </>
  );
};

export default SourcePreference;
