import { SxProps } from "@mui/material/styles";

const queryNumber: SxProps = {
  background: "#F9FAFB",
  border: "1px solid #D1D5DB",
  width: "62px",
  height: "62px",
  marginRight: 2,
  marginTop: "38px",
  borderRadius: "6px",
};

const queryEditor: SxProps = {
  display: "flex",
  alignItems: "start",
  gap: { xs: 0, sx: 1, md: 2, lg: 3 },
  marginTop: 2,
  flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
  
};

export const queryBuilderStyles = {
  queryNumber,
  queryEditor,
};
