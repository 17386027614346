import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import SortByRelevenceForBrowser from "../../components/basicSearchTopBar/sortByRelevenceBrowser";
import BrowserJournalBlocks, {
  AccessLock,
} from "../../components/browserJournalblocks/browserJournalblocks";
import React from "react";
import { Colors } from "../../utils/constants";
import AlphabeticalFilter from "../alphabeticalFilter/AlphabeticalFilter";
import CollectionsComponentOfBrowser from "../leftBarComponent/collectionsOfBrowser";
import SearchFilterComponentOfBrowser from "../leftBarComponent/searchFilterOFBrowser";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { getBooks, getJournals } from "../../api/browserGetPublisher";
import { useDispatch } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import { getAlertDetails, markFav } from "../../api/browseJournal";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import RSSAlert from "../../components/resultsPopups/RSSAlert";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import {
  browseDetailsSearchResult,
  updateknownAlphasearchPackageArticle,
} from "../../store/slice/browserFilterSliceDetails";
import SearchFilterComponentOfJournalDetails from "../leftBarComponent/searchFilterOfDetails";
import { Clear, FilterAlt, Padding } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Pagination from "../../components/pagination";
import { updateBrowserPage } from "../../store/slice/browserFilterSliceDetails";
import { updateBrowserRow } from "../../store/slice/browserFilterSliceDetails";
import {
  LinkResolverType,
  getBulkLinkRecords,
} from "../../api/linkResolverApi";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlphabeticalFilterForAuthor from "../alphabeticalFilter/AlphabeticalFilterForAuthor";
import { useAppSelector } from "../../store/hooks";
import SJRModal from "../rightDrawer/SJRModal";
import { showIsProfileToast } from "../../utils/helper";
import { usagelogApi } from "../../api/usageReportApi";
import { MathJax } from "better-react-mathjax";
import { updateBrowseJournalSearch } from "../../store/slice/browseJournal";
import {
  updateSort,
  updateSortName,
} from "../../store/slice/browseFilterSlice";
import BookBlocks from "./bookblock";
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BookPackageArticle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    publisherId,
    publisherName,
    publisherFilter,
    packageId,
    packageName,
    packageDetails,
    fromPackage,
    from,
  } = location.state;
  const [sort, setSort] = React.useState("");
  const [selectedRankSorting, setSorting] = useState("Ranking");

  const [journalSearchResults, setJournalSearchResults] = React.useState<any[]>(
    []
  );

  const [journalName, setJournalName] = React.useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [srjData, setSrjData] = useState("0");
  const [jsonFacet, setJsonFacet] = React.useState<any>({});
  const [AllNoOfResults, setAllNoOfResults] = React.useState(0);
  const [cite, setCite] = useState(false);
  const [rssAlert, setRssAlert] = useState({});
  const [alertType, setAlertType] = useState("");
  console.log("alertType", alertType);
  const details = location?.state?.data;
  const [publicationValue, setPublicationValue] = useState("");
  const [collectionsValue, setcollectionsValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [apipage, setApiPage] = React.useState(1);
  const [keywords, setKeywords] = React.useState<any[]>([]);
  const [selectedLetterTags, setSelectedLetterTags] = useState(
    Array(28).fill(false)
  );
  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);
  const userDatas = useAppSelector((state) => state.customer.customerDetails);
  const [FilterDrawer, setFilterDrawer] = useState(false);
  const [filters, setFilters] = useState<any>("");
  const [myPersonalLibrary, setMyPersonalLibrary] = useState(false);
  const [enabledTags, setEnabledTags] = React.useState<any[]>([]);
  const [collectionFilter, setCollectionFilter] = useState("");

  const [selectedTag, setSelectedTag] = useState<String>("");
  const [linkToDisplay, setLinkToDisplay] = useState<LinkResolverType[] | null>(
    null
  );
  const [message, setMessage] = useState({ messages: "", open: false });

  const customerId = useSelector(
    (state: any) => state?.customer?.customerDetails?.informaticscustomer_id
  );
  const consortia_virtualid = useAppSelector(
    (state) => state.customer.customerDetails?.consortia_virtualid
  );
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const profileStatus = sessionStorage.getItem("isProfileUser");

  const searchResults = useSelector(
    (state: any) => state.browseJournalSearch.browserResult
  );

  const PackageResults = useSelector(
    (state: any) => state.browserDetailsSlice.browserResult
  );

  const handleCite = async (item: any, alertSet: any) => {
    setAlertType(alertSet);
    setCite(!cite);
    setRssAlert(item);
    setJournalResults();
    // let response = await citeArticle();
  };

  async function fetchLinkDetails(arr: any) {
    if (!Array.isArray(arr)) return;
    let formData = new FormData();
    let x = arr?.map((obj: any) => {
      let object: any = {
        customer_id: customerId,
        article_id: obj?.article_id,
        consortia_virtualid: consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      return object;
    });

    let formApiData = {
      data: x,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      setLinkToDisplay(response.data);
    }
  }
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      messages: "",
      open: false,
    });
  };

  // for alphabeticals

  const contextId =
    selectedFilters.collectionsValueJournal === "J-GateCollection"
      ? 1
      : selectedFilters.collectionsValueJournal === "Consortia Collections"
      ? 2
      : selectedFilters.collectionsValueJournal === "My Library Collections"
      ? 3
      : selectedFilters.collectionsValueJournal ===
        "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionsValueJournal === "Open Access Collections"
      ? 7
      : null;

  const logPackageUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const PackagePublisherId = details?.publisher_id
      ? details?.publisher_id[0]
      : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      PackagePublisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const updateSelectedTags = (element: any, index: any) => {
    let temp = Array(28).fill(false);
    logPackageUsageData(86, packageDetails);
    if (selectedLetterTags[element]) {
      setSelectedLetterTags(temp);
      setSelectedTag("");
    } else {
      if (element == 0) {
        temp[0] = true;
        setSelectedLetterTags(temp);
        setSelectedTag("");
      } else if (element == 1) {
        temp[1] = true;
        setSelectedLetterTags(temp);
        setSelectedTag("");
      } else {
        temp[element] = true;
        setSelectedLetterTags(temp);
        setSelectedTag(String.fromCharCode(element + 63));
      }
    }
  };
  const open = Boolean(anchorEl);

  const pagination = useSelector((state: any) => state.browserDetailsSlice);
  let count = Math.ceil(pagination?.browserResult?.hits / pagination.rows);

  const alertsAndFav = useSelector(
    (state: any) => state.browseJournalSearch?.alertFav
  );

  const enabledTagsPackagearticle = useSelector(
    (state: any) => state.browserDetailsSlice.knownAlphasearchPackageArticle
  );

  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );

  function handlePageChange(p: any) {
    dispatch(updateBrowserPage(p));
    // setPage(p);
    // isMounted.current = true;
    // setJournalResults();
    // goToTop();
  }

  function getRowsPerPage(value: number) {
    // isMounted.current = true;
    dispatch(updateBrowserRow(value));
    // setRow(value);
  }
  const searchReducer: any = useSelector((state: any) => state.searchReducer);
  const independentFilter = useSelector(
    (state: any) => state.browseFilterSlice.currentUpdate
  );

  const loginDetails: any = useSelector((state: any) => state.login);

  const currentYear: number = new Date().getFullYear();

  //   CONTENT DATA
  const setJournalResults = async () => {
    let subjects = "";
    let dataTypes = "";

    if (searchReducer?.subjectType?.length > 0) {
      searchReducer?.subjectType?.map((element: any, index: any) => {
        subjects += `${element} OR `;
      });
      subjects = `(${subjects.slice(0, -3)})`;
    }

    if (searchReducer?.dataType?.length > 0) {
      searchReducer?.dataType?.map((element: any) => {
        dataTypes += `${element} OR `;
      });
      dataTypes = `(${dataTypes.slice(0, -3)})`;
    }

    let resourceList = "";
    resourceList = resourceList.replace(/\s/g, "\\ ");
    keywords?.map((element) => {
      resourceList += `*${element.replace(/\s/g, "\\ ")}* AND `;
    });
    resourceList = `${resourceList.slice(0, -5)}`;

    let apiJournalData: any = {
      page: pagination.page,
      rows: pagination.rows,
      profileuserid: sessionStorage.getItem("user_id"),
      sort: "book_name asc",
      sessionid: sessionStorage.getItem("session_key"),
      facet_fields:
        "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,book_name_initial",
      fq__subjects_id_l2: subjects,
      fq__resource_type: 12,
    };

    if (independentFilter)
      Object.assign(apiJournalData, {
        fq__dateofpublication: "[NOW/YEAR TO NOW/YEAR]",
        createddate: "[NOW-60DAYS TO NOW]",
      });
    if (publisherId) {
      Object.assign(apiJournalData, { fq__publisher_id: publisherId });
    }
    if (packageId)
      Object.assign(apiJournalData, { fq__aggregator_package_id: packageId });
    if (
      selectedFilters.sortName !== "" &&
      selectedFilters.sortName !== "Journal"
    )
      Object.assign(apiJournalData, { sort: selectedFilters.sort });
    if (selectedTag !== "")
      Object.assign(apiJournalData, { fq__resource_name_initial: selectedTag });
    if (
      selectedFilters.collectionsValueJournal === "Open Access Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      Object.assign(apiJournalData, { fq__fulltext_ACL: "(0)" });
    if (
      selectedFilters.collectionsValueJournal === "My Library Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      Object.assign(apiJournalData, {
        fq__fulltext_ACL: `${userDatas?.my_library_filter}`,
      });
    if (
      selectedFilters.collectionsValueJournal === "Consortia Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      Object.assign(apiJournalData, {
        fq__fulltext_ACL: `${userDatas?.consortia_filter}`,
      });

    if (
      selectedFilters.collectionsValueJournal === "ONOS Collections" &&
      !filters.includes("&fq__fulltext_ACL")
    )
      Object.assign(apiJournalData, { fq__fulltext_ACL: true });
    let apiResults: any[] = [...journalSearchResults];
    let publisherFilters = publisherFilter;
    if (!publisherFilters) publisherFilters = "";
    const response = await getBooks(
      resourceList.length > 2
        ? "?" +
            objectToQueryString(apiJournalData) +
            `&fq__book_name_tk=${resourceList}${publisherFilters}${filters}`
        : "?" +
            objectToQueryString(apiJournalData) +
            publisherFilters +
            `${filters}`
    );
    dispatch(browseDetailsSearchResult(response?.data.data));
    // setJournalSearchResults(response?.data.data);
    let enabledTagsList: any[] = ["ALL", "(0-9)"];

    response?.data.data.facets.facet_fields?.resource_name_initial?.map(
      (ele: any) =>
        Object.keys(ele)[0]?.charAt(0)?.toUpperCase().match(/[a-z]/i)
          ? enabledTagsList.push(Object.keys(ele)[0]?.charAt(0)?.toUpperCase())
          : null
    );
    dispatch(updateknownAlphasearchPackageArticle(enabledTagsList));
    await fetchLinkDetails(response?.data?.data?.docs);

    if (response) {
      setJsonFacet(response?.data.data.facets.facet_fields);
      apiResults = [...response?.data.data.docs];
      setAllNoOfResults(response?.data.data.hits);
      let apiJson: any = {
        usermaster_id: sessionStorage.getItem("user_id"),
        resourcemaster_ids: [],
      };
      if (response.data.data.docs.length > 0) {
        response.data.data.docs?.map((element: any, index: any) => {
          apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.concat(
            element.bookmaster_id
          );
        });
      }

      apiJson.resourcemaster_ids = apiJson.resourcemaster_ids.toString();
      let enabledTagsList: any[] = ["ALL", "(0-9)"];
      response.data.data.facets.facet_fields?.resource_name_initial?.map(
        (ele: any) =>
          Object.keys(ele)[0]?.charAt(0)?.toUpperCase().match(/[a-z]/i)
            ? enabledTagsList.push(
                Object.keys(ele)[0]?.charAt(0)?.toUpperCase()
              )
            : null
      );
      setEnabledTags(enabledTagsList);
      const alertresponse = await getAlertDetails(
        "?" + objectToQueryString(apiJson)
      );

      if (alertresponse) {
        alertresponse.data.data[0][0]?.map((element: any, index: any) => {
          apiResults[index] = {
            data: apiResults[index],
            isFavourite: element?.favorite_set,
            alertSet: element?.notification_set,
            alert_type: element?.alert_type,
          };
        });

        setJournalSearchResults([...apiResults]);
      }
    }
  };

  const search = () => {
    // if (
    //   !keywords.find((selectedEle) => selectedEle == journalName.trim()) &&
    //   journalName.trim() !== ""
    // ) {
    dispatch(updateBrowserPage(1));
    setKeywords(keywords.concat(journalName.trim()));
    // setJournalResults()
    // }
  };
  React.useEffect(() => {
    const debounce = setTimeout(() => {
      setJournalResults();
    }, 100);
    return () => {
      clearTimeout(debounce);
    };
  }, [
    selectedTag,
    filters,
    pagination.page,
    selectedRankSorting,
    keywords,
    independentFilter,
    pagination.rows,
    selectedFilters.sortName,
  ]);
  useEffect(() => {
    updateBrowseJournalSearch(true);
    return () => {
      dispatch(updateSort(""));
      dispatch(updateSortName("Journal"));
    };
  }, []);

  function closeSjrModal() {
    setIsOpen(false);
  }

  // const contextId =
  //   selectedFilters.collectionsValueJournal === "J-GateCollection"
  //     ? 1
  //     : selectedFilters.collectionsValueJournal === "Consortia Collections"
  //     ? 2
  //     : selectedFilters.collectionsValueJournal === "My Library Collections"
  //     ? 3
  //     : selectedFilters.collectionsValueJournal ===
  //       "MyPersonalLibraryCollection"
  //     ? 4
  //     : selectedFilters.collectionsValueJournal === "Open Access Collections"
  //     ? 7
  //     : null;

  const logBasicSearchUsageData = (usageActionID: any, data?: any) => {
    const user_ipv4_address = sessionStorage.getItem("user_ipv4_address");

    usagelogApi(
      profileData.user_id,
      profileData.informaticscustomer_id,
      profileData1?.consortiamaster_id,
      usageActionID,
      contextId,
      null,
      null,
      null,
      publisherId,
      data.data.resourcemaster_id || null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      profileData.session_key,
      1,
      null
    );
  };

  const handlePublisherDetailsClick = (item: any) => {
    const yearData = Array.isArray(item?.data?.year_from)
      ? item?.data?.year_from[0]
      : item?.data?.year_from;
    if (currentYear === yearData) {
      logPackageUsageData(90, item?.data);
    } else {
      logPackageUsageData(87, item?.data);
    }
    logPackageUsageData(92, item?.data);
    logPackageUsageData(91, item?.data);
    navigate("/BrowseJournalTableSearch/BrowseJournalSearch", {
      state: {
        item: item?.data,
        alerts: item?.data,
      },
    });
  };
  return (
    <div>
      <Box sx={webStyle.flexRow} mr="3%" ml="3%" mt={"1%"}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosIcon sx={{ alignSelf: "center" }} fontSize="small" />
        </IconButton>
        <Typography sx={webStyle.textResult} variant="h5">
          Back
        </Typography>
      </Box>
      <Box
        mr="3%"
        ml="3%"
        mt={"1%"}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <TextField
          onChange={(e) => setJournalName(e.target.value)}
          size="small"
          placeholder="Search Journal"
          sx={[
            webStyle.textInputText,
            { alignSelf: "center", flex: 1, marginRight: "0.5%" },
          ]}
          onKeyPress={(e) => {
            if (e.key === "Enter" && journalName) {
              search();
            }
          }}
        ></TextField>

        <Button variant="contained" sx={webStyle.button} onClick={search}>
          Search
        </Button>
      </Box>
      <Box ml={"3%"} mr={"3%"} mt={"1%"} display={"flex"}>
        {/* <AlphabeticalFilter
          selectedTags={selectedLetterTags}
          selectOnClick={updateSelectedTags}
        /> */}
        <AlphabeticalFilterForAuthor
          selectedTags={selectedLetterTags}
          selectOnClick={updateSelectedTags}
          enabledTags={enabledTagsPackagearticle}
        />
      </Box>{" "}
      <Box sx={webStyle.mainContainer}>
        <Box ml={"3%"} mr={"3%"}>
          <Grid container mt={"2%"}>
            <Grid
              item
              xs={3}
              sx={{ display: { xs: "none", sm: "none", md: "initial" } }}
            >
              <Box sx={{ paddingBlock: 2 }}>
                <Typography
                  fontWeight={"700"}
                  fontSize={"20px"}
                  lineHeight={"24px"}
                  style={webStyle.resulteText}
                >
                  Results{" "}
                  {pagination?.browserResult?.hits
                    ? pagination?.browserResult?.hits.toLocaleString("en-US")
                    : ""}
                </Typography>
              </Box>

              <CollectionsComponentOfBrowser
                setMyPersonalLibrary={setMyPersonalLibrary}
                setFilters={setFilters}
                setCollectionFilter={setCollectionFilter}
                from={from}
              ></CollectionsComponentOfBrowser>

              <SearchFilterComponentOfJournalDetails
                setFilters={setFilters}
                packageDetails={packageDetails}
              ></SearchFilterComponentOfJournalDetails>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Box bgcolor={"#f5f5f5"}>
                {/* Header Div */}
                <Box
                  sx={[
                    webStyle.flexBox,
                    {
                      marginTop: "1%",
                      justifyContent: { xs: "space-between", md: "flex-end" },
                      textAlign: "center",
                      alignItems: "center",
                    },
                  ]}
                >
                  <Typography
                    fontWeight={"700"}
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    style={webStyle.resulteText}
                    sx={{
                      display: { xs: "initial", sm: "initial", md: "none" },
                    }}
                  >
                    Results1{" "}
                    {pagination?.browserResult?.hits
                      ? pagination?.browserResult?.hits.toLocaleString("en-US")
                      : ""}
                  </Typography>
                  <IconButton
                    sx={{
                      display: {
                        xs: "initial",
                        sm: "initial",
                        md: "none",
                      },
                      mr: "15px",
                    }}
                    onClick={() => {
                      setFilterDrawer(true);
                    }}
                    size="small"
                    color="primary"
                  >
                    <FilterAlt />
                  </IconButton>
                  <SortByRelevenceForBrowser
                    setSort={setSorting}
                    selectedRankSort={selectedRankSorting}
                    packageDetails={packageDetails}
                    // sortData={
                    //   searchResults?.facets?.facet_fields?.filter_metrix
                    // }
                    sortData={
                      PackageResults?.facets?.facet_fields?.filter_metrix
                    }
                  ></SortByRelevenceForBrowser>
                </Box>
                <Grid container style={{ minHeight: "60vh" }}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        border: "1px solid #D1D5DB",
                        margin: "2% 0%",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography sx={webStyle.titleText}>
                        <MathJax>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: publisherName,
                            }}
                          />
                        </MathJax>
                      </Typography>
                    </Box>

                    {journalSearchResults?.map((item: any, i: number) => {
                      return <BookBlocks item={item?.data} />;
                    })}
                  </Grid>
                </Grid>
                <Box
                  my={3}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Pagination
                    count={count}
                    handlePageChange={handlePageChange}
                    rowsPerResult={pagination.rows}
                    getRowsPerPage={getRowsPerPage}
                    currentPage={pagination.page}
                  />
                  {/* <Spinner isLoading={isLoading} /> */}
                </Box>
                {/* <UsePagination></UsePagination> */}
                {/* <PaginationRounded></PaginationRounded> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <RSSAlert
        setJournalResults={setJournalResults}
        openViewModal={cite}
        handleCloseViewModal={handleCite}
        alerts={alertType}
        id={rssAlert}
        setMessage={setMessage}
      ></RSSAlert>
      <Snackbar
        open={message.open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          {message.messages}
        </Alert>
      </Snackbar>
      <SJRModal isOpen={isOpen} handleClose={closeSjrModal} sjrId={srjData} />
      <Drawer
        open={true}
        sx={{ display: FilterDrawer ? "block" : "none" }}
        ModalProps={{
          disableScrollLock: true,
        }}
      >
        <Box bgcolor={"#FFF"} width={"100%"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignSelf: "center",
              padding: "1%",
            }}
          >
            <Typography
              variant="h6"
              fontFamily={"Lora"}
              fontWeight={"700"}
              fontSize={"24px"}
            >
              Filters
            </Typography>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                setFilterDrawer(false);
              }}
            >
              <Clear />
            </IconButton>
          </Box>
        </Box>
        <Divider></Divider>
        <Box width={"100%"}>
          <CollectionsComponentOfBrowser
            setMyPersonalLibrary={setMyPersonalLibrary}
            setFilters={setFilters}
            setCollectionFilter={setCollectionFilter}
            from={from}
          ></CollectionsComponentOfBrowser>

          <SearchFilterComponentOfJournalDetails
            setFilters={setFilters}
            packageDetails={packageDetails}
          ></SearchFilterComponentOfJournalDetails>
        </Box>
      </Drawer>
    </div>
  );
};

export default BookPackageArticle;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    fontSize: 24,
    color: Colors.black,
  },
  button: {
    margin: "0.5%",
    paddingLeft: "3%",
    paddingRight: "3%",
  },
  textInputText: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
  },
  resulteText: {
    fontFamily: "Helvetica Neue",
    fontsSyle: "normal",
    fontWeight: 700,
  },
  mainContainer: {
    backgroundColor: "#f5f5f5",
    flex: 1,
    minHeight: "100vh",
  },

  container: {
    backgroundColor: Colors.white,
    padding: "2%",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "red",
    marginTop: "1%",
    cursor: "pointer",
  },
  text: {
    fontFamile: "Lora",
  },
  subText: {
    color: Colors.gray_600,
    fontFamily: "Helvetica Neue",
    fontSize: "0.8rem",
  },
  titleText: {
    fontFamily: "Lora",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#7852FB",
    margin: "2% 1%",
  },
};
