import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Tooltip,
} from "@mui/material";
import { Colors } from "../../utils/constants";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Paper from "@mui/material/Paper";

import AbstractView from "./abstractIndex";
import { useAppSelector } from "../../store/hooks";
import validator from "validator";
import { redirectLink } from "../../utils/helper";
import { MathJax } from "better-react-mathjax";
import {
  browserJournalGetVolume,
  getListOfJournal,
} from "../../api/browseJournal";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { useNavigate } from "react-router-dom";
import {
  getCardData,
  updateBrowserPage,
  updateLoadingState,
} from "../../store/slice/browseJournal";
import { useDispatch } from "react-redux";
import { bowserJournalSearchCard } from "../../api/browseJournalSearch";
import { updateVolumeOfTOC } from "../../store/slice/browserFilterSliceDetails";
import BrokenLinkIcon from "../../assets/img/broken_image.png";
import ReportIssue from "../resultsPopups/ReportIssue";
import { notify } from "../../utils/Notify";
import axios from "axios";
import api from "../../api/endpoints";
import NoResultModal from "../modals/NoResultFound";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import { SaverArticlesType } from "../../types/interface";
import { updateSavedArticles } from "../../store/slice/searchSlice";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
//import Tooltip from "../tootTips/hoverOverToolTips";
//import IconButton from "@mui/material/IconButton";
interface P {
  handleDawer: (data: any) => void;
  setViewDetails: Dispatch<SetStateAction<boolean>>;
  state: boolean;
  details: any;
  journalHistory: any;
  setDetails: any;
  setYearDropDownList?: Dispatch<SetStateAction<any[]>>;
  setValue?: any;
  setVolumeDropDownList?: any;
  setValueOne?: any;
  setLinkToDisplay?: any;
}

const JournalViewDetails: React.FC<P> = ({
  handleDawer,
  setViewDetails,
  state,
  details,
  journalHistory,
  setDetails,
  setYearDropDownList,
  setValue,
  setVolumeDropDownList,
  setValueOne,
  setLinkToDisplay,
}) => {
  // const [top, setTop] = useState(true);
  const [abstractTop, setAbstractTop] = useState(false);

  let userId = sessionStorage.getItem("user_id");
  let session_key = sessionStorage.getItem("session_key");
  let informaticscustomerId = sessionStorage.getItem("informaticscustomer_id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openViewModal, setOpenViewModal] = useState<Boolean>(false);
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const selector = useAppSelector((state) => state.login);
  const allowProfile = customerDetails?.allow_profile;
  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);
  const [noresultModal, setNoResultModal] = useState(false);

  const handlePopUp = () => {
    setAbstractTop(!abstractTop);
  };

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[]
  ) => {
    // setFormats(newFormats);
  };

  const getDataList = useAppSelector(
    (state) => state.browseJournalSearch?.getCardDataDetails?.docs
  );

  const sortVolumesFunction = (data: any) => {
    const sortedVolumes = data?.sort((a: any, b: any) => {
      if (a?.volumefrom === "Early-Online") return -1; // a should come first
      if (b?.volumefrom === "Early-Online") return 1; // b should come first

      const volumeA = parseInt(a?.volumefrom, 10);
      const volumeB = parseInt(b?.volumefrom, 10);
      const issueA = parseInt(a?.issuenumberfrom, 10);
      const issueB = parseInt(b?.issuenumberfrom, 10);

      // // First sort by volume, then by issue number
      // if (volumeA !== volumeB) {
      //   return volumeA - volumeB;
      // }
      // return issueB - issueA;
    });

    return sortedVolumes;
  };

  async function closeNoResultModal() {
    setNoResultModal(false);
  }

  async function fetchLinkDetails(arr: any) {
    if (!Array.isArray(arr)) return;
    let formData = new FormData();
    let sessionCustomerId = sessionStorage.getItem("informaticscustomer_id");

    let x = arr?.map((obj: any) => {
      let object: any = {
        customer_id: sessionCustomerId,
        article_id: obj?.article_id,
        // consortia_virtualid: consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.consortia_virtualid) {
        object.consortia_virtualid = obj?.consortia_virtualid;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      if (obj?.is_onos_resource) {
        object.is_onos_resource = true;
      }

      return object;
    });

    let formApiData = {
      data: x,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      setLinkToDisplay(response.data);
    }
  }

  const handleClick = async (name: string) => {
    dispatch(updateLoadingState(true));
    let resourceList = "";
    let key = "";
    let strLen = name.split(" ");
    dispatch(updateVolumeOfTOC(""));
    name.split(" ")?.map((ele: any, indexs: number) => {
      strLen.length - 1 !== indexs
        ? (key += ele?.replace(/^\"+|\“+|\"+|\”$/g, "") + "\\ ")
        : (key += ele);
    });
    resourceList +=
      key?.indexOf(":\\") > -1
        ? `fq__resource_name_tk=${key.split(":\\").join("\\:\\")}* AND `
        : `fq__resource_name_tk=*${key}* AND `;

    resourceList = `&${resourceList.slice(0, -5)}`;

    let apiJournalData: any = {
      page: 1,
      rows: 15,
      profileuserid: userId,
      sessionid: session_key,
      facet_fields:
        "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,resource_name_initial",

      fq__active_flag: true,
    };

    const response = await getListOfJournal(
      "resourcecore?" + objectToQueryString(apiJournalData) + resourceList
    );
    setDetails(response?.data.data.docs[0]);

    dispatch(updateBrowserPage(1));

    dispatch(updateVolumeOfTOC(""));

    let apiJournalHistoryData = {
      profileuserid: userId,
      sessionid: session_key,
      customerid: informaticscustomerId,
      datsearchtype_id: 1,
      datproductcontrolitem_id: 4,
      datsearch_action_type_id: 1,
      fq__resourcemaster_id: response?.data.data.docs[0].resourcemaster_id,
      // sort: "dateofpublication desc",
      sort: "dateofpublication desc, volumefrom desc, issuenumberfrom desc",
      // fq__resourceissue_id: "*",
    };
    if (customerDetails?.consortiamaster_id) {
      Object.assign(apiJournalData, {
        consortiamaster_id: customerDetails?.consortiamaster_id,
      });
    }

    const formatQueryString = (items: any[], key: string) => {
      return items?.length > 0
        ? `(${items.map((item) => item[key]).join(" OR ")})`
        : "";
    };

    const subjects = formatQueryString(
      customerDetails?.subject || [],
      "datsubjectmasterlevel2_id"
    );
    const dataTypes = formatQueryString(
      customerDetails?.datatypes || [],
      "dat_resourcetype_id"
    );

    // Object.assign(apiJournalHistoryData, { fq__resource_type: "(1 OR 2 )" });
    Object.assign(apiJournalHistoryData, { fq__subjects_id_l2: subjects });
    Object.assign(apiJournalHistoryData, { fq__resource_type: dataTypes });
    let newQuery =
      "?" +
      objectToQueryString({ ...apiJournalHistoryData, page: 1, rows: 500 });
    Object.assign(apiJournalHistoryData, { rows: 15 });

    Object.assign(apiJournalHistoryData, {
      json_facet: JSON.stringify({
        yearfrom: {
          type: "terms",
          field: "yearfrom",
          limit: 100,
          sort: "index desc",
        },
        monthfrom: { type: "terms", field: "monthfrom", limit: 100 },
        volumefrom: { type: "terms", field: "volumefrom", limit: 100 },
        issuenumberfrom: {
          type: "terms",
          field: "issuenumberfrom",
          limit: 100,
        },
        resource_status: {
          type: "terms",
          field: "resource_status",
          limit: 100,
        },
        primary_publisher_country: {
          type: "terms",
          field: "primary_publisher_country",
          limit: 100,
        },
        publisher_name: { type: "terms", field: "publisher_name", limit: 100 },
        journal_name: { type: "terms", field: "journal_name", limit: 100 },
        filter_metrix: { type: "terms", field: "filter_metrix", limit: 100 },
      }),
    });

    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = customerDetails?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      Object.assign(apiJournalHistoryData, {
        fq__acl_group: customerDetails.consortia_filter,
      });
    }

    if (selectedFilters.collectionsValueJournal === "My Library Collections") {
      Object.assign(apiJournalHistoryData, {
        fq__acl_group: customerDetails?.my_library_filter,
      });
    }

    if (selectedFilters.collectionsValueJournal === "Consortia Collections") {
      Object.assign(apiJournalHistoryData, {
        fq__acl_group: customerDetails?.consortia_filter,
      });
    }
    let obj = {
      resourcemaster_id: response?.data.data.docs[0].resourcemaster_id,
    };

    const newResponse = await axios.get(
      api.baseApi +
        `/api/indexing/solr/articlecore2${
          "?" + objectToQueryString(apiJournalHistoryData)
        }`
    );

    // let response2 = await bowserJournalSearchCard(obj);
    //let response2 = await bowserJournalSearchCard(apiJournalHistoryData);
    dispatch(getCardData(newResponse?.data?.data));

    // Fetching Year data
    let years =
      newResponse?.data?.data?.jsonfacets?.yearfrom?.buckets?.map(
        (element: any) => element.val.toString()
      ) || [];

    setYearDropDownList?.(years);
    setValue(years[0]);

    // Fetching volume and prefilling data
    let volumeRes = await browserJournalGetVolume(
      newQuery + `&fq__yearfrom=${years[0]}`
    );
    let firstVolData = volumeRes?.data?.data?.docs[0];
    setValueOne(firstVolData);
    //dispatch(updateVolumeOfTOC(firstVolData));
    setVolumeDropDownList(sortVolumesFunction(volumeRes?.data?.data?.docs));

    // fetch article Exiestanc
    const articleData = new FormData();

    let articleId: any = [];
    if (newResponse?.data?.data?.docs?.length > 0) {
      newResponse?.data.data.docs?.map((element: any) => {
        articleId = articleId.concat(element?.article_id);
      });
    }
    if (newResponse?.data?.data?.docs?.length == 0) {
      setNoResultModal(true);
    }

    articleData.append("profile_id", selector.user_id);
    articleData.append("article_ids", articleId.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateSavedArticles(filter_is_saved));

    // fetch links details
    await fetchLinkDetails(newResponse?.data?.data?.docs);

    dispatch(updateLoadingState(false));

    navigate("/BrowseJournalTableSearch/BrowseJournalSearch", {
      state: {
        item: response?.data.data.docs[0],
        // alerts: alerts,
        // lock: lock,
        from: "BrowseJournalTableSearch",
      },
    });
  };

  const handleCloseViewModal = () => setOpenViewModal(false);

  const handleOpenViewModal = () => {
    if (allowProfile) {
      setOpenViewModal(true);
    } else {
      notify("error", "Access Denied!");
    }
  };

  return (
    <React.Fragment>
      <Drawer
        onClose={handleDawer}
        anchor={"right"}
        open={state}
        // open={true}
        PaperProps={{
          sx: {
            width: {xs:"90%",md:"65%"},
            bgcolor: "#F9F8F6",
            height: "100%",
            paddingBottom: "5%",
          },
        }}
        //   onClose={toggleDrawer(anchor, false)}
      >
        <Box bgcolor={"#FFF"} width={"100%"}>
          <Box
            sx={[
              webStyle.flexBox,
              {
                alignSelf: "center",
                padding: "1%",
                ml: 3,
                margin: "1%",
              },
            ]}
          >
            <Typography
              variant="h6"
              fontFamily={"Lora"}
              fontWeight={"700"}
              fontSize={"24px"}
            >
              Journal Details
            </Typography>
            <IconButton aria-label="delete" size="small" onClick={handleDawer}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider></Divider>

        {/* =========POP UP ================ */}
        <Box sx={webStyle.subContainer}>
          <Typography variant="body2" sx={webStyle.titleText}>
            Journal Name:
          </Typography>
          <Typography variant="body2" sx={webStyle.informationText}>
            <MathJax>
              <div
                dangerouslySetInnerHTML={{
                  __html: details?.resource_name_tk,
                }}
              />
            </MathJax>
          </Typography>
        </Box>
        {journalHistory?.previous_resource_name && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Previous Name:
            </Typography>
            <Typography variant="body2" sx={webStyle.informationText}>
              <MathJax>
                <div
                  dangerouslySetInnerHTML={{
                    __html: journalHistory?.previous_resource_name,
                  }}
                />
              </MathJax>
            </Typography>
          </Box>
        )}

        <Box sx={webStyle.subContainer}>
          <Typography variant="body2" sx={webStyle.titleText}>
            Publisher:
          </Typography>
          <Typography variant="body2" sx={webStyle.informationText}>
            <MathJax>
              <div
                dangerouslySetInnerHTML={{
                  __html: details?.publisher_name_tk
                    ? details?.publisher_name_tk
                    : details?.publisher_name[0],
                }}
              />
            </MathJax>
          </Typography>
        </Box>
        {details?.co_publisher ||
          (journalHistory?.co_publisher && (
            <Box sx={webStyle.subContainer}>
              <Typography variant="body2" sx={webStyle.titleText}>
                Co-Publisher:
              </Typography>
              <Typography variant="body2" sx={webStyle.informationText}>
                {`${
                  journalHistory?.co_publisher
                    ? journalHistory?.co_publisher
                    : details?.co_publisher
                }`}
                {/* <Link to={}></Link> */}
              </Typography>
            </Box>
          ))}
        {details?.pissn && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              P-ISSN:
            </Typography>
            <Typography variant="body2" sx={webStyle.informationText}>
              {details?.pissn}
            </Typography>
          </Box>
        )}
        {details?.eissn && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              E-ISSN:
            </Typography>
            <Typography variant="body2" sx={webStyle.informationText}>
              {details?.eissn}
            </Typography>
          </Box>
        )}
        {details?.aboutresource || journalHistory?.about_journal ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              About Journal:
            </Typography>
            <Typography variant="body2" sx={webStyle.informationText}>
              {journalHistory?.about_journal
                ? journalHistory?.about_journal
                : details?.aboutresource}
            </Typography>
          </Box>
        ) : null}

        {details?.content_start || details?.content_end ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Content coverage:
            </Typography>
            <Typography variant="body2" sx={webStyle.informationText}>
              {details?.content_start} -{" "}
              {journalHistory?.resource_status
                ? journalHistory?.resource_status == "Active" ||
                  journalHistory?.resource_status == "Behind Schedule"
                  ? "Current"
                  : details?.content_end
                : details?.content_end}
            </Typography>
          </Box>
        ) : null}
        {journalHistory?.resource_status ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Journal Status:
            </Typography>
            <Typography variant="body2" sx={webStyle.informationText}>
              {journalHistory?.resource_status}
            </Typography>
          </Box>
        ) : null}
        {journalHistory?.author_url && (
          <Box
            sx={{
              display: "block",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "2%",
            }}
          >
            <Box>
              <Typography variant="body2" sx={webStyle.titleText}>
                Author URL:
                <Tooltip title="Report Link Issue" placement="top" arrow>
                  <IconButton>
                    {" "}
                    <img
                      src={BrokenLinkIcon}
                      alt="Broken Link Icon"
                      style={{ width: 24, height: 24 }}
                      onClick={handleOpenViewModal}
                    />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                ...webStyle.informationText,
                wordBreak: "break-word",
                marginLeft: "3%",
              }}
            >
              {validator?.isURL(journalHistory?.author_url) ? (
                <Link
                  href=""
                  onClick={(event) => {
                    redirectLink(journalHistory?.author_url, event);
                  }}
                  underline="hover"
                >
                  {journalHistory?.author_url}
                </Link>
              ) : (
                journalHistory?.author_url
              )}
            </Typography>
          </Box>
        )}

        {journalHistory?.frequency ? (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Frequency:
            </Typography>
            <Typography variant="body2" sx={webStyle.informationText}>
              {journalHistory?.frequency}
            </Typography>
          </Box>
        ) : null}

        {journalHistory?.resource_history && (
          <Box sx={webStyle.subContainer}>
            <Typography variant="body2" sx={webStyle.titleText}>
              Journal History:
            </Typography>
            <Box sx={{ width: "70%" }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="medium"
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
                      <TableCell>Journal Name</TableCell>
                      <TableCell>Year</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {journalHistory?.resource_history?.map((item: any) => {
                      return (
                        <>
                          <TableRow
                            // key={row}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{ cursor: "pointer" }}
                              sx={{
                                alignSelf: "center",
                                color: Colors.primary,
                                marginLeft: 1,
                                fontFamily: "Helvetica Neue",
                                fontStyle: "normal",
                                fontSize: 14,
                              }}
                              onClick={() => {
                                setViewDetails(false);
                                handleClick(item.resource_name);
                              }}
                              component="th"
                              scope="row"
                            >
                              {item.resource_name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.year_from && item.year_from}{" "}
                              {item.year_to && item.year_to !== 0
                                ? ` - ${item.year_to}`
                                : "- Current"}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}
      </Drawer>
      <ReportIssue
        openViewModal={openViewModal}
        handleCloseViewModal={handleCloseViewModal}
        resourcemaster_id={
          details?.resourcemaster_id
            ? details?.resourcemaster_id
            : journalHistory?.journal_id
        }
        authorURL={journalHistory?.author_url}
      />

      <NoResultModal isOpen={noresultModal} handleClose={closeNoResultModal} />
    </React.Fragment>
  );
};

export default JournalViewDetails;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    //  backgroundColor:"red"
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",

    fontStyle: "normal",
    color: "#343332",
    fontSize: "14px",
    wordWrap:"break-word"
  },
  informationText: {
    width: "70%",
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#343332",
    wordWrap:"break-word"
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
