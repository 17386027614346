import React from "react";
import { Box, Typography, FormControl, Select, MenuItem } from "@mui/material";
import Text from "@mui/material/Typography";
import { Colors, dataTypes } from "../../utils/constants";

interface BrowseIssuesProps {
  value: any;
  valueOne: any;
  handleChange: (event: any) => void;
  handleChangeOne: (event: any) => void;
  YeardropDownList: any[];
  volumeDropDownList: any[];
  hasRequiredData: boolean;
  objectWithBookTitle?: { book_title: string };
  month: string[];
  MenuProps: any;
}

const BrowseIssues: React.FC<BrowseIssuesProps> = ({
  value,
  valueOne,
  handleChange,
  handleChangeOne,
  YeardropDownList,
  volumeDropDownList,
  hasRequiredData,
  objectWithBookTitle,
  month,
  MenuProps,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderWidth: 1,
        borderColor: "#D1D5DB",
        borderStyle: "solid",
        borderRadius: "12px",
        overflow: "hidden",
        padding: { xs: 1, md: 3 },
      }}
    >
      <Box sx={[{ marginTop: "0%" }]}>
        <Typography
          fontWeight={"700"}
          sx={{
            fontFamily: "Lora",
            fontSize: { xs: "18px", md: "20px" },
            lineHeight: { xs: "18px", md: "24px" },
            mb: { xs: 1, md: 2.5 },
          }}
        >
          Browse Issues
        </Typography>
        <FormControl
          sx={{
            marginRight: { xs: 0, sm: 1 },
            marginBottom: 2,
            minWidth: 250,
            width: { xs: "100%", md: 250 },
          }}
        >
          <Select
            size={"medium"}
            displayEmpty
            MenuProps={MenuProps}
            id="demo-simple-select-helper"
            renderValue={
              value == -1 || value == undefined ? () => "Year" : undefined
            }
            value={value}
            onChange={handleChange}
            inputProps={{ "aria-label": "Without label" }}
            style={{ borderRadius: 8 }}
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              minWidth: { xs: "16em", md: "18em" },
              //width: { xs: "70%", sm: "100%" },
            }}
          >
            {YeardropDownList?.map((year: any, index: number) => {
              return (
                <MenuItem
                  value={year}
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    //width: { xs: "70%", sm: "100%" },
                  }}
                >
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            marginRight: { xs: 0, sm: 1 },
            marginBottom: 2,
            minWidth: 250,
            width: { xs: "100%", md: 250 },
          }}
        >
          {hasRequiredData && objectWithBookTitle ? (
            <Typography
              sx={[webStyles.headerText, { margin: 0 }]}
              variant="h5"
              gutterBottom
            >
              <Text>{objectWithBookTitle.book_title}</Text>
            </Typography>
          ) : (
            <Select
              size={"medium"}
              displayEmpty={true}
              renderValue={
                // valueOne !== ("" && undefined)
                valueOne !== "" && valueOne !== undefined
                  ? () =>
                      `${month[valueOne?.monthfrom - 1] || ""} ${
                        valueOne?.datefrom && valueOne?.datefrom !== 0
                          ? valueOne?.datefrom
                          : ""
                      } ${
                        (valueOne?.monthfrom || valueOne?.datefrom) &&
                        valueOne?.volumefrom
                          ? "- "
                          : ""
                      }${
                        valueOne?.volumefrom
                          ? `Volume ${valueOne?.volumefrom}`
                          : ""
                      }${
                        valueOne?.issuenumberfrom && valueOne?.volumefrom
                          ? ", "
                          : ""
                      }${
                        valueOne?.issuenumberfrom
                          ? `Issue ${valueOne?.issuenumberfrom}`
                          : ""
                      }${
                        valueOne?.issuenumberto
                          ? `- ${valueOne?.issuenumberto}`
                          : ""
                      }${
                        valueOne?.issuepart
                          ? `, Part ${valueOne?.issuepart}`
                          : ""
                      }`
                  : () => "Volume, Issue"
              }
              MenuProps={MenuProps}
              id="demo-simple-select-helper"
              value={valueOne}
              onChange={handleChangeOne}
              inputProps={{ "aria-label": "Without label" }}
              style={{ borderRadius: 8 }}
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                minWidth: { xs: "16em", md: "18em" },
              }}
            >
              {/* {volume[0]?.map(() => {
                      <Text>{objectWithBookTitle.book_title}</Text>
                      </Typography> :  <Select
                      // size="small"
                      displayEmpty={true}
                      renderValue={
                        volumeDetailsTOC == "" ? () => "Volume, Issue" : undefined
                      }
                      MenuProps={MenuProps}
                      id="demo-simple-select-helper"
                      value={volumeDetailsTOC}
                      onChange={handleChangeOne}
                      inputProps={{ "aria-label": "Without label" }}
                      style={{ borderRadius: 8, minWidth: "23em" }}
                    >
                      {/* {volume[0]?.map(() => {
                    return (
                      <MenuItem value={"Containing"}>
                        March 2 - Volume 55, Issue 3
                      </MenuItem>
                    );
                  })} */}

              {volumeDropDownList?.map((e: any, i: any) => {
                return (
                  <MenuItem
                    value={e}
                    sx={{ fontSize: { xs: "16px", md: "20px" } }}
                  >
                    {month[e?.monthfrom - 1]}{" "}
                    {e?.datefrom && e?.datefrom !== 0 ? e?.datefrom : ""}
                    {(e?.monthfrom || e?.datefrom) && e?.volumefrom ? "- " : ""}
                    {e?.volumefrom ? `Volume ${e?.volumefrom}` : ""}
                    {e?.issuenumberfrom && e?.volumefrom ? ", " : ""}
                    {e?.issuenumberfrom ? `Issue ${e?.issuenumberfrom}` : ""}
                    {e?.issuenumberto ? `- ${e?.issuenumberto}` : ""}
                    {e?.issuepart ? `, Part ${e?.issuepart}` : ""}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export default BrowseIssues;

const webStyles = {
  button: {
    margin: "0.5%",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    flex: 1,
  },
  headerText: {
    fontFamily: "Lora",
    color: Colors.primary,
    fontWeight: 700,
    fontSize: { xs: "16px", md: "24px" },
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: { xs: "0.8rem", md: "0.9rem" },
  },
  checkBox: {
    fontSize: 14,
    color: Colors.primary,
    fontFmaily: "Helvetica Neue",
    fontWeight: 400,
  },
  buttonOne: {
    marginLeft: "0.9%",
    padding: { xs: "0.4rem", md: "0.7rem" },
    width: "10%",
    minWidth: { xs: "64px", md: "128px" },
    fontWeight: "500",
  },
  inputField: {
    // width: "77%",
    marginRight: "1%",
    borderRadius: 60,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.coolGray300,
        borderRadius: 2,
      },
    },
    fontFamily: "Helvetica Neue",
    fontSize: { xs: "0.75rem", md: "0.875rem" },
  },
  flexRow: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    // backgroundColor: "red",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    // fontSize: 24,
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
  },
  peerReviewed: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: { xs: 8, md: 14 },
    color: "#1A1A1A",
  },
  clearallText: {
    // fontFamily: "Helvetica Neue",
    // fontWeight: "500",
    // color: Colors.primary,
    // fontSize: 14,
    // alignSelf: "center",
    height: 22,
    // textTransform: "none",
  },
  searchTextFont: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: Colors.gray900,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: Colors.gray_400,
    marginLeft: 1,
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 22,
    borderRadius: "6px",
    //  fontSize:"0.875rem"
  },
  gridStyle: {
    position: "absolute",
    width: "330px",
    // height: "118px",
    top: "5px",
    marginTop: "50px",
    background: "#FFFFFF",
    border: "1px dashed rgba(33, 33, 33, 0.1)",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    dispay: "flex",
    justifyContent: "flex-end",
    zIndex: "100000",
  },
};
