import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel, a11yProps } from "../Tabs/TabPanel";
import { prefernecesStyles as styles } from "../../screens/Preferences/Preferences.styles";
import { useState } from "react";
import { useGetMyPreference } from "../../api/Preferences/Subjects.api";

// Tabs
import DocumentTypeTab from "./preferenceTabs/Document.tab";
import SubjectPreferenceTab from "./preferenceTabs/Subject.tab";
import { Loader } from "../spinner";

interface PropType {
  open: boolean;
  checkPreferenceAdded?: (data: any) => void;
}

export default function PreferenceModal(props: PropType) {
  const { open, checkPreferenceAdded } = props;
  const dispatch = useAppDispatch();

  const [value, setValue] = useState(0);
  const selector = useAppSelector((state) => state.login);

  const { data: l1l2Data } = useGetMyPreference(selector.user_id);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  const closeModal = () => {
    dispatch(hideModal());
    checkPreferenceAdded && checkPreferenceAdded(l1l2Data);
  };
  return (
    <Dialog open={open} onClose={closeModal} maxWidth={false}>
      {/* ------------------------Title --------------------------------------- */}
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
          Preference
        </Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/*----------------------------- Content------------------------------------- */}
      {/* <DialogContent sx={{ width: 700 }}> */}
      <DialogContent
        sx={{ width: { xs: "100%", sm: 500, md: 700, lg: 700, xl: 700 } }}
      >
        <Box sx={styles.body}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Subjects" {...a11yProps(0)} />
              <Tab label="Document Type" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {l1l2Data ? (
              <SubjectPreferenceTab l1l2Data={l1l2Data} />
            ) : (
              <Loader height={"100%"} width={"100%"} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {l1l2Data ? (
              <DocumentTypeTab l1l2Data={l1l2Data} />
            ) : (
              <Loader height={"100%"} width={"100%"} />
            )}
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
