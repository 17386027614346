import React, { useEffect } from "react";

import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { Colors } from "../../utils/constants";
import Nav from "../../components/Nav/MainNav";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  findInLibraryApi,
  getFindInLibraryAddessListCall,
  getFindInLibraryArticleId,
} from "../../api/findInLibrary";
import { LoginCred } from "../../types/interface";
import { useLocation, useNavigate } from "react-router-dom";
import objectToQueryString from "../browseJournal/objToQuery";
import { useDispatch, useSelector } from "react-redux";
import { Direction } from "react-toastify/dist/utils";

function FindLibrary() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const details = location?.state?.data;
  let loginId: any = useSelector(
    (state: LoginCred) => state?.login?.informaticscustomer_id
  );
  const handleChange =
    (panel: string, ele: any) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      isExpanded && updateAddressList(Object.keys(ele)[0]);

      setExpanded(isExpanded ? panel : false);
    };

  const [findInLibraryItems, setFindInLibrarayItems] = React.useState<any[]>(
    []
  );

  const [findInLibraryList, setFindInLibraryList] = React.useState<any[]>([]);
  console.log(details);

  const getArticleId = async () => {
    const apiData = {
      customerId: sessionStorage.getItem("informaticscustomer_id") || loginId,
      article_issue_id: details?.resourceissue_id,
      // customerId: 1877,
      // article_issue_id: "2876026",
    };

    const response = await getFindInLibraryArticleId(
      "?" + objectToQueryString(apiData)
    );
    if (response?.data?.data[0][0]?.customer_list !== null) {
      {
        setFindInLibraryList(response?.data?.data[0][0]?.customer_list);
      }
    }
  };

  const updateAddressList = async (id: any) => {
    const apiData = {
      customerId: id,
    };

    const response = await getFindInLibraryAddessListCall(
      "?" + objectToQueryString(apiData)
    );

    if (response?.data.message == "Ok") {
      {
        let apiResponse = response?.data.data[0];
        let list: any[] = [];
        apiResponse?.map((element: any, index: any) => {
          list = list.concat({
            title: element.customername,
            information: [element.address, element.statename, element.pin],
          });
        });

        setFindInLibrarayItems(list);
      }
    }
  };

  React.useEffect(() => {
    getArticleId();
    // updateAddressList();
  }, []);

  return (
    <Box>
      <Nav></Nav>
      <Box padding={"1.2% 3%"} sx={webStyles.flexBox}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosNewIcon
            sx={{ alignSelf: "center" }}
            fontSize={"small"}
          ></ArrowBackIosNewIcon>
        </IconButton>
        <Typography
          sx={{
            fontSize: { xs: "1.2rem", sm: "1.5rem" },
            fontFamily: "Lora",
            fontWeight: 700,
            alignSelf: "center",
          }}
        >
          {" "}
          Find in Library
        </Typography>
      </Box>
      <Box sx={{ padding: "1% 3%" }}>
        {/* <Box
          sx={{
            backgroundColor: Colors.white,
            borderRadius: "16px",
            border: "1px solid #D1D5DB",
          }}
        >
          <Box padding={"1%"}>
            <Typography
              sx={[
                webStyles.titleText,
                { fontWeight: 500, marginBottom: "1%" },
              ]}
            >
              Libraries subscribing to this journal
            </Typography>
            <Typography sx={webStyles.heading}>
              {details.journal_name}
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: 0, sm: 1 },
              }}
            >
              <Typography style={webStyles.titleText}>Published by:</Typography>
              <Typography>
                {details?.publisher_name?.map((item: any) => {
                  return (
                    <>
                      <Typography>{item}</Typography>
                      {details?.publisher_name.length > 1 && (
                        <Divider></Divider>
                      )}
                    </>
                  );
                })}
              </Typography>
            </Stack>
          </Box>
        </Box> */}

        <Box
          sx={{
            backgroundColor: Colors.white,
            borderRadius: "16px",
            border: "1px solid #D1D5DB",
          }}
        >
          <Box padding={"1%"}>
            {/* Title */}
            <Typography
              sx={[
                webStyles.titleText,
                { fontWeight: 500, marginBottom: "1%" },
              ]}
            >
              Libraries subscribing to this journal
            </Typography>

            {/* Journal Name */}
            <Typography sx={webStyles.heading}>
              {details.journal_name}
            </Typography>

            {/* Publisher Details */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                gap: { xs: 1, sm: 2 },
                flexWrap: "wrap",
              }}
            >
              <Typography style={webStyles.titleText}>Published by:</Typography>

              {/* Publisher List */}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={0}
                sx={{ flexWrap: "wrap" }}
              >
                {details?.publisher_name?.map((item: string, index: number) => (
                  <React.Fragment key={index}>
                    <Typography>{item}</Typography>
                    {index !== details.publisher_name.length - 1 && (
                      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          </Box>
        </Box>

        {findInLibraryList && (
          <Box
            sx={{
              backgroundColor: Colors.white,
              borderRadius: "24px",
              border: "1px solid #D1D5DB",
              marginTop: "1%",
              overflow: "hidden",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              sx={{
                backgroundColor: "#F3F4F6",
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
              }}
            >
              <Typography
                sx={{ width: { xs: "20%", sm: "10%" }, flexShrink: 0 }}
              >
                Sl no
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>Name</Typography>
            </AccordionSummary>
            {findInLibraryList?.map((ele: any, i: any) => {
              return (
                <Accordion
                  expanded={expanded === `panel${i}`}
                  onChange={handleChange(`panel${i}`, ele)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography
                      sx={{ width: { xs: "20%", sm: "10%" }, flexShrink: 0 }}
                    >
                      {i + 1}
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      {/* {ele?.title} */}
                      {Object.values(ele)}
                    </Typography>
                  </AccordionSummary>
                  <Typography
                    sx={{ padding: "1% 1%", backgroundColor: "#F3F4F6" }}
                  >
                    Contact Details
                  </Typography>
                  {/* <AccordionDetails>
                    <Box sx={webStyles.contactdetailsItemContainer}>
                      <Typography sx={webStyles.leftCol}>Name : </Typography>
                      <Typography>{findInLibraryItems[0]?.title}</Typography>
                    </Box>
                    <Box sx={webStyles.contactdetailsItemContainer}>
                      <Typography sx={webStyles.leftCol}>State : </Typography>
                      <Typography>
                        {findInLibraryItems[0]?.information[1]}
                      </Typography>
                    </Box>
                    <Box sx={webStyles.contactdetailsItemContainer}>
                      <Typography sx={webStyles.leftCol}>Address : </Typography>
                      <Typography>
                        {findInLibraryItems[0]?.information[0]}
                      </Typography>
                    </Box>
                    <Box sx={webStyles.contactdetailsItemContainer}>
                      <Typography sx={webStyles.leftCol}>Pincode : </Typography>

                      <Typography>
                        {findInLibraryItems[0]?.information[2]}
                      </Typography>
                    </Box>
                  </AccordionDetails> */}

                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" }, // Stack on xs, row on sm+
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <Box sx={webStyles.contactdetailsItemContainer}>
                        <Typography sx={webStyles.leftCol}>Name:</Typography>
                        <Typography>{findInLibraryItems[0]?.title}</Typography>
                      </Box>

                      <Box sx={webStyles.contactdetailsItemContainer}>
                        <Typography sx={webStyles.leftCol}>State:</Typography>
                        <Typography>
                          {findInLibraryItems[0]?.information[1]}
                        </Typography>
                      </Box>

                      <Box sx={webStyles.contactdetailsItemContainer}>
                        <Typography sx={webStyles.leftCol}>Address:</Typography>
                        <Typography>
                          {findInLibraryItems[0]?.information[0]}
                        </Typography>
                      </Box>

                      <Box sx={webStyles.contactdetailsItemContainer}>
                        <Typography sx={webStyles.leftCol}>Pincode:</Typography>
                        <Typography>
                          {findInLibraryItems[0]?.information[2]}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: Colors.white,
            borderRadius: "16px",
            border: "1px solid #D1D5DB",
            marginTop: "1%",
          }}
        >
          <Box padding={"1%"}>
            <Typography
              gutterBottom
              sx={[
                { fontWeight: 500, fontSize: "0.875rem", color: Colors.red500 },
              ]}
            >
              Disclaimer
            </Typography>
            <Typography sx={[{ fontWeight: 500, fontSize: "0.875rem" }]}>
              Informatics does not guarantee the availibility of the journal in
              the libraries listed here. Contact your library for further help
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FindLibrary;

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    fontFamily: "Lora",
    fontWeight: 700,
    fontSize: { xs: "1.2rem", sm: "1.5rem" },
    color: Colors.primary,
  },
  text: {
    fontFamily: "Helvetica Neue",
    fontWeight: 400,
    fontSize: "1rem",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "0.875rem",
    fontFamily: "Helvetica Neue",
    // marginTop: "0.9%",
    display: "flex",
    gap: "4px",
  },
  marginTop: {},
  // leftCol: {
  //   width: "10%",
  // },
  leftCol: {
    width: { xs: "20%", sm: "20%", md: "15%", lg: "12%", xl: "10%" }, // Dynamic width
    fontWeight: 700,
    minWidth: "80px", // Prevents label shrink
  },
  contactdetailsItemContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
};
