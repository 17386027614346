import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../../utils/constants";
import IconButton from "@mui/material/IconButton";
import StarOutlineIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import RSSIcon from "@mui/icons-material/RssFeed";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@mui/material/Typography";
import { alertStyles as styles } from "./Alerts.style";
import { Button, Tooltip } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useManageTOC } from "./ManageTOC.hook";

export default function ManageTOC() {
  const {
    search,
    tocData,
    hitCount,
    pageSize,
    loading,
    resCount,
    setPage,
    handleOnSearchChange,
    handleFav,
    handleAlert,
    handleSearch,
    handleClear,
    handlePerPage,
  } = useManageTOC();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <TextField
          // sx={styles.search}
          sx={{
            ...styles.search,
            display: "flex",
            width: { xs: "100%", sm: "50%", md: "40%", lg: "30%", xl: "30%" },
          }}
          value={search}
          onChange={handleOnSearchChange}
          placeholder="Search Journal Name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={handleClear}>
                  {search && <CloseIcon fontSize="inherit" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSearch();
          }}
        />
        <Button
          variant="contained"
          sx={{
            height: "56px",
            width: { xs: "100%", sm: "130px" },
            //flex: "130px 0 1",
          }}
          onClick={handleSearch}
        >
          Search
        </Button>
        {!loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              ml: "auto",
              my: 2,
            }}
          >
            <Text sx={{ fontSize: "1.1rem" }}>
              Active Alerts: <strong>{resCount?.alert_count}</strong>
            </Text>
            <Text sx={{ fontSize: "1.1rem", mt: 1 }}>
              Favorites: <strong>{resCount?.favorite_set_count}</strong>
            </Text>
          </Box>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>Title</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tocData?.map((row) => (
              <TableRow
                key={row.resourcemaster_id}
                hover
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={styles.addManageCell}>
                  {row.resource_name}

                  <Box sx={{ display: "flex" }}>
                    <Tooltip
                      title={row.alerttype_id ? "Modify Alert" : "Set Alert"}
                    >
                      <IconButton
                        onClick={() => handleAlert(row)}
                        aria-label="rss"
                        sx={{
                          color: row.alerttype_id ? "#2E8B57" : "#6E6E6F", //Any ID > 0 == True
                        }}
                      >
                        <RSSIcon />
                      </IconButton>
                    </Tooltip>

                    <IconButton
                      onClick={() => handleFav(row, row)}
                      aria-label="favorite"
                      sx={{
                        color: row.is_favourite ? "#FACA15" : "#6E6E6F",
                      }}
                    >
                      {row.is_favourite ? <StarIcon /> : <StarOutlineIcon />}
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        my={3}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2 },
          alignItems: { xs: "flex-end", sm: "flex-end" },
        }}
      >
        <Pagination
          count={hitCount}
          variant="outlined"
          shape="rounded"
          color="primary"
          onChange={(_, p) => setPage(p)}
        />
        <Select
          id="pageSize"
          value={pageSize.toString()}
          displayEmpty
          autoWidth
          onChange={handlePerPage}
          sx={{
            height: "32px",
            ml: 2,
          }}
          MenuProps={{
            PaperProps: {
              style: {
                transform: "translateY(-81px)",
              },
            },
          }}
        >
          <MenuItem value={15}>15 / Page</MenuItem>
          <MenuItem value={25}>25 / Page</MenuItem>
          <MenuItem value={50}>50 / Page</MenuItem>
          <MenuItem value={100}>100 / Page</MenuItem>
        </Select>
      </Box>
    </>
  );
}
