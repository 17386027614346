import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "../../components/card";
import Text from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { MathJax } from "better-react-mathjax";
import {
  accessTypeButtons,
  isStringOfArray,
  sortAuthor,
  sortAuthorDetail,
  sortByAuthorRole,
} from "../../utils/helper";
import { useAppSelector } from "../../store/hooks";
import { Colors, dataTypes, authorRole } from "../../utils/constants";
import { findInLibraryApi } from "../../api/findInLibrary";
import AccessLock from "../basicSearchScreen/AccessLock";
// Icons
import RssFeedIcon from "@mui/icons-material/RssFeed";
import IdIcon from "../../assets/img/id.png";
import DatasetIcon from "../../assets/img/database.svg";
import RetractedIcon from "../../assets/img/Retracted.svg";
import FindInLibraryIcon from "../../assets/img/findinlib.svg";
import PreprintIcon from "../../assets/img/preprint.svg";
import metaData from "../../utils/metadata";
import { notify } from "../../utils/Notify";
import { usagelogApi } from "../../api/usageReportApi";
import { keywordHighlight } from "../../utils/highlightKeyword";

interface PropsType {
  data: any;
  getMarkedResult?: (data: any, id: string) => void;
  markedData?: string[];
  handleAuthor?: (name: string, data: any, role?: any) => void;
  handleDawer: (data: any) => void;
  handleResource?: (data: any) => void;
  folderId?: number;
  getButtonType: any;
  linkToDisplay: any;
}

export default function SearchResultCard(props: PropsType) {
  const {
    data,
    getMarkedResult,
    markedData,
    handleAuthor,
    handleDawer,
    handleResource,
    folderId,
    getButtonType,
    linkToDisplay,
  } = props;
  const navigate = useNavigate();
  const dataType = useAppSelector((state) => state.searchReducer.dataType);
  const profileData = useAppSelector((state) => state.login);
  const searchTerm = useAppSelector((state) => state.searchReducer.searchTerm);
  let searchResults: any = useAppSelector(
    (state) => state.searchReducer.searchResult
  );
  let keywordData = searchResults?.highlighting;
  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const profileStatus = sessionStorage.getItem("isProfileUser");

  const is_ONOSCustomer = useAppSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  let customerId: any =
    useAppSelector((state) => state?.login?.informaticscustomer_id) ||
    sessionStorage.getItem("informaticscustomer_id");

  let authorDetails = Array.isArray(data?.author_details)
    ? data?.author_details
    : Array.isArray(data?.authors)
    ? data?.authors
    : Array.isArray(data?.speakers)
    ? data?.speakers
    : [];

  const selectedFilters = useAppSelector((state) => state.filterReducer);

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "Consortia Collections"
      ? 2
      : selectedFilters.collectionValue === "My Library Collections"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logSearchResultCardUsageData = (usageActionID: any, details?: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isArticleONOS = details?.is_onos_resource;
    const isONOS = is_ONOSCustomer && isArticleONOS ? 1 : 0;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };

  const handleAbstractFullScreen = (data?: any) => {
    logSearchResultCardUsageData(30, data);
    navigate("/abstractFullScreen", {
      state: { data, linkToDisplay },
    });
  };

  const handleReadMore = (data: any) => {
    handleDawer(data);
    logSearchResultCardUsageData(30, data);
  };
  function handleAvailableinLib() {
    notify(
      "info",
      "Please visit the library to obtain the photocopy if required. Contact your library staff for any help."
    );
  }
  return (
    <Box>
      <Card
        data={data}
        getMarkedResult={getMarkedResult}
        markedArticlesid={markedData}
        searchTerm={searchTerm.toString()}
      >
        {/***********************************************************************************************************************************************
                          Title
                        ***********************************************************************************************************************************************/}
        <CardContent sx={{ padding: "0px 14px" }}>
          {data?.title && (
            <Text
              onClick={
                () => handleAbstractFullScreen(data)
                // navigate("/abstractFullScreen", {
                //   state: { data, linkToDisplay, searchTerm },
                // })
              }
              fontWeight={600}
              fontFamily={"Lora"}
              sx={{
                textAlign: "left",
                color: "#1A1A1A",
                cursor: "pointer",
                fontSize: { xs: ".9rem", sm: "1.125rem" },
              }}
              component={"div"}
            >
              <MathJax>
                <div
                  id="title"
                  dangerouslySetInnerHTML={{
                    __html: keywordHighlight(
                      keywordData,
                      searchTerm,
                      data?.article_id,
                      data?.title
                    ),
                  }}
                />
              </MathJax>
            </Text>
          )}
        </CardContent>

        {/***********************************************************************************************************************************************
                          Author Details
                        ***********************************************************************************************************************************************/}
        {data?.data_type === dataTypes.journalArticle ||
        data?.data_type === dataTypes.journals ||
        data?.data_type === dataTypes.bookSeries ||
        data?.data_type === dataTypes.dissertationThesis ||
        data?.data_type === dataTypes.preprint ||
        data?.data_type === dataTypes.conferenceProceedings ||
        data?.data_type === dataTypes.magazine ||
        isOpac ||
        data?.data_type === dataTypes.audioVideo ? (
          <CardContent sx={{ display: "flex", padding: "0 14px" }}>
            {Array.isArray(data?.author_details) &&
              !isStringOfArray(data?.author_details) &&
              sortByAuthorRole(
                sortAuthorDetail(data?.author_details),
                data?.data_type === dataTypes.dissertationThesis
              )?.map((obj: any, i) => {
                const firstName = obj?.author_fname ? obj?.author_fname : "";
                const lastName = obj?.author_lname ? obj?.author_lname : "";

                const reversedName = lastName
                  ? lastName + ", " + firstName
                  : firstName;
                const displayName = lastName
                  ? lastName.replace(/,/g, " ") +
                    ", " +
                    firstName.replace(/,/g, " ")
                  : firstName.replace(/,/g, " ");

                if (i > (isXs ? 1 : 3)) return null;

                return (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    {i < (isXs ? 1 : 3) ? (
                      <>
                        <Tooltip title={authorRole[obj?.datauthorrole_id]}>
                          <Button
                            onClick={() => {
                              handleAuthor &&
                                handleAuthor(reversedName, data, obj);
                            }}
                            sx={{
                              fontFamily: "Helvetica Neue",
                              fontSize: "14px",
                              textDecoration: "underline",
                              padding: i === 0 ? "8px 0px 6px 0px" : "6px 8px",
                            }}
                          >
                            {displayName}
                          </Button>
                        </Tooltip>
                        {obj?.orc_id ? (
                          <a
                            href={`https://orcid.org/${obj?.orc_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={IdIcon}
                              style={{
                                paddingLeft: "5px",
                                marginTop: "8px",
                                paddingRight: "6px",
                              }}
                              alt="IdIcon"
                            />
                          </a>
                        ) : null}

                        <Divider
                          orientation="vertical"
                          style={{
                            height: "60%",
                            alignSelf: "center",
                          }}
                        />
                      </>
                    ) : (
                      <Button
                        sx={{
                          color: "#6B7280",
                          textDecoration: "underline",
                        }}
                        onClick={() => handleDawer(data)}
                      >
                        +{data.author_details?.length - (isXs ? 1 : 3)} More
                      </Button>
                    )}
                  </Box>
                );
              })}
            {Array.isArray(data?.author_details) &&
              isStringOfArray(data?.author_details) &&
              sortAuthor(data?.author_details)?.map(
                (name: string, i: number) => {
                  if (i > (isXs ? 1 : 3)) return null;
                  return (
                    <Box
                      key={i}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                    >
                      {i < (isXs ? 1 : 3) ? (
                        <>
                          {name?.length ? (
                            <Button
                              onClick={() =>
                                handleAuthor && handleAuthor(name, data)
                              }
                              sx={{
                                fontFamily: "Helvetica Neue",
                                fontSize: "14px",
                                textDecoration: "underline",
                                padding:
                                  i === 0 ? "8px 8px 6px 0px" : "6px 8px",
                              }}
                            >
                              {name.length
                                ? name
                                    .trim()
                                    .replace(/^\,+|\,+$/g, "")
                                    .trim()
                                : ""}{" "}
                            </Button>
                          ) : null}

                          <Divider
                            orientation="vertical"
                            style={{
                              height: "60%",
                              alignSelf: "center",
                            }}
                          />
                        </>
                      ) : (
                        <Button
                          sx={{
                            color: "#6B7280",
                            textDecoration: "underline",
                          }}
                          onClick={() => handleDawer(data)}
                        >
                          +
                          {data.authors
                            ? data.authors?.length - (isXs ? 1 : 3)
                            : data.author_details?.length - (isXs ? 1 : 3)}{" "}
                          More
                        </Button>
                      )}
                    </Box>
                  );
                }
              )}
          </CardContent>
        ) : null}

        {/***********************************************************************************************************************************************
                          Journal name, Data, Year
                        ***********************************************************************************************************************************************/}
        {data?.data_type === dataTypes.journalArticle ||
        data?.data_type === dataTypes.journals ||
        data?.data_type === dataTypes.bookSeries ||
        data?.data_type === dataTypes.dissertationThesis ||
        data?.data_type === dataTypes.preprint ||
        data?.data_type === dataTypes.conferenceProceedings ||
        data?.data_type === dataTypes.magazine ||
        data?.data_type === dataTypes.audioVideo ? (
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "4px 14px",
              alignItems: { xs: "start", sm: "center" },
            }}
          >
            {data?.data_type === dataTypes.dissertationThesis ||
            data?.data_type === dataTypes.audioVideo ||
            data?.data_type === dataTypes.preprint ||
            data?.data_type === dataTypes.magazine ||
            data?.data_type === dataTypes.conferenceProceedings ? null : (
              <Box pr={"0.8rem"}>
                <Tooltip title="Set Alert">
                  <IconButton
                    sx={{
                      "&:hover": {
                        color: "#F48120",
                      },
                      padding: 0,
                    }}
                  >
                    <RssFeedIcon fontSize="small"></RssFeedIcon>
                  </IconButton>
                </Tooltip>
              </Box>
            )}

            <Text
              component={"div"}
              sx={{
                textAlign: "left",
                fontSize: { xs: "14px", sm: "16px" },
                color: Colors.primary,
                fontStyle: "italic",
                cursor: "pointer",
              }}
              onClick={() => handleResource && handleResource(data)}
            >
              {metaData(data,isXs)}
            </Text>
          </CardContent>
        ) : null}

        {/***********************************************************************************************************************************************
                          Footer Section
                        ***********************************************************************************************************************************************/}
        <CardActions
          disableSpacing
          style={{ justifyContent: "space-between" }}
          sx={{ padding: "4px 14px" }}
        >
          <Stack direction="row" spacing={2}>
            <Stack direction={"row"} gap={1}>
              <Button
                onClick={() => {
                  isXs ? handleAbstractFullScreen(data) : handleReadMore(data);
                }}
                size="small"
                sx={{
                  textDecoration: "underline",
                  color: "#000",
                  fontSize: { xs: "11px", sm: "14px" },
                }}
              >
                Read More
              </Button>
              {data?.has_datasets ? (
                <Tooltip title="Dataset">
                  <IconButton
                    sx={{ padding: 0 }}
                    href={data?.dataset_link && data?.dataset_link[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      // usageReport("37");
                    }}
                  >
                    <img src={DatasetIcon} alt="DatasetIcon" />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
              {/* is_retracted */}
              {data?.is_retracted ? (
                <Tooltip title="Retracted Article">
                  <img src={RetractedIcon} alt="RetractedIcon" />
                </Tooltip>
              ) : (
                ""
              )}
            </Stack>
          </Stack>
          <Box display={"flex"} gap={1}>
            {accessTypeButtons(data, customerId) !== "GreenLock" &&
              data?.has_preprint && (
                <Tooltip title="View Pre-Print">
                  <Button
                    href={data?.preprint_link}
                    rel="noopener noreferrer"
                    target="_blank"
                    sx={{ minWidth: 0 }}
                  >
                    <img src={PreprintIcon} alt="PreprintIcon" />
                  </Button>
                </Tooltip>
              )}
            {!data?.fulltext &&
              getButtonType(data?.article_id)?.includes("request_article") && (
                <Button
                  variant="outlined"
                  onClick={async () => {
                    // usageReport("36");
                    // logBasicSearchUsageData(36);
                    navigate("DdrScreen", {
                      state: { data },
                    });
                  }}
                >
                  Request Article
                </Button>
              )}

            {!data?.fulltext &&
              getButtonType(data?.article_id)?.includes(
                "available_in_library"
              ) && (
                <Button
                  size="small"
                  onClick={handleAvailableinLib}
                  sx={{
                    textDecoration: "underline",
                    color: "#000",
                  }}
                >
                  Available in library
                </Button>
              )}

            {getButtonType(data?.article_id)?.includes("find_in_library") &&
              !data?.fulltext &&
              (data?.data_type === dataTypes.journalArticle ||
                data.data_type === dataTypes.conferenceProceedings ||
                data.data_type === dataTypes.journals) && (
                <Tooltip title="Find in Library">
                  <Button
                    sx={{ minWidth: 0 }}
                    onClick={async () => {
                      // usagelogApi(
                      //   profileData.user_id,
                      //   profileData.informaticscustomer_id,
                      //   profileData1?.consortiamaster_id,
                      //   38,
                      //   null,
                      //   null,
                      //   data?.article_id,
                      //   data?.resourceissue_id,
                      //   null,
                      //   data?.resourcemaster_id,
                      //   null,
                      //   null,
                      //   profileData?.session_key,
                      //   1,
                      //   null
                      // );
                      navigate("findlibrary", {
                        state: { data },
                      });
                    }}
                  >
                    <img src={FindInLibraryIcon} alt="FindInLibraryIcon" />
                  </Button>
                </Tooltip>
              )}
            {data?.pmcid ? (
              <Button
                href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${data?.pmcid}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                PubMed Link
              </Button>
            ) : (
              ""
            )}
            <AccessLock
              data={data}
              linkToDisplay={linkToDisplay && linkToDisplay}
            />
            {isOpac && data?.details_url ? (
              <Button
                href={data?.details_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Details
              </Button>
            ) : null}
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
}
