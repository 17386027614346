import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartEvent,
  ActiveElement,
  Chart,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useEffect, useMemo, useState } from "react";
import { Facet, LegendType, PieType } from "../modals/Analytics.modal";
import { generateRainbowColors } from "../../utils/ColorGenerator";
import {
  updateAllFilter,
  updateJournal,
  updateSubject,
} from "../../store/slice/filterSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import { onSearch as search } from "../../screens/Search/onSearch";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { onAuthorResult as authorsearch } from "../../screens/AuthorFinder/onAuthorResult";
import {
  updateAuthorPage,
  updateAuthorRow,
} from "../../store/slice/authorFinderSlice";
import { checkDuplicateFilter, toTitleCase } from "../../utils/helper";
import { updateQueries } from "../../store/slice/appliedQueries";
import { filterUpdate } from "../../api/redux-thunks/filterUpdate";

ChartJS.register(ArcElement, Tooltip, Legend);
const chartOptions: ChartOptions<"pie"> = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 30,
  },
  plugins: {
    legend: {
      position: "right",
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      callbacks: {
        title: (ctx) => `${ctx[0].label}`,
        label: (_ctx) => _ctx.formattedValue,
        labelPointStyle: () => ({
          pointStyle: "circle",
          rotation: 0,
        }),
      },
    },
  },
};

const AnalyticsPieChart = ({
  chartData,
  count,
  type,
}: {
  chartData: Facet[];
  count: number;
  type: "subject" | "journal";
}) => {
  const dispatch = useAppDispatch();
  const authorReducer = useAppSelector((state) => state.authorReducer);
  const allAppliedFilter = useAppSelector(
    (state) => state.filterReducer.allAppliedFilter
  );

  const [legends, setLegends] = useState<LegendType | null>(null);
  const [pieData, setPieData] = useState<PieType | null>(null);
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const colors = useMemo(() => generateRainbowColors(count), [count]);

  useEffect(() => {
    if (chartData?.length > 0) {
      const currentCountArr = chartData.slice(0, count);
      const currentColorArr = colors.slice(0, count);

      const customLegends = currentCountArr?.map((item, i) => {
        return {
          name: item.val,
          count: item.count,
          color: currentColorArr[i],
        };
      });

      const data: PieType = {
        labels: currentCountArr?.map((item) => item.val),
        // labels: currentCountArr?.map((item) => toTitleCase(item.val)),
        datasets: [
          {
            data: currentCountArr?.map((item) => item.count),
            backgroundColor: currentColorArr,
            hoverOffset: 21,
            borderWidth: 0,
          },
        ],
      };

      setLegends(customLegends);
      setPieData(data);
    } else {
      setIsNoData(true);
    }
  }, [chartData, colors, count]);

  const handleSelectedValue = (value: string[] | undefined) => {
    const text = value?.[0];
    console.log("JOJO", text);

    type === "subject"
      ? dispatch(updateSubject([text]))
      : dispatch(updateJournal([text]));

    let filteredArr = allAppliedFilter.filter((obj) => obj?.key === type);
    let appliedFilter: any[] = [];

    const isExist = checkDuplicateFilter(allAppliedFilter, text!);
    if (!isExist) {
      appliedFilter.push({
        key: type,
        value: text,
      });
    }

    dispatch(updateAllFilter(appliedFilter));
    dispatch(updateQueries(appliedFilter));
    dispatch(filterUpdate(filteredArr, type));

    authorReducer.isAuthorAnalytics
      ? dispatch(updateAuthorPage(1)) &&
        dispatch(updateAuthorRow(15)) &&
        dispatch(authorsearch())
      : dispatch(search("", null, true));

    dispatch(hideModal());
  };

  const handlePieClick = (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: Chart<"pie", (number | null | undefined)[], unknown>
  ) => {
    if (elements.length > 0) {
      const element = elements[0];
      const { datasetIndex, index } = element;
      const label: any = chart.data.labels?.[index];
      const value = chart.data.datasets?.[datasetIndex].data?.[index];
      handleSelectedValue([label]);
    }
  };

  return (
    <>
      {isNoData ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            fontSize: "1.4rem",
          }}
        >
          No Data Available
        </Box>
      ) : (
        <Box
          sx={{
            height: "63vh",
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            p: 1,
            // overflow: "auto",
            overflow: { xs: "auto", sm: "auto", md: "hidden" },
          }}
        >
          <Box
            sx={{
              height: { xs: "50%", md: "100%" },
              width: { xs: "100%", md: "30%" },
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {pieData && (
              <Pie
                data={pieData}
                // options={{
                //   ...chartOptions,
                //   onClick: (_f, _u, chart) => {
                //     handleSelectedValue(chart.tooltip?.title);
                //   },
                // }}
                options={{
                  ...chartOptions,
                  onClick: handlePieClick,
                }}
              />
            )}
          </Box>

          {
            <Box
              sx={{
                height: "63vh",
                width: { xs: "100%", sm: "100%", md: "70%" },
                // width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1,
              }}
            >
              <Grid
                container
                spacing={{ xs: 0, sm: 1 }}
                sx={{
                  height: "100%",
                  width: "100%",
                  overflowY: "auto",
                  p: { xs: 0, sm: 0, md: 1 },
                  alignItems: "center",
                  //maxWidth: "600px",
                }}
              >
                {legends &&
                  legends?.map((item, i) => (
                    <>
                      <Grid xs={1}>
                        <Box
                          sx={{
                            width: { xs: "8px", md: "16px" },
                            height: { xs: "8px", md: "16px" },
                            backgroundColor: item.color,
                            borderRadius: "50%",
                            // mr: 1,
                          }}
                        />
                      </Grid>
                      <Grid xs={8} sm={9} md={9}>
                        <Text
                          noWrap
                          width={"100%"}
                          sx={{
                            fontSize: { xs: "0.9rem", md: "1rem" },
                          }}
                        >
                          {toTitleCase(item.name)}
                        </Text>
                      </Grid>
                      <Grid xs={3} sm={2} md={2}>
                        <Text
                          sx={{
                            ml: "auto",
                            textTransform: "capitalize",
                            fontSize: { xs: ".9rem", md: "1rem" },
                          }}
                        >
                          {item.count?.toLocaleString("en-US")}
                        </Text>
                      </Grid>
                    </>
                  ))}
              </Grid>
              <Grid xs={2}>
                <Text
                  sx={{
                    position: "relative",
                    writingMode: "vertical-rl",
                    transform: "rotate(180deg)",
                    color: "blue",
                    marginLeft: "6px",
                  }}
                >
                  Document Count
                </Text>
              </Grid>
            </Box>
          }
        </Box>
      )}
    </>
  );
};

export default AnalyticsPieChart;
