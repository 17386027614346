import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal, setModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import { alertStyles as styles } from "../../../screens/MyLibrary/MyAlerts/Alerts.style";
import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Colors } from "../../../utils/constants";
import {
  getAlertStatus,
  updateRSS,
} from "../../../api/MyLibrary/TOCAlerts.api";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { GetMyLibraryResData } from "../../../types/GetMyLibraryResources.types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { notify } from "../../../utils/Notify";
import { GetAlertStatusData } from "../../../types/GetAlertStatus.types";
import { usagelogApi } from "../../../api/usageReportApi";

interface Props {
  show: boolean;
  isTOC: boolean;
  alertData: GetMyLibraryResData;
}

const frequency = [
  {
    i: 1,
    freq: "Daily",
  },
  {
    i: 2,
    freq: "Weekly",
  },
  {
    i: 3,
    freq: "Bi-Weekly",
  },
  {
    i: 4,
    freq: "Monthly",
  },
  {
    i: 5,
    freq: "Quarterly",
  },
];

interface ChildModalProps {
  open: boolean;
  handleClose: () => void;
  handleContinue: () => void;
}
function ChildModal({ open, handleClose, handleContinue }: ChildModalProps) {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...modalBaseStyles,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            py: 3,
          }}
        >
          <Text>
            Are you sure you want to disable all alerts to this Journal?
          </Text>
          <Box
            sx={{
              width: "100%",
              mt: 3,
              display: "flex",
              direction: { xs: "column", sm: "row" },
            }}
          >
            <Button
              variant="contained"
              sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
              onClick={handleContinue}
              color="error"
            >
              Yes
            </Button>
            <Button sx={{ color: Colors.black }} onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const SetAlertModal = ({ show, isTOC, alertData }: Props) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.user_id);

  const [alertStatusCode, setAlertStatusCode] = useState(-1);
  const [isEmail, setIsEmail] = useState(true);
  const [isRSS, setRSS] = useState(false);
  const [selectedFreq, setSelectedFreq] = useState<string>(
    frequency[0].i.toString()
  );

  //Nested Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleContinue = () => {
    setOpen(false);
    handleFormSubmit(0);
  };

  useQuery({
    queryKey: ["GetAlertStatus"],
    queryFn: () => getAlertStatus(userId, alertData.resource_id.toString()),
    select: ({ data }): GetAlertStatusData => data.data[0][0],
    onSuccess: ({ alert_status }) => {
      if (alertData.notification_set) {
        setAlertStatusCode(alert_status);
        if (alert_status === 1) {
          setIsEmail(true);
          setRSS(false);
        } else if (alert_status === 2) {
          setIsEmail(false);
          setRSS(true);
        } else if (alert_status === 3) {
          setIsEmail(true);
          setRSS(true);
        }
      } else {
        setIsEmail(false);
        setRSS(false);
      }
    },
  });

  const updateRSSHandler = useMutation({
    mutationFn: (obj: { formData: FormData }) => updateRSS(obj),
    onSuccess: ({ data }) => {
      dispatch(hideModal());
      if (data?.data[0][0]?.status === "Failed") {
        notify("warning", data?.data[0][0]?.Message);
        return;
      }

      if (alertStatusCode !== 0) {
        dispatch(
          setModal({
            modalType: "ALERT_SUCCESS",
            modalProps: {
              show: true,
              rssURL: data.data[0][0].rssurl,
              alertStatusCode: alertStatusCode,
            },
          })
        );
      }

      queryClient.invalidateQueries({ queryKey: ["GetAllJournals"] });
      queryClient.invalidateQueries({ queryKey: ["GetAllLibResources"] });
      queryClient.invalidateQueries({ queryKey: ["ResourceCount"] });
    },
  });

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFreq(event.target.value);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmail(event.target.checked);
  };

  const handleRSS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRSS(event.target.checked);
  };
  const logAlertUsageData = (emailActionID: any, rssActionID: any) => {
    if (isEmail) {
      usagelogApi(
        profileData.user_id,
        profileData.informaticscustomer_id,
        profileData1?.consortiamaster_id,
        emailActionID,
        null,
        null,
        null,
        null,
        null,
        null,
        // profileData.ip_v4,
        null,
        null,
        profileData.session_key,
        1,
        null
      );
    }
    if (isRSS) {
      usagelogApi(
        profileData.user_id,
        profileData.informaticscustomer_id,
        profileData1?.consortiamaster_id,
        rssActionID,
        null,
        null,
        null,
        null,
        null,
        null,
        // profileData.ip_v4,
        null,
        null,
        profileData.session_key,
        1,
        null
      );
    }
    // isEmail
    //   ? usagelogApi(
    //       profileData.user_id,
    //       profileData.informaticscustomer_id,
    //       profileData1?.consortiamaster_id,
    //       emailActionID,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       // profileData.ip_v4,
    //       null,
    //       null,
    //       profileData.session_key,
    //       1,
    //       null
    //     )
    //   : null;
    // isRSS
    //   ? usagelogApi(
    //       profileData.user_id,
    //       profileData.informaticscustomer_id,
    //       profileData1?.consortiamaster_id,
    //       rssActionID,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       // profileData.ip_v4,
    //       null,
    //       null,
    //       profileData.session_key,
    //       1,
    //       null
    //     )
    //   : null;
  };

  const handleFormSubmit = (alertType: 0 | 1 | 2 | 3) => {
    //logAlertUsageData(100, 101);
    // alertData.resource_id == 8 ? logAlertUsageData(104, 105) : null;
    // alertData.resource_id == 24 ? logAlertUsageData(106, 107) : null;
    // alertData.resource_id == 4 ? logAlertUsageData(108, 109) : null;
    let data: any = {
      data: [
        {
          usermaster_id: userId,
          resourcemaster_id: alertData.resource_id,
          resourcetype_id: alertData.dat_resourcetype_id,
          alerttype_id: alertType,
        },
      ],
    };

    if (!isTOC) {
      data.data[0]["mail_frequency"] = selectedFreq;
    }

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(data));

    updateRSSHandler.mutate({ formData });
    if (alertType === 0) {
      notify("success", "Alerts Disabled Successfully");
    }
  };
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const handleAdd = () => {
    let alertType: 0 | 1 | 2 | 3 = 0;
    if (isEmail) {
      alertType = isRSS ? 3 : 1;
    } else if (isRSS) {
      alertType = 2;
    } else {
      setAlertStatusCode(0);
      handleOpen();
      return;
    }

    setAlertStatusCode(alertType);
    handleFormSubmit(alertType);
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="SetAlertModal-Title"
      aria-describedby="SetAlertModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          // height: "30vh",
          width: { xs: "80%", sm: "50%", md: "45%", lg: "35vw" },
          alignItems: "flex-start",
          justifyContent: "space-between",
          px: 3,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: { sx: "1rem", md: "1rem", lg: "1.4rem" },
              textAlign: "center",
            }}
          >
            Create New Email/RSS Alert
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>

        {!isTOC && (
          <Box sx={{ width: "100%", mb: 1 }}>
            <Text>Email Alert Frequency</Text>
            <Select
              value={selectedFreq}
              onChange={handleChange}
              displayEmpty
              sx={styles.frequency}
              inputProps={{ "aria-label": "Without label" }}
            >
              {frequency?.map((item) => (
                <MenuItem value={item.i} key={item.i}>
                  {item.freq}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        <FormGroup sx={{ my: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={isEmail} onChange={handleEmail} />}
            label="Email Alerts"
          />
          <FormControlLabel
            control={<Checkbox checked={isRSS} onChange={handleRSS} />}
            label="RSS Alerts"
          />
        </FormGroup>

        <Box sx={{ width: "100%", my: 1, display: "flex" }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleAdd}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
        <ChildModal
          open={open}
          handleClose={handleClose}
          handleContinue={handleContinue}
        />
      </Box>
    </Modal>
  );
};

export default SetAlertModal;
