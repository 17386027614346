import { IconButton } from "@mui/material";
import { goToDown } from "../../utils/helper";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useEffect, useState } from "react";

export default function GotoBottomButton() {
  const [isBottom, setIsBottom] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Check if the user is at the bottom
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;

      const scrolledToBottom = scrollTop + windowHeight >= docHeight - 10; // Small offset to handle precision issues

      setIsBottom(scrolledToBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <IconButton
      aria-label="Scroll to Top"
      sx={{
        position: "fixed",
        top: isBottom ? "50px" : { xs: "317px", sm: "290px" },
        right: { xs: "1vw", sm: "3vw", md: "1vw" },
        transform: "translateY(-50%)",
        border: "1px solid #D1D5DB",
        backgroundColor: "#FFF",
        color: "primary.main",
        "&:hover": {
          border: "1px solid #D1D5DB",
          backgroundColor: "#FFF",
          color: "primary.main",
          boxShadow: 2,
        },
      }}
      onClick={() => goToDown()}
    >
      <ArrowDownwardIcon />
    </IconButton>
  );
}
