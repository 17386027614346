import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Text from "@mui/material/Typography";
import SearchImage from "../../assets/images/Search.png";
import AdvancedSearchImage from "../../assets/images/AdvanceSearch.png";
import { useAppSelector } from "../../store/hooks";

interface PropType {
  isAdvanceSearch?: boolean;
}
const HeaderSection = (props: PropType) => {
  const customer_details = useAppSelector(
    (state) => state?.customer?.customerDetails
  );
  const { isAdvanceSearch } = props;
  return (
    <Stack
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      direction={"row"}
      // sx={{
      //   width: "100%",
      //   height: "40vh",
      //   position: "relative",
      //   overflow: "hidden",
      // }}
      className="image-parent-container"
    >
      {/*Package name*/}
      {/* {!isAdvanceSearch && customer_details?.product_package && (
        <div
          // style={{
          //   position: "absolute",
          //   top: 5,
          //   left: 5,
          //   zIndex: 1,
          //   color: "#7852FB",
          //   textTransform: "capitalize",
          //   fontSize: "20px",
          // }}
          className="product-package-details"
        >
          {customer_details?.product_package}
        </div>
      )} */}
      <img
        src={isAdvanceSearch ? AdvancedSearchImage : SearchImage}
        alt="Search"
        className="basicsearch-image"
        // style={{
        //   width: "100%",
        //   height: "115%",
        //   // width: "100vw",
        //   objectFit: "cover",
        //   position: "absolute",
        //   top: 0,
        //   left: 0,

        //   // width:"100%",
        //   // height:"105%",
        //   // position:"absolute",
        //   // bottom:0,
        //   // left:0
        // }}
      />

      {!isAdvanceSearch && customer_details?.product_package && (
        <div
          // style={{
          //   position: "absolute",
          //   top: 5,
          //   left: 5,
          //   zIndex: 1,
          //   color: "#7852FB",
          //   textTransform: "capitalize",
          //   fontSize: "20px",
          // }}
          className="product-package-details"
        >
          {customer_details?.product_package}
        </div>
      )}

      <Box
        // sx={{
        //   position: "absolute",
        //   width: "100%",
        //   height: "7vh",
        //   bottom: 0,
        //   right: 0,
        //   background:
        //     "linear-gradient(180deg, rgba(250, 248, 245, 0.0), rgba(250, 248, 245, 0.2), rgba(250, 248, 245, 0.5), rgba(250, 248, 245, 0.8), rgba(250, 248, 245, 1))",
        // }}
        className="gradient-overlay"
      />
    </Stack>
  );
};

export default HeaderSection;
