import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { MathJax } from "better-react-mathjax";
import { useState } from "react";
import {
  DownloadFromApi,
  downloadDatasetApi,
} from "../../utils/abstractDownload";
import { Colors, dataTypes } from "../../utils/constants";
import { removeSpecialCharacters } from "../../utils/helper";
import { highlightAbstract, highlightKeys } from "../../utils/highlightKeyword";

import { getDataSetDetails } from "../../api/Search/BasicSearch.api";
import DatasetPurpleIcon from "../../assets/img/databasepurple.svg";
import DatasetWhiteIcon from "../../assets/img/databasewhite.svg";

export default function AbstractModal(props: any) {
  const {
    dialogueData,
    handleKeyword,
    searchTerm,
    keywords,
    keywordData,
    isOpac,
    showDoi,
    showAuthorAffiliation,
    isStringOfArray,
    sortAuthorAffliliation,
    authorAffiliation,
    showAbstract,
    location,
    tocGetDataList,
    tocSearchTerm,
    abstractUrl,
    fromJournalToc,
    authorEmailData,
    authorEmail_data,
    refrences,
    citeDetails,
    loading1,
  } = props;
  type TabType = "abstract" | "author" | "references" | "cite" | "dataset";
  const [tabs, setTabs] = useState<TabType>("abstract");
  const [datasetData, setDatasetData] = useState<any>([]);
  console.log("dd", dialogueData);

  const handleDoiClick = (id: any) => {
    window.open(`/abstractFullScreen?id=${id}`);
  };

  const removeUrl = (datastring: string) => {
    const underlineregex = /<u>.*?<\/u>/g;
    const cleanedText = datastring.replace(underlineregex, "");
    return cleanedText;
  };

  async function handleDataset() {
    const res = await getDataSetDetails(dialogueData?.article_id);

    if (res?.total_count == 1) {
      window.open(res?.dataset_details[0]?.data_link);
    } else {
      setDatasetData(res?.dataset_details);
      setTabs("dataset");
    }
  }

  return (
    <Grid
      container
      spacing={1}
      sx={{ width: "95%", marginInline: "auto" }}
      rowGap={1}
      direction={"column"}
    >
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Conference proceedings Section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Grid item>
        {dialogueData?.data_type !== dataTypes.conferenceProceedings &&
        dialogueData?.issue_title ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box minWidth={"150px"}>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
              >
                Proceedings:
              </Typography>
            </Box>
            <Typography variant="body2">{dialogueData?.issue_title}</Typography>
          </Box>
        ) : null}
      </Grid>

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Keywords section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Grid item>
        {keywords?.length ? (
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box minWidth={"150px"}>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
              >
                Keywords:
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap" }} flexWrap="wrap">
              {dialogueData?.keywords?.map((val: string, i: number) => {
                return (
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{
                        fontFamily: "Helvetica Neue",
                        paddingTop: 0,
                        paddingBottom: 0,
                        display: "flex",
                        flexDirection: "row",
                        textTransform: "capitalize",
                        // padding: i === 0 ? "0px 8px 0px 0px" : "0px 8px",
                        padding: 0,
                        ":hover": { textDecoration: "underline" },
                        color: fromJournalToc ? "black" : "",
                      }}
                      onClick={() => {
                        if (!fromJournalToc) {
                          handleKeyword(val, null, dialogueData);
                        }
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: fromJournalToc
                            ? highlightKeys(val, tocSearchTerm)
                            : highlightKeys(val, searchTerm),
                        }}
                      />
                    </Button>
                    <Box paddingLeft={"8px"} />
                    <Divider
                      orientation="vertical"
                      style={{
                        height: "50%",
                        alignSelf: "center",
                      }}
                    />
                    <Box paddingRight={"8px"} />
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : null}
      </Grid>
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Citation Section>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

      <Grid item>
        {dialogueData?.data_type == dataTypes.journalArticle ||
        dialogueData?.data_type == dataTypes.journals ||
        isOpac ? (
          <>
            {showDoi && dialogueData?.articledoi ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box minWidth={"150px"}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                  >
                    Citation:
                  </Typography>
                </Box>
                <div
                  className="scite-badge"
                  data-doi={dialogueData?.articledoi}
                  data-layout="horizontal"
                ></div>
              </Box>
            ) : null}
          </>
        ) : null}
      </Grid>
      {/* Tabs Sections*/}
      {
        <Box
          sx={{
            gap: 3,
            display: "flex",
            paddingTop: 5,
          }}
        >
          <Button
            variant={tabs === "abstract" ? "contained" : "outlined"}
            onClick={() => {
              setTabs("abstract");
            }}
          >
            {dialogueData?.data_type == dataTypes.books &&
            !dialogueData?.has_chapter
              ? "About"
              : "Abstract"}
          </Button>
          {(dialogueData?.author_details || authorEmailData?.length > 0) && (
            <Button
              variant={tabs === "author" ? "contained" : "outlined"}
              onClick={() => {
                setTabs("author");
              }}
              disabled={!showAuthorAffiliation}
            >
              Author Affiliations
            </Button>
          )}
          <Button
            variant={tabs === "references" ? "contained" : "outlined"}
            onClick={() => {
              setTabs("references");
            }}
            disabled={
              !loading1 &&
              (refrences?.data[0]?.reference_count === 0 ||
                refrences?.data[0]?.reference_count === undefined)
            }
          >
            References{" "}
            {!loading1 &&
              refrences?.data[0]?.reference_count &&
              `(${refrences?.data[0]?.reference_count})`}
            {loading1 && <CircularProgress size="1rem" />}
          </Button>
          <Button
            variant={tabs === "cite" ? "contained" : "outlined"}
            onClick={() => {
              setTabs("cite");
            }}
            disabled={
              !loading1 &&
              (parseInt(citeDetails?.data[0]?.cited_by_count) === 0 ||
                citeDetails?.data[0]?.cited_by_count === undefined)
            }
          >
            Cited By{" "}
            {!loading1 &&
              citeDetails?.data[0]?.cited_by_count &&
              `(${citeDetails?.data[0]?.cited_by_count})`}
            {loading1 && <CircularProgress size="1rem" />}
          </Button>
          {dialogueData?.has_datasets && (
            <Button
              variant={tabs === "dataset" ? "contained" : "outlined"}
              startIcon={
                <img
                  src={
                    tabs === "dataset" ? DatasetWhiteIcon : DatasetPurpleIcon
                  }
                  alt="DatasetIcon"
                />
              }
              onClick={handleDataset}
            >
              Dataset
            </Button>
          )}
        </Box>
      }

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Abstract Section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      {tabs === "abstract" && (
        <Grid item>
          {dialogueData?.abstract ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box minWidth={"150px"}></Box>
              <MathJax>
                <Typography variant="body2" sx={{ textAlign: "justify" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: fromJournalToc
                        ? highlightAbstract(
                            tocGetDataList,
                            tocSearchTerm,
                            dialogueData?.article_id,
                            dialogueData?.abstract
                          )
                        : highlightAbstract(
                            keywordData,
                            searchTerm,
                            dialogueData?.article_id,
                            dialogueData?.abstract
                          ),
                    }}
                  />
                </Typography>
              </MathJax>
            </Box>
          ) : (
            <Box>
              {abstractUrl != "" && abstractUrl != null ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box minWidth={"150px"}></Box>
                  <Typography variant="body2">
                    <a
                      target="_blank"
                      style={{ color: Colors.primary }}
                      href={`${abstractUrl}`}
                      rel="noreferrer"
                    >
                      {abstractUrl}
                    </a>
                  </Typography>
                </Box>
              ) : // for DF-1342
              // <Typography style={{ textAlign: "center", marginTop: 20 }}>
              //   Abstract not available
              // </Typography>
              null}
            </Box>
          )}
        </Grid>
      )}

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Doi Section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      {tabs === "abstract" && (
        <Grid item>
          {dialogueData?.data_type == dataTypes.journalArticle ||
          dialogueData?.data_type == dataTypes.journals ||
          dialogueData?.data_type == dataTypes.magazine ||
          dialogueData?.data_type == dataTypes.dissertationThesis ||
          dialogueData?.data_type == dataTypes.preprint ||
          dialogueData?.data_type == dataTypes.conferenceProceedings ||
          dialogueData?.data_type == dataTypes.audioVideo ||
          dialogueData?.data_type == dataTypes.dataset ||
          dialogueData?.data_type == dataTypes.books ||
          isOpac ? (
            <div>
              {dialogueData?.articledoi && !dialogueData?.bookdoi ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box minWidth={"150px"}>
                    <Typography
                      variant="body2"
                      sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                    >
                      DOI:
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    <a
                      target="_blank"
                      style={{ color: Colors.primary }}
                      href={`https://doi.org/${dialogueData?.articledoi}`}
                      rel="noreferrer"
                    >
                      https://doi.org/{dialogueData?.articledoi}
                    </a>
                  </Typography>
                </Box>
              ) : null}
              {dialogueData?.bookdoi ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box minWidth={"150px"}>
                    <Typography
                      variant="body2"
                      sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                    >
                      DOI:
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    <a
                      target="_blank"
                      style={{ color: Colors.primary }}
                      href={` https://dx.doi.org/${dialogueData?.bookdoi}`}
                      rel="noreferrer"
                    >
                      https://dx.doi.org/{dialogueData?.bookdoi}
                    </a>
                  </Typography>
                </Box>
              ) : null}
            </div>
          ) : null}
        </Grid>
      )}

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<< Author Affiliation Section>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      {tabs === "author" && (
        <Grid item>
          {dialogueData?.data_type == dataTypes.journalArticle ||
          dialogueData?.data_type == dataTypes.journals ||
          dialogueData?.data_type == dataTypes.audioVideo ||
          dialogueData?.data_type == dataTypes.preprint ||
          dialogueData?.data_type == dataTypes.conferenceProceedings ||
          dialogueData?.data_type == dataTypes.magazine ||
          dialogueData?.data_type == dataTypes.bookSeries ||
          dialogueData?.data_type == dataTypes.dissertationThesis ||
          dialogueData?.data_type == dataTypes.dataset ||
          dialogueData?.data_type == dataTypes.books ||
          isOpac ? (
            <div>
              {showAuthorAffiliation?.length ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box minWidth={"150px"}>
                    <Typography
                      variant="body2"
                      sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                      pt="1.2rem"
                    >
                      Author Affiliations:
                    </Typography>
                  </Box>
                  <Box
                  // sx={screen ? { width: "100%" } : { width: "70%" }}
                  >
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        size="medium"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
                            <TableCell>Author Name</TableCell>
                            <TableCell>Affliliation</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isStringOfArray(dialogueData?.author_details) ? (
                            sortAuthorAffliliation(
                              dialogueData?.author_details
                            )?.map((row: string, i: number) => (
                              <>
                                {row.split("$")[1] && (
                                  <TableRow
                                    key={row}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.split("$")[0]}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.split("$")[1]}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))
                          ) : (
                            <>
                              {Array.isArray(authorAffiliation) &&
                                authorAffiliation?.map(
                                  (obj: any, i: number) => (
                                    <TableRow
                                      key={i}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {obj?.author_fname +
                                          " " +
                                          obj?.author_lname}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        {removeSpecialCharacters(
                                          obj?.author_affiliation
                                        )}
                                        {obj.author_country_name
                                          ? ", " + obj?.author_country_name
                                          : ""}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              ) : null}
            </div>
          ) : null}
        </Grid>
      )}

      {/**==================== Author Email =============================*/}
      {tabs === "author" && (
        <Grid item>
          {dialogueData?.data_type == dataTypes.journalArticle ||
          dialogueData?.data_type == dataTypes.journals ||
          dialogueData?.data_type == dataTypes.audioVideo ||
          dialogueData?.data_type == dataTypes.preprint ||
          dialogueData?.data_type == dataTypes.conferenceProceedings ||
          dialogueData?.data_type == dataTypes.magazine ||
          dialogueData?.data_type == dataTypes.bookSeries ||
          dialogueData?.data_type == dataTypes.dissertationThesis ||
          dialogueData?.data_type == dataTypes.dataset ||
          dialogueData?.data_type == dataTypes.books ||
          isOpac ? (
            <>
              {authorEmailData?.length ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box minWidth={"150px"}>
                    <Typography
                      variant="body2"
                      sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                    >
                      Correspondence Email:
                    </Typography>
                  </Box>
                  <div>{authorEmail_data}</div>
                </Box>
              ) : null}
            </>
          ) : null}
        </Grid>
      )}
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<References Section>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      {tabs === "references" && (
        <Grid item>
          {tabs === "references" &&
            (dialogueData?.data_type == dataTypes.journalArticle ||
            dialogueData?.data_type == dataTypes.journals ||
            dialogueData?.data_type == dataTypes.magazine ||
            dialogueData?.data_type == dataTypes.dissertationThesis ||
            dialogueData?.data_type == dataTypes.preprint ||
            dialogueData?.data_type == dataTypes.conferenceProceedings ||
            dialogueData?.data_type == dataTypes.audioVideo ||
            dialogueData?.data_type == dataTypes.books ||
            isOpac ? (
              <div>
                {dialogueData?.articledoi || dialogueData?.bookdoi ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      margin: " 0.2rem 0 0.3rem 0",
                    }}
                  >
                    <Box>References : </Box>
                    <Box
                      sx={{
                        paddingLeft: 2,
                        paddingTop: 2,
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                      }}
                    >
                      {refrences?.data[0]?.reference?.map(
                        (item: any, index: any) => {
                          return (
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  cursor: item?.jgate_article_id
                                    ? "pointer"
                                    : "default",
                                }}
                                key={item?.key}
                                onClick={() => {
                                  if (item?.jgate_article_id)
                                    handleDoiClick(item?.jgate_article_id);
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      `[${index + 1}]. ` +
                                      removeUrl(item?.refernce_data),
                                  }}
                                ></div>
                                {item?.jgate_article_id && (
                                  <div
                                    style={{
                                      textDecoration: "underline",
                                      color: "darkblue",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (item?.jgate_article_id)
                                        handleDoiClick(item?.jgate_article_id);
                                    }}
                                  >
                                    {item?.article_url}
                                  </div>
                                )}
                              </Typography>
                            </Box>
                          );
                        }
                      )}
                      {refrences?.data[0]?.reference_count > 50 && (
                        <Typography style={{ color: "green" }}>
                          Note : Please access the Full Text of the article for
                          the Complete Cited Reference List
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ) : null}
              </div>
            ) : null)}
        </Grid>
      )}
      {tabs === "cite" && (
        <Grid item>
          {dialogueData?.data_type == dataTypes.journalArticle ||
          dialogueData?.data_type == dataTypes.journals ||
          dialogueData?.data_type == dataTypes.magazine ||
          dialogueData?.data_type == dataTypes.dissertationThesis ||
          dialogueData?.data_type == dataTypes.preprint ||
          dialogueData?.data_type == dataTypes.conferenceProceedings ||
          dialogueData?.data_type == dataTypes.audioVideo ||
          dialogueData?.data_type == dataTypes.books ||
          isOpac ? (
            <div>
              {dialogueData?.articledoi || dialogueData?.bookdoi ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: " 0.2rem 0 0.3rem 0",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Cited By :{" "}
                    <Button
                      onClick={() => {
                        DownloadFromApi(
                          refrences?.data[0]?.openalex_id,
                          refrences?.data[0]?.cited_by_count
                        );
                      }}
                    >
                      Download CSV
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: 2,
                      paddingTop: 2,
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                    }}
                  >
                    {citeDetails?.data[0]?.cited_records?.map(
                      (item: any, index: any) => {
                        return (
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{
                                cursor: item?.jgate_article_id
                                  ? "pointer"
                                  : "default",
                              }}
                              key={item?.key}
                              onClick={() => {
                                if (item?.jgate_article_id)
                                  handleDoiClick(item?.jgate_article_id);
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    `[${index + 1}]. ` +
                                    removeUrl(item?.refernce_data),
                                }}
                              ></div>
                              {item?.jgate_article_id && (
                                <div
                                  style={{
                                    textDecoration: "underline",
                                    color: "darkblue",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (item?.jgate_article_id)
                                      handleDoiClick(item?.jgate_article_id);
                                  }}
                                >
                                  {item?.article_url}
                                </div>
                              )}
                            </Typography>
                          </Box>
                        );
                      }
                    )}
                    {citeDetails?.data[0]?.cited_by_count > 50 && (
                      <Typography style={{ color: "green" }}>
                        Note : Please download to view complete list
                      </Typography>
                    )}
                  </Box>
                </Box>
              ) : null}
            </div>
          ) : null}
        </Grid>
      )}
      {/*************************************************************************************************************************************
          Dataset links Section
        *************************************************************************************************************************************/}
      {tabs === "dataset" &&
        (dialogueData?.has_datasets || isOpac ? (
          <div>
            {datasetData?.length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  paddingTop: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    minWidth: "150px",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                    pt="1.2rem"
                  >
                    Dataset Links:
                  </Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      downloadDatasetApi(dialogueData?.article_id);
                    }}
                  >
                    Export
                  </Button>
                </Box>

                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    size="medium"
                  >
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
                        <TableCell>Dataset Title</TableCell>
                        <TableCell>Dataset Link</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {datasetData?.map((item: any) => {
                        return (
                          <TableRow>
                            <TableCell>{item?.dataset_title}</TableCell>
                            <TableCell>
                              <a
                                target="_blank"
                                style={{ color: Colors.primary }}
                                href={item?.data_link}
                                rel="noreferrer"
                              >
                                {item?.data_link}
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : null}
          </div>
        ) : null)}
    </Grid>
  );
}
