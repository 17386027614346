import { Box, Button } from "@mui/material";
import { Colors } from "../../utils/constants";
// interface PropType {
//     data: string;
//     selected: boolean;
//     handleChange: (e: string) => void;
// }

interface Props {
  selectedTags: boolean[];
  selectOnClick?: any;
}

const AlphabetSearch = ({ selectedTags, selectOnClick }: Props) => {
  const tagData: String[] = [
    "ALL",
    "(0-9)",
    "A-C",
    "D-F",
    "G-I",
    "J-L",
    "M-O",
    "P-R",
    "S-U",
    "V-Z",
  ];

  return (
    // <Box style={{ marginTop: "20px" }}>
    <Box
      sx={{
        display: "flex",
        marginTop: { xs: "10px", sm: "20px" },
        flexWrap: { xs: "nowrap", sm: "nowrap", md: "wrap" }, // No wrap for xs & sm, wrap for md+
        overflowX: { xs: "auto", sm: "auto", md: "unset" }, // Enable horizontal scroll only on xs & sm
        whiteSpace: { xs: "nowrap", sm: "nowrap", md: "normal" }, // Prevent wrapping only for xs & sm
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          height: "6px", // Scrollbar for xs & sm
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#ccc",
          borderRadius: "3px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f0f0f0",
        },
      }}
    >
      {tagData?.map((element, index) => (
        <Button
          key={index}
          onClick={() => selectOnClick(index)}
          sx={{
            // minWidth: "41px",
            // minWidth: "36px",
            minWidth: {
              xs: "45px",
              sm: "45px",
              md: "36px",
              lg: "36px",
              xl: "36px",
            },
            blockSize: " fit-content",
            fontSize: "12px",
            borderRadius: "8px",
            // display: ' inline-block',
            justifyContent: "space-around",
            //  width: "auto",
            // whiteSpace: 'normal',
            height: "35px",
            background: selectedTags[index] == true ? "#E4DCFE" : "#F3F4F6",
            border:
              selectedTags[index] == true
                ? "1px solid #7852FB"
                : "1px solid #E5E7EB",
            margin: "3.5px",
            alignItems: "center",
            // flexGrow: 2,
            fontFamily: "inter",
          }}
        >
          {element}
        </Button>
      ))}
    </Box>
  );
};

export default AlphabetSearch;
