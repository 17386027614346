import { useEffect, useRef, useState } from "react";
import logo from "../assets/img/logo.png";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, Typography } from "@mui/material";
import { Colors } from "../../utils/constants";
import {
  updateBrowserPage,
  updatecollection,
} from "../../store/slice/browseFilterSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { goToTop, showIsProfileToast } from "../../utils/helper";
import { useLocation } from "react-router-dom";

//Type
interface PropType {
  jsonFacets?: any;
  data?: any;
  setFilters: any;
  setMyPersonalLibrary: any;
  setCollectionFilter?: any;
  from?: any;
  setFilterDrawer?: any;
}
const CollectionsComponentOfBrowser = (props: PropType) => {
  let {
    jsonFacets,
    data,
    setFilters,
    setMyPersonalLibrary,
    setCollectionFilter,
    from,
    setFilterDrawer,
  } = props;

  const [collectionsValue, setcollectionsValue] = useState("");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const previousLocation = useRef(location);
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );
  const enable_onos = useAppSelector(
    (state: any) => state.customer?.customerDetails?.is_enable_onos
  );
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const selectedFilters: any = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  const ids = useAppSelector(
    (state) => state.customer.customerDetails?.informaticscustomer_id
  );
  const customerData = localStorage.getItem("informaticscustomer_id") || ids;
  const selectedAtoZCollection: any = useAppSelector(
    (state: any) => state.browseFilterSlice.collectionsValueJournal
  );

  const userData = useAppSelector((state) => state.customer.customerDetails);

  const subjectFilterData = useAppSelector(
    (state) => state?.browseFilterSlice?.subject
  );

  const [isProfileUser, setIsProfileUser] = useState(false);
  const showJgateCollection = userData?.product_type?.includes("1");

  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    console.log("USERUSER", userId);
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }
  }, []);

  useEffect(() => {
    if (
      selectedAtoZCollection == "My Library Collections" ||
      selectedAtoZCollection == "Consortia Collections" ||
      selectedAtoZCollection == "J-GateCollection"
    ) {
      dispatch(updatecollection(selectedAtoZCollection));
      setcollectionsValue(selectedAtoZCollection);
      generatedFilterString(selectedAtoZCollection);
    } else if (
      (previousLocation?.current?.state?.from !==
        "BrowseJournalTableSearch/BrowseJournalSearch" &&
        previousLocation?.current?.pathname !==
          "/BrowseJournalTableSearch/PackageArticle") ||
      selectedFilters?.collectionsValueJournal == ""
    ) {
      if (userData?.product_type === "1" || userData?.product_type === "1,7") {
        if (from === "package") {
          dispatch(updatecollection("My Library Collections"));
          setcollectionsValue("My Library Collections");
          generatedFilterString("My Library Collections");
        } else {
          dispatch(updatecollection("J-GateCollection"));
          setcollectionsValue("J-GateCollection");
          generatedFilterString("J-GateCollection");
        }
      } else if (userData?.product_type === "7") {
        dispatch(updatecollection("Consortia Collections"));

        setcollectionsValue("Consortia Collections");
        generatedFilterString("Consortia Collections");
      }
    }
  }, [userData]);

  function handleClearAllCollections() {
    if (
      customerDetails?.product_type === "1" ||
      customerDetails?.product_type === "1,7"
    ) {
      dispatch(updatecollection("J-GateCollection"));

      setcollectionsValue("J-GateCollection");
      generatedFilterString("J-GateCollection");
    } else if (customerDetails?.product_type === "7") {
      dispatch(updatecollection("Consortia Collections"));

      setcollectionsValue("Consortia Collections");
      generatedFilterString("Consortia Collections");
    }
  }

  const handleChangeCollections = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValue = event.target.value;
    if (collectionsValue === event.target.value) {
      setcollectionsValue("");
      dispatch(updatecollection(""));
      generatedFilterString("");

      setTimeout(() => {
        setcollectionsValue(selectedValue); // Reapply the same value
        dispatch(updatecollection(selectedValue));
        generatedFilterString(selectedValue);
      }, 0);
    } else {
      if (event.target.value == "My Library Collections") {
        dispatch(updateBrowserPage(1));
        setcollectionsValue((event.target as HTMLInputElement).value);
        dispatch(updatecollection((event.target as HTMLInputElement).value));

        generatedFilterString(event.target.value);
        setFilterDrawer(false);
      }
      // else if (
      //   event.target.value == "My Library Collections" &&
      //   !isProfileUser
      // ) {
      //   showIsProfileToast(
      //     "There are no configured subjects nor subscription information for your library. Please contact your library administrator"
      //   );
      // }
      else if (
        event.target.value == "MyPersonalLibraryCollection" &&
        isProfileUser
      ) {
        dispatch(updateBrowserPage(1));
        dispatch(updatecollection((event.target as HTMLInputElement).value));

        setcollectionsValue((event.target as HTMLInputElement).value);
        generatedFilterString(event.target.value);
        setFilterDrawer(false);
      } else if (
        event.target.value == "MyPersonalLibraryCollection" &&
        !isProfileUser
      ) {
        showIsProfileToast(
          "Please login as profile user to view this collection"
        );
      }
      if (
        event.target.value == "J-GateCollection" ||
        event.target.value == "Open Access Collections" ||
        event.target.value == "Consortia Collections" ||
        event.target.value == "ONOS Collections"
      ) {
        dispatch(updateBrowserPage(1));
        dispatch(updatecollection((event.target as HTMLInputElement).value));

        setcollectionsValue((event.target as HTMLInputElement).value);
        generatedFilterString(event.target.value);
        setFilterDrawer(false);
      }
    }
    goToTop();
  };

  const updateFilters: any = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  //   function replaceParams(url:any,newpattern:any) {
  //     const pattern = /(&fq__fulltext_ACL=[^&]*|&fq__is_onos_resource=true)/g;

  //     // Replace the matched parameters with a new string
  //     const newUrl = url.replace(pattern, newpattern);

  //     return newUrl;
  // }

  function generatedFilterString(checkedItem: any) {
    setCollectionFilter(checkedItem);
    if (checkedItem === "MyPersonalLibraryCollection") {
      setMyPersonalLibrary(true);
    } else {
      setMyPersonalLibrary(false);
    }

    const collections =
      checkedItem == "My Library Collections"
        ? `&fq__fulltext_ACL=(${userData?.my_library_filter})`
        : checkedItem == "Open Access Collections"
        ? "&fq__fulltext_ACL=(0)"
        : checkedItem == "Consortia Collections"
        ? `&fq__fulltext_ACL=${userData?.consortia_filter}`
        : checkedItem == "ONOS Collections"
        ? "&fq__is_onos_resource=true"
        : "";

    // let filterModify
    // //changes needed
    // if(filters && (filters.includes('fq__fulltext_ACL=') || filters.includes('fq__is_onos_resource=true'))){

    //   filterModify=replaceParams(filters,collections)
    // }else{
    //   filterModify=filters+collections
    // }
    // console.log("These are filter modify>>>>> ",filterModify)

    setFilters(collections);
  }

  const handleDisabledCollection = () => {
    return from === "package";
  };

  const handleDisabledPersonalLibraryCollection = () => {
    return from === "package" || from === "publisher";
  };

  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={{ paddingInline: "7%", paddingBlock: "4%" }}>
          <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
            <Typography sx={webStyle.titleHeader} gutterBottom>
              Collections
            </Typography>
            {/* <Button onClick={handleClearAllCollections}>Clear All</Button> */}
          </Box>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedFilters?.collectionsValueJournal}
            onChange={handleChangeCollections}
          >
            {showJgateCollection && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                  },
                }}
                value="J-GateCollection"
                // @ts-ignore
                // control={<Radio onClick={handleChangeCollections} />}
                control={<Radio />}
                label={`J-Gate Collection`}
                disabled={handleDisabledCollection()}
              />
            )}
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.9rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                },
              }}
              value="My Library Collections"
              // @ts-ignore
              // control={<Radio onClick={handleChangeCollections} />}
              control={<Radio />}
              label={`${
                userData?.customershortname != null
                  ? userData?.customershortname + " " + "Subscribed"
                  : "My Library Collection"
              }`}
            />
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.9rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                },
              }}
              value="MyPersonalLibraryCollection"
              // @ts-ignore
              // control={<Radio onClick={handleChangeCollections} />}
              control={<Radio />}
              label={`My Personal Library Collection`}
              disabled={handleDisabledPersonalLibraryCollection()}
            />
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "0.9rem",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                },
              }}
              value="Open Access Collections"
              // @ts-ignore
              // control={<Radio onClick={handleChangeCollections} />}
              control={<Radio />}
              label="Open Access"
              disabled={handleDisabledCollection()}
            />
            {consortiamaster_id && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                  },
                }}
                value="Consortia Collections"
                // @ts-ignore
                // control={<Radio onClick={handleChangeCollections} />}
                control={<Radio />}
                label={`Consortia Subscriptions`}
              />
            )}

            {enable_onos && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.9rem",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                  },
                }}
                value="ONOS Collections"
                // @ts-ignore
                // control={<Radio onClick={handleChangeCollections} />}
                control={<Radio />}
                label="ONOS Collections"
              />
            )}
          </RadioGroup>
        </Box>
      </Box>
    </>
  );
};

export default CollectionsComponentOfBrowser;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
