import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Colors } from "../utils/constants";
import metaData from "../utils/metadata";

interface P {
  dialogueData?: any;
}

const BookDetails: React.FC<P> = ({ dialogueData }) => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        width: "100%",
        mx: "auto",
        mt: "15px",
        boxSizing: "border-box",
      }}
      direction="column"
    >
      {/* Title */}
      <Grid item>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "600",
            fontSize: "1.2rem",
            fontFamily: "Lora",
            wordBreak: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          {dialogueData?.has_chapter
            ? dialogueData?.title || dialogueData?.book_name
            : dialogueData?.book_name}
        </Typography>
      </Grid>

      {/* Metadata */}
      <Grid item>
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "0.875rem",
            color: Colors.black,
            fontStyle: "italic",
            wordBreak: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          {metaData(dialogueData)}
        </Typography>
      </Grid>

      {/* Editors */}
      {dialogueData?.editors && (
        <Grid item>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              Editor:
            </Typography>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {dialogueData?.editors.join(", ")}
            </Typography>
          </Box>
        </Grid>
      )}

      {/* About Book */}
      {(dialogueData?.aboutresource ||
        dialogueData?.about_resource ||
        dialogueData.abstract) && (
        <Grid item>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Helvetica Neue",
                fontWeight: "700",
                wordBreak: "break-word",
              }}
            >
              About Book:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: dialogueData?.aboutresource
                    ? dialogueData?.aboutresource
                    : dialogueData?.about_resource
                    ? dialogueData?.about_resource
                    : dialogueData.abstract,
                }}
              />
            </Typography>
          </Box>
        </Grid>
      )}

      {/* ISBN */}
      {dialogueData?.p_isbn && (
        <Grid item>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              ISBN:
            </Typography>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {dialogueData?.p_isbn}
            </Typography>
          </Box>
        </Grid>
      )}

      {/* Book Link */}
      <Grid item>
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            Book Link:
          </Typography>
          <Typography variant="body2" sx={webStyle.informationText}>
            <a
              target="_blank"
              style={{
                color: Colors.primary,
                wordBreak: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
              }}
              href={`${dialogueData?.bookurl}`}
              rel="noreferrer"
            >
              {dialogueData?.bookurl}
            </a>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};


export default BookDetails;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",
  },
  informationText: {
    width: "70%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
