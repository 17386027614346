import { styled, SxProps } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Colors } from "../../../utils/constants";

const selectTextWrapper: SxProps = {
  display: "flex",
  my: 3,
  cursor: "pointer",
  borderRadius: 3,
  border: "1px solid",
  borderColor: Colors.gray_400,
  mr: "auto",
};

const addManageCell: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const search: SxProps = {
  my: 1,
  p: "0.5rem 0.5rem 0.5rem 0",
  borderRadius: 2,
  // flex: ".5 0 300px",
  flex: { xs: ".5 0 100%", sm: ".5 0 300px" },
};

const searchType: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
};

const viewResult: SxProps = {
  textDecoration: "underline",
  color: Colors.primary,
  fontSize:{ xs:".7rem",sm:"0.8rem",md:"0.875rem"},
  mr: 3,
  "&:hover": {
    cursor: "pointer",
  },
  textAlign: "start",
  width: {xs:"100%",md:200},
};

const searchTypeOptions: SxProps = {
  display: "flex",
  alignItems: "center",
};

const addAlertButton: SxProps = {
  float: "right",
  my: 2,
  py: 1,
  fontSize:{ xs:".7rem",sm:"0.8rem",md:"0.875rem"},
};

const keyword: SxProps = {
  width: "100%",
  border: "1px solid",
  borderColor: "#D1D5DB",
  p: 1,
  borderRadius: 2,
  my: 1,
};

const frequency: SxProps = {
  width: { xs: "100%", sm: "50%", md: "50%", lg: "50%", xl: "50%" },
  border: "1px solid",
  borderColor: "#D1D5DB",
  px: "1rem 0.5rem",
  borderRadius: 2,
  my: 1,
  fontSize: "0.875rem",
};

const avThesesBody: SxProps = {
  width: "100%",
  display: "flex",
  mt: 3,
  mb: 1,
};

const list: SxProps = {
  width: "50%",
  height: "100%",
  borderRight: "1px solid #E4E4E7",
  minHeight: "42vh",
  maxHeight: "56vh",
  overflowY: "auto",
  p: 1,
};

const listItem: SxProps = {
  width: "100%",
  color: "#343332",
  borderBottom: "1px solid #E4E4E7",
  fontSize:{ xs:".7rem",sm:"0.8rem",md:"0.875rem"},
  py: 2,
  px: 1,
  "&:hover": {
    cursor: "pointer",
    // backgroundColor: Colors.coolGray100,
  },
};

const checkboxList: SxProps = {
  width: "50%",
  height: "100%",
  borderRight: "1px solid #E4E4E7",
  minHeight: "42vh",
  maxHeight: "56vh",
  overflowY: "auto",
  p: 1,
};

const checkboxListItem: SxProps = {
  height: "100%",
  width: "100%",
  p: 1,
  display: "flex",
  fontSize:{ xs:".7rem",sm:"0.8rem",md:"0.875rem"},
  alignItems: "center",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: Colors.coolGray100,
  },
};

const addSubjects: SxProps = {
  display: "flex",
  alignItems: "center",
  color: Colors.primary,
  "&:hover": {
    cursor: "pointer",
  },
};

const addButton: SxProps = {
  float: "right",
  my: 2,
  py: 1,
  fontSize:{ xs:".7rem",sm:"0.8rem",md:"0.875rem"},
};

const chips: SxProps = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  color: Colors.primary,
  my: 4,
  "&:hover": {
    cursor: "pointer",
  },
  flexWrap: "wrap",
  gap: 0.5,
};

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const alertStyles = {
  selectTextWrapper,
  addManageCell,
  search,
  searchType,
  searchTypeOptions,
  viewResult,
  addAlertButton,
  keyword,
  frequency,
  avThesesBody,
  list,
  checkboxList,
  listItem,
  checkboxListItem,
  addSubjects,
  chips,
  addButton,
};
