import { IconButton } from "@mui/material";
import { goToTop } from "../../utils/helper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function GotoTopButton() {
  return (
    <IconButton
      aria-label="Scroll to Top"
      sx={{
        position: "fixed",
        bottom: "70px",
        right: { xs: "1vw", sm: "3vw", md: "1vw" },
        transform: "translateY(-50%)",
        border: "1px solid #D1D5DB",
        backgroundColor: "#FFF",
        color: "primary.main",
        "&:hover": {
          border: "1px solid #D1D5DB",
          backgroundColor: "#FFF",
          color: "primary.main",
          boxShadow: 2,
        },
      }}
      onClick={() => goToTop()}
    >
      <ArrowUpwardIcon />
    </IconButton>
  );
}
