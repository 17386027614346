import LockOpenIcon from "@mui/icons-material/LockOpen";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { MathJax } from "better-react-mathjax";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RightDrawerOfBook from "../../Book/rightDrawerBook";
import { updateBooksFavorites } from "../../api/MyLibrary/TOCAlerts.api";
import { Colors } from "../../utils/constants";
import highlightKeyword from "../../utils/highlightKeyword";
import LockIcon from "@mui/icons-material/Lock";

const BookBlocks: React.FC<any> = ({ item, favorite_set, setfavorite }) => {
  const [dialogueData, setDialogueData] = useState({});
  const [top, setTop] = useState(false);
  const navigate = useNavigate();
  const loginDetails: any = useSelector((state: any) => state.login);
  let userId = sessionStorage.getItem("user_id");
  const query = new URLSearchParams(window.location.search);
  let bookmaster_id = query.get("bookmasterid") || "";
  let [, setSearchParams] = useSearchParams();

  const handleDivClick = () => {
    const url = item?.bookurl;
    console.log("item", item);
    if (url) {
      const newTab = window.open("", "_blank");
      if (newTab) {
        newTab.location.href = url;
      } else {
        console.error("Unable to open a new tab");
      }
    }
  };

  const handlerightDrawer = (data: any) => {
    setTop(!top);
    setDialogueData(data);
  };

  const fav = async (ale: any) => {
    const data = {
      usermaster_id: userId,
      resource_ids: [parseInt(ale.bookmaster_id)],
      is_favourite: true,
    };

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(data));
    updateBooksFavorites({ formData });
    setfavorite((prev: any) => [...prev, ale.bookmaster_id]);
  };

  const removeFav = async (ale: any) => {
    const data = {
      usermaster_id: userId,
      resource_ids: [parseInt(ale.bookmaster_id)],
      is_favourite: false,
    };

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(data));
    updateBooksFavorites({ formData });
    setfavorite((prev: any) =>
      prev.filter((id: any) => id !== ale.bookmaster_id)
    );
  };
  const handlerClick = () => {
    if (!item?.has_chapter) handlerightDrawer(item);
    else {
      navigate(`/BookSearch/bookbrowser?id=${item?.bookmaster_id}`, {
        state: item,
      });
    }
  };

  useEffect(() => {
    if (bookmaster_id === item?.bookmaster_id) {
      query.delete("bookmasterid");
      setSearchParams(query, {
        replace: true,
      });
      navigate(`/BookSearch/bookbrowser?id=${item?.bookmaster_id}`, {
        state: item,
      });
    }
  }, [item]);

  return (
    <Box sx={webStyles.container}>
      <Box sx={webStyles.flexBox} onClick={handlerClick}>
        <Box style={{ width: "90%" }}>
          <Typography
            gutterBottom
            sx={[
              webStyles.text,
              {
                fontSize: { xs: "0.9rem", sm: "1.1rem", md: "1.3rem" },
                fontFamily: "Lora",
                fontWeight: 600,
              },
            ]}
            // onClick={() => handlerightDrawer(item)}
          >
            <MathJax>
              <div
                id="title"
                dangerouslySetInnerHTML={{
                  __html: highlightKeyword(
                    item.book_name ? item?.book_name : item?.title,
                    []
                  ),
                }}
                style={{ maxWidth: "80%" }}
              />
            </MathJax>
          </Typography>
          <Box style={{ display: "flex" }}>
            {item?.authors && (
              <Box sx={[webStyles.flexBox]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {/* {item.authors.join(" | ")} */}
                  {item?.authors?.map((author: any, index: any) => (
                    <React.Fragment key={index}>
                      {index > 0 && <span style={{ color: "gray" }}> | </span>}
                      {author}
                    </React.Fragment>
                  ))}
                </Typography>
                &nbsp; &nbsp;
              </Box>
            )}
          </Box>

          <Box style={{ display: "flex" }}>
            {item.publisher_name && (
              <Box sx={[webStyles.subText]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {`${item?.publisher_name}`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box style={{ display: "flex" }}>
            {item?.publication_date && (
              <Box sx={[webStyles.subText]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {`Publication Date: ${item.publication_date}`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box style={{ display: "flex" }}>
            {item.primary_publisher_country && (
              <Box sx={[webStyles.subText]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {`${item.primary_publisher_country}`}&nbsp;
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          {favorite_set?.includes(item?.bookmaster_id) ? (
            <Tooltip title="Remove Favourite">
              <IconButton
                style={{ color: "#FFC000" }}
                onClick={async (e) => {
                  e.stopPropagation();
                  removeFav(item);
                }}
              >
                <StarIcon></StarIcon>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Set Favourite">
              <IconButton
                onClick={async (e) => {
                  e.stopPropagation();
                  fav(item);
                }}
              >
                <StarOutlineIcon></StarOutlineIcon>
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box onClick={handleDivClick}>
          {item?.access_type_id === 24 ? (
            <LockOpenIcon color="secondary" href={item?.bookurl} />
          ) : (
            <LockIcon color="error" href={item?.bookurl} />
          )}
        </Box>
      </Box>

      <RightDrawerOfBook
        dialogueData={dialogueData}
        handleDawer={handlerightDrawer}
        state={top}
      />
    </Box>
  );
};

export default BookBlocks;

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    // margin: "0.5%",
  },
  container: {
    backgroundColor: Colors.white,
    padding: "2%",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "red",
    marginTop: "1%",
    cursor: "pointer",
  },
  text: {
    fontFamile: "Lora",
  },
  subText: {
    color: Colors.gray_600,
    fontFamily: "Helvetica Neue",
    fontSize: "0.8rem",
  },
  gridStyle: {
    position: "absolute",
    width: "330px",
    // height: "118px",
    right: "1px",
    top: "5px",
    marginTop: "50px",
    background: "#FFFFFF",
    border: "1px dashed rgba(33, 33, 33, 0.1)",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    dispay: "flex",
    justifyContent: "flex-end",
    zIndex: "100000",
  },
};
