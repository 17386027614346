import React from "react";
import { Button, Tooltip } from "@mui/material";
import { Colors } from "../../utils/constants";
import Stack from "@mui/material/Stack";
import LockIcon from "@mui/icons-material/Lock";

const RedLockClosed = (props: any) => {
  const { link, showGrid } = props;

  return (
    <Tooltip title="Subscription Required">
      <LockIcon
        style={{ color: Colors.red500 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{ fontSize: { xs: "23px", md: "24px" } }}
      />
    </Tooltip>
  );
};

export default RedLockClosed;
