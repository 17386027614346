import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getContainOrStartingWIth } from "../../store/slice/browseJournal";
import { updateBrowserSearch_type } from "../../store/slice/browseFilterSlicePackage";
import { Box } from "@mui/material";
interface P {
  items: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //width: 250,
      fontFamily: "Helvetica Neue",
    },
  },
};

function getStyles(name: string, personName: string, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function DropDownComponent(props: P) {
  const searchResult = useSelector(
    (state: any) => state.browseJournalSearch.containStartingWith
  );
  const packageSearch = sessionStorage.getItem("getTabSwitch");
  const theme = useTheme();
  const { items } = props;
  const [value, setValue] = React.useState("Starting With");
  const [values, setValues] = React.useState(["Starting With", "Containing"]);
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    if (packageSearch == "2") {
      setValue(event.target.value);
      handleSetValue(event.target.value);
      if (event.target.value == "Containing") {
        dispatch(updateBrowserSearch_type("3"));
      } else if (event.target.value == "Starting With") {
        dispatch(updateBrowserSearch_type("2"));
      } else {
        dispatch(updateBrowserSearch_type("1"));
      }
    } else {
      setValue(event.target.value);
      handleSetValue(event.target.value);
    }
  };

  const handleSetValue = (searchVal: string) => {
    dispatch(getContainOrStartingWIth(searchVal));
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: { xs: "100%", sm: "30%", md: "30%", lg: "20%" },
      }}
    >
      {/* <FormControl sx={{ m: 1, minWidth: 250, width: { xs: "100%" } }}> */}
      <FormControl
        sx={{
          mt: 1,
          mb: 1,
          ml: { xs: 0, sm: 1 },
          mr: { xs: 0, sm: 1 },
          //minWidth: { xs: 0, sm: 150 },
          // width: { xs: "100%", sm: "50%" },
          width: "100%",
        }}
      >
        <Select
          size="small"
          displayEmpty
          MenuProps={MenuProps}
          id="demo-simple-select-helper"
          value={value}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          sx={[
            webStyles.fontStyle,
            { borderRadius: 2, height: { xs: "44px" } },
          ]}
        >
          {values?.map((val) => {
            return (
              <MenuItem value={val} sx={{ ...webStyles.fontStyle }}>
                {val}
              </MenuItem>
            );
          })}
          {/* <MenuItem value="StartingWith" sx={webStyles.fontStyle}>
            Starting With
          </MenuItem> */}

          {/* <MenuItem value={"StartingWith"}>Starting With</MenuItem> */}
          {/* <MenuItem sx={webStyles.fontStyle} value={"Containing"}>
            Containing
          </MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
}

const webStyles = {
  fontStyle: {
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    fontSize: 14,
    // color: "#9B9B9B",
  },
};
