import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppSelector } from "../../../store/hooks";
import { modalBaseStyles } from "../RootModal";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import InputBase from "@mui/material/InputBase";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { GetMyPreference2Detail } from "../../../types/GetMySubjects.types";
import { useMutateModifyMySubjects } from "../../../api/Preferences/Subjects.api";

interface Props {
  show: boolean;
  mySubjects: GetMyPreference2Detail[];
  onClose: () => void;
}

const AddSubjectModal = ({ show, mySubjects, onClose }: Props) => {
  const selector = useAppSelector((state) => state.login);

  const modifySubjectHandler = useMutateModifyMySubjects();

  const [search, setSearch] = useState("");

  const [selectedSubs, setSelectedSubs] = useState<number[]>([]); //Contains IDs of selectedSubs

  const [filteredSubjects, setFilteredSubjects] = useState<
    GetMyPreference2Detail[]
  >([]);

  useEffect(() => {
    // Logic to filter subjects based on search term
    setFilteredSubjects(
      mySubjects.filter((subject) =>
        subject.subject_name2.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [mySubjects, search]);

  useEffect(() => {
    setFilteredSubjects(mySubjects);
    //Get all L2 subjects
    const myL2 = mySubjects
      .filter((y) => y.is_favorite)
      ?.map((x) => x.subject_id2);
    setSelectedSubs(() => myL2);
  }, [mySubjects]);

  const handleSubjectSelect = (subId: number) => {
    console.log(subId);
    //Remove
    if (selectedSubs.includes(subId)) {
      const newArray = selectedSubs.filter((x) => x !== subId);
      setSelectedSubs(() => newArray);
    } else {
      //Add
      setSelectedSubs((prev) => [...prev, subId]);
    }
  };

  const handleSearch = ({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) => setSearch(currentTarget.value);

  const handleAdd = () => {
    // if (true) {
    //Complex stuff, modify later for better readability 〜(￣▽￣〜)
    // const myL3 = allSubjects
    //   ?.map((w) => w.subject2_details)
    //   .flat()
    //   .filter((x) => selectedSubs.includes(parseInt(x.subject_id2)))
    //   ?.map((y) => y.subject3_details?.map((z) => z.subject_id3));

    // console.log(myL3);

    // }
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("subject_level3_ids", selectedSubs.join(","));

    modifySubjectHandler.mutate({ formData });
    onClose();
  };

  const handleClear = () => setSearch("");

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="FilterSubjectModal-Title"
      aria-describedby="FilterSubjectModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          // width: "49vw",
          width: {
            xs: "100%",
            sm: "80%",
            md: "60%",
            lg: "50%",
            xl: "50%",
          },
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            Filter Subjects
          </Text>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <InputBase
          sx={{
            my: 3,
            ml: 1,
            width: "98%",
            border: "1px solid",
            borderColor: "#D1D5DB",
            p: 1,
            borderRadius: 2,
          }}
          value={search}
          onChange={handleSearch}
          placeholder="Select subject you want to add to your filter"
          endAdornment={
            <IconButton size="small" onClick={handleClear}>
              {search && <CloseIcon fontSize="inherit" />}
            </IconButton>
          }
        />

        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            py: 1,
            overflowY: "auto",
            mb: 2,
          }}
        >
          {filteredSubjects?.map((val, i) => (
            <Grid
              key={i}
              xs={12}
              sm={6}
              sx={{
                // width: "50%",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleSubjectSelect(val.subject_id2)}
            >
              <Checkbox checked={selectedSubs.includes(val.subject_id2)} />
              <Text noWrap>{val.subject_name2}</Text>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ width: "100%", my: 1, display: "flex", direction: "row" }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleAdd}
          >
            Apply
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddSubjectModal;
