import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Backdrop,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { Colors } from "../../utils/constants";
import useDebounce from "../../hooks/useDebounce";
// import { onSearch as search } from "../../screens/Search/onSearch";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { queryConstructor } from "../../api/formQuery";
import { useSelector } from "react-redux";
import { AuthorFinderObj } from "../../types/interface";
import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";
import { basicSearch } from "../../api/Search/BasicSearch.api";
import { authorFinderApi } from "../../api/authorFinderApi";
import ClearIcon from "@mui/icons-material/Clear";

interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  modalName: string;
  getFilterdValues?: (e: string[]) => void;
  filterData?: any;
  applyFilter?: (e: string[]) => void;
  placeholderName?: string;
  appliedFilterValues?: string[];
  facetKey?: string;
  authorFilterScreen?: boolean;
  filterloader?: boolean;
}

interface ObjType {
  [key: string]: any;
}

const ModalTwoOfAuthor = (props: Proptype) => {
  const {
    isOpen,
    handleClose,
    modalName,
    getFilterdValues,
    filterData,
    applyFilter,
    placeholderName,
    appliedFilterValues,
    facetKey,
    authorFilterScreen,
    filterloader,
  } = props;

  const dispatch = useAppDispatch();
  const [filteredValues, setFilteredvalues] = useState<string[]>(
    Array.isArray(appliedFilterValues) ? appliedFilterValues : []
  );
  const [toggleValue, setToggleValue] = useState("relevance");
  const [facetData, setFacetData] = useState(filterData);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm);
  const profileData = useSelector((state: any) => state.login);

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const searchReducer: any = useSelector((state: any) => state.searchReducer);

  const filteredFacetData =
    facetData && facetData?.filter((item: any) => item.val !== "");

  useEffect(() => {
    if (debouncedValue) {
      handleSearch();
    } else {
      setFacetData(filterData);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValues();
  }, [isOpen]);

  function setValues() {
    if (Array.isArray(appliedFilterValues))
      setFilteredvalues(appliedFilterValues);

    setFacetData(filterData);
  }

  const selectedValues = (data: string) => {
    if (filteredValues.length === 0) {
      setFilteredvalues([data]);
      // getFilterdValues && getFilterdValues([data]);
    } else if (filteredValues.includes(data)) {
      setFilteredvalues(() => filteredValues.filter((e) => e !== data));
      // getFilterdValues &&
      //   getFilterdValues(filteredValues.filter((e) => e !== data));
    } else {
      setFilteredvalues((prev) => [...prev, data]);
      // getFilterdValues && getFilterdValues([...filteredValues, data]);
    }
  };

  const handleSubmit = () => {
    if (!filteredValues.length) return;
    getFilterdValues && getFilterdValues(filteredValues);
    applyFilter && applyFilter(filteredValues);
    setFilteredvalues([]);
    handleClose();
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setToggleValue(value);
    if (value === "alphabetical") {
      handleSort();
    } else {
      setFacetData(filterData);
    }
  };

  function handleCancel() {
    setFilteredvalues([]);
    handleClose();
  }

  function clearAll() {
    getFilterdValues && getFilterdValues([]);
    setFilteredvalues([]);
  }

  function handleSort() {
    let sortedData = [...facetData].sort((a: any, b: any) => {
      let valA = a.val.toUpperCase();
      let valB = b.val.toUpperCase();

      if (valA > valB) {
        return 1;
      }
      if (valA < valB) {
        return -1;
      }
      return 0;
    });
    setFacetData(sortedData);
  }

  // Handle search
  async function handleSearch() {
    if (typeof facetKey !== "string") return;
    try {
      let queryObj: ObjType = {};
      let json_facet = {
        [facetKey]: {
          type: "terms",
          field: facetKey,
          limit: 100,
          sort: "index asc",
          prefix: searchTerm,
        },
      };

      if (authorFilterScreen === true) {
        if (
          (authorReducer.firstname === "" && authorReducer?.lastname) ||
          (authorReducer.lastname === "" && authorReducer?.firstname) ||
          (authorReducer?.firstname && authorReducer?.lastname)
        ) {
          if (authorReducer?.firstname) {
            queryObj.author_firstname = `("${authorReducer.firstname
              ?.toString()
              ?.replaceAll('"', "")}")`;
          }
          if (authorReducer?.lastname) {
            queryObj.author_lastname = `("${authorReducer.lastname
              ?.toString()
              ?.replaceAll('"', "")}")`;
          }
          queryObj.fq__resourcetype_id = searchReducer.dataType;
          queryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
          queryObj.page = 1;
          queryObj.sort = "author_firstname asc, author_lastname asc";

          let query = await queryConstructor({
            json_facet: JSON.stringify(json_facet),
            ...queryObj,
          });
          let response = await authorFinderApi(query);
          let data = response?.jsonfacets[facetKey].buckets;
          setFacetData(data);
        }
      } else {
        const searchQueryObj = {
          fq__authors_tk: authorReducer.searchTerm,
          fq__subjects_id_l2: authorReducer.subjectType,
          fq__resource_type: searchReducer.dataType,
          sort: "dateofpublication desc",
        };

        let query = await queryConstructor({
          json_facet: JSON.stringify(json_facet),
        });

        if (authorReducer.fullText) {
          Object.assign(searchQueryObj, {
            // fq__fulltext: authorReducer.fullText,
            "fq__(fulltext": `true OR acl_group=(${profileData.informaticscustomer_id}))`,
          });
        }
        const searchQuery = await queryConstructor(searchQueryObj, "OR", true);
        query += `&${searchQuery}`;

        let response = await basicSearch(query);
        let data = response?.jsonfacets[facetKey].buckets;
        setFacetData(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function popupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {modalName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={false}
      sx={{
        width: { xs: "110vw", sm: "90%", md: "70%" }, // Adaptive width
        margin: "auto",
        left: { xs: "-5%", sm: "none" },
        right: { xs: "-5%", sm: "none" },
      }}
    >
      {popupTitle()}

      <DialogContent
        sx={{
          width: { xs: "100%", sm: "100%", md: 906 },
          padding: { xs: "15px 10px", sm: "20px 24px" },
        }}
      >
        {/* Search Bar */}
        <TextField
          size="small"
          fullWidth
          placeholder={`Search by ${placeholderName}`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2, marginBlock: { xs: 1, sm: 3 } }}
        >
          <Stack direction={"row"} alignItems="center">
            <ToggleButtonGroup
              onChange={handleChange}
              color="primary"
              exclusive
              aria-label="Platform"
              size="small"
            >
              <ToggleButton
                sx={[
                  toggleValue === "relevance"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    ":hover": {
                      background:
                        toggleValue === "relevance" ? Colors.primary : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
                value={"relevance"}
              >
                {/* Relevance */}
                Document Count
              </ToggleButton>
              <ToggleButton
                value="alphabetical"
                sx={[
                  toggleValue === "alphabetical"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    ":hover": {
                      background:
                        toggleValue === "alphabetical"
                          ? Colors.primary
                          : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
              >
                Alphabetical
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack
            sx={{
              display: {
                xs: "none",
                sm: "contents",
                md: "contents",
                lg: "contents",
                xl: "contents",
              },
            }}
          >
            <Button onClick={clearAll}>
              Clear All <CloseIcon fontSize="small" />
            </Button>
          </Stack>
          <IconButton
            sx={{
              display: {
                xs: "initial",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
              // mr: "10px",
            }}
            onClick={clearAll}
            size="large"
            color="primary"
          >
            <ClearIcon />
          </IconButton>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ gap: 2, overflowY: "auto" }}
          flexWrap="wrap"
          maxHeight={"60vh"}
        >
          {Array.isArray(filteredFacetData) &&
            filteredFacetData?.map((data: any) => (
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ gap: 2 }}
                // width={"400px"}
                width={{ xs: "100%", sm: "400px" }}
              >
                <Stack direction={"row"} alignItems="center">
                  <Checkbox
                    onClick={() => selectedValues(data?.val)}
                    checked={filteredValues?.includes(data?.val)}
                  />

                  <Tooltip title={data?.val}>
                    <Typography
                      sx={{
                        // width: "250px",
                        width: { xs: "50vw", sm: "250px" },
                        overflow: "hidden",
                        textOverflow: { xs: "none", sm: "ellipsis" },
                        display: "-webkit-box",
                        WebkitLineClamp: { xs: "none", sm: "1" },
                        WebkitBoxOrient: "vertical",
                        textTransform: "capitalize",
                        wordBreak: "break-word",
                        whiteSpace: { xs: "normal", sm: "none" },
                      }}
                    >
                      {data?.val}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Typography>({data?.count})</Typography>
              </Stack>
            ))}
        </Stack>
      </DialogContent>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{
          gap: 2,
          marginBlock: 2,
          position: "sticky",
          padding: "16px 24px",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ minWidth: 100 }}
        >
          Apply
        </Button>
        <Button onClick={handleCancel} sx={{ minWidth: 150, color: "#111928" }}>
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ModalTwoOfAuthor;

const styles = {
  checkedStyle: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    minWidth: "100px",
    borderRadopis: "6px",
  },
  unCheckedStyle: {
    minWidth: "100px",
    color: "#7852FB",
    backgroundColor: "#FFFFFF",
    borderRadopis: "6px",
  },
};
