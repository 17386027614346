import { SxProps } from "@mui/material/styles";
import { Colors } from "../../utils/constants";

const root: SxProps = {
  height: "calc(100vh - 70px)", //Compensate for Navbar height
  width: "100%",
  px: { xs: 2, sm: 7, md: 7, lg: 7, xl: 7 },
  py: 2,
  backgroundColor: "#F9FAFB",
};

const title: SxProps = {
  fontSize: "1.7rem",
  fontWeight: 600,
  mb: 2,
};

const body: SxProps = {
  backgroundColor: "#FFF",
  border: "1px solid",
  borderColor: Colors.coolGray300,
  height: "90%",
  width: "100%",
  borderRadius: "0.5rem",
  p: { xs: 0, sm: 2 },
};

const addSubjects: SxProps = {
  display: "flex",
  alignItems: "center",
  color: Colors.primary,
  "&:hover": {
    cursor: "pointer",
  },
};

const addDataTypes: SxProps = {
  py: 1,
  px: 5,
  mr: 3,
};

const chipsWrapper: SxProps = {
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  my: 2,
  gap: 2,
};

const chipClearAll: SxProps = {
  color: Colors.primary,
  "&:hover": {
    cursor: "pointer",
  },
};

export const prefernecesStyles = {
  root,
  title,
  body,
  addSubjects,
  addDataTypes,
  chipsWrapper,
  chipClearAll,
};
