import { Box, Button } from "@mui/material";
import { Colors } from "../../utils/constants";
// interface PropType {
//     data: string;
//     selected: boolean;
//     handleChange: (e: string) => void;
// }

interface Props {
  selectedTags: boolean[];
  selectOnClick?: any;
  enabledTags?: any;
}

const AlphabeticalFilter = ({
  selectedTags,
  selectOnClick,
  enabledTags,
}: Props) => {
  const tagData: String[] = [
    "ALL",
    "(0-9)",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: { xs: "nowrap", sm: "nowrap", md: "wrap" }, // No wrap for xs & sm, wrap for md+
        overflowX: { xs: "auto", sm: "auto", md: "unset" }, // Enable horizontal scroll only on xs & sm
        whiteSpace: { xs: "nowrap", sm: "nowrap", md: "normal" }, // Prevent wrapping only for xs & sm
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          height: "6px", // Scrollbar for xs & sm
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#ccc",
          borderRadius: "3px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f0f0f0",
        },
      }}
    >
      {tagData?.map((element, index) => (
        <Button
          key={index}
          onClick={() => selectOnClick(index)}
          sx={{
            minWidth: {
              xs: "45px",
              sm: "45px",
              md: "36px",
              lg: "36px",
              xl: "36px",
            },
            blockSize: " fit-content",
            fontSize: "12px",
            borderRadius: "8px",
            // display: ' inline-block',
            justifyContent: "space-around",
            //  width: "auto",
            // whiteSpace: 'normal',
            height: "35px",
            background: enabledTags
              ? !enabledTags?.includes(element)
                ? Colors.coolGray50
                : selectedTags[index] == true
                ? "#E4DCFE"
                : "#F3F4F6"
              : selectedTags[index] == true
              ? "#E4DCFE"
              : "#F3F4F6",
            border: enabledTags
              ? !enabledTags?.includes(element)
                ? "1px solid #e5e7eb"
                : selectedTags[index] == true
                ? "1px solid #7852FB"
                : "1px solid #E5E7EB"
              : selectedTags[index] == true
              ? "#E4DCFE"
              : "#F3F4F6",
            margin: "3.5px",
            alignItems: "center",
            // flexGrow: 2,
            fontFamily: "inter",
          }}
          disabled={enabledTags ? !enabledTags?.includes(element) : false}
        >
          {element}
        </Button>
      ))}
    </Box>
  );
};

export default AlphabeticalFilter;
