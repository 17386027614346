import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Colors } from "../../utils/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateSort } from "../../store/slice/filterSlice";
import Tooltip from "@mui/material/Tooltip";
import { onSearch as search } from "../../screens/Search/onSearch";
import { Box, colors, IconButton } from "@mui/material";
import { valueExists } from "../../utils/helper";
import { usagelogApi, usageReport } from "../../api/usageReportApi";
import { Sort } from "@mui/icons-material";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Props {
  disable?: boolean;
  sortData?: any;
}

const SortByRelevence = ({ disable, sortData }: Props) => {
  const sort = useAppSelector((state) => state.filterReducer.sort);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedSort, setSelectedSort] = React.useState("Publication Date");
  const [triggerEffect, setTriggerEffect] = React.useState(0);
  const [selectedRankSort, setSort] = React.useState("Date");
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );
  const open = Boolean(anchorEl);

  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const selectedFilters = useAppSelector((state) => state.filterReducer);
  const is_ONOSCustomer = useAppSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isONOS =
      is_ONOSCustomer && selectedFilters.collectionValue === "ONOSCollections"
        ? 1
        : 0;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };

  React.useEffect(() => {
    if (triggerEffect) onSearch();
  }, [triggerEffect]);

  React.useEffect(() => {
    updateDisplayedSortValue();
  }, [sort]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSort(val: string) {
    setTriggerEffect((prev) => prev + 1);
    if (val === "Publication Date") {
      // usageReport("74");
      logBasicSearchUsageData(74);
      dispatch(updateSort("dateofpublication desc"));
    } else if (val === "Best Match") {
      // usageReport("75");
      logBasicSearchUsageData(75);
      dispatch(updateSort("score desc"));
    } else if (val === "sjr_score desc,dateofpublication desc") {
      // usageReport("73");
      logBasicSearchUsageData(73);
      dispatch(updateSort("sjr_score desc,dateofpublication desc"));
    } else if (val === "sjr_hindex desc,dateofpublication desc") {
      // usageReport("73");
      logBasicSearchUsageData(73);
      dispatch(updateSort("sjr_hindex desc,dateofpublication desc"));
    } else if (val === "naas_value desc,dateofpublication desc") {
      // usageReport("73");
      logBasicSearchUsageData(73);
      dispatch(updateSort("naas_value desc,dateofpublication desc"));
    }
    setSelectedSort(val);
    handleClose();
  }

  async function onSearch() {
    dispatch(search());
  }

  function updateDisplayedSortValue() {
    try {
      if (sort === "dateofpublication desc") {
        setSort("Date");
      }
      if (sort === "score desc") {
        setSort("Best Match");
      }
      if (sort === "sjr_score desc,dateofpublication desc") {
        setSort("SJR");
      }
      if (sort === "sjr_hindex desc,dateofpublication desc") {
        setSort("H-Index");
      }
      if (sort === "naas_value desc,dateofpublication desc") {
        setSort("NAAS");
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <Button
        sx={{
          textAlign: "left",
          color: Colors.black,
          paddingTop: "2.5px",
          paddingBottom: "2.5px",
          display:{xs:"none",sm:"none",md:"none",lg:"flex"}
        }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disable}
      >
        <Box
          sx={{
            fontSize: "0.8rem",
            color: disable ? Colors.gray_500 : Colors.black,
            minHeight: 0,
            padding: "5px 5px",
          }}
        >
          Sort By
          <span style={{ fontSize: "0.8rem", paddingLeft: "6px" }}>
            {selectedRankSort}
          </span>
        </Box>
      </Button>
      <IconButton  onClick={handleClick} sx={{ display:{xs:"initial",sm:"initial",md:"initial",lg:"none"}}}><Sort/></IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            color: sort === "dateofpublication desc" ? Colors.primary : "",
            fontSize: "14px",
          }}
          onClick={() => handleSort("Publication Date")}
          disableRipple
        >
          Publication Date
        </MenuItem>
        <MenuItem
          onClick={() => handleSort("Best Match")}
          disableRipple
          sx={{
            color: sort === "score desc" ? Colors.primary : "",
            fontSize: "14px",
          }}
        >
          Best Match
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          disableRipple
          sx={{
            opacity: 0.6,
            ":hover": { background: "inherit" },
            cursor: "default",
          }}
        >
          Ranking
        </MenuItem>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          // value={publicationValue}
          value={sort}
          // onChange={handleChange}
          sx={{ padding: 1 }}
        >
          {valueExists("SJR", sortData) && (
            <Tooltip title="Scimago Journal Ranking">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: "14px" },
                }}
                value="sjr_score desc,dateofpublication desc"
                control={<Radio />}
                label="SJR"
                onChange={() =>
                  handleSort("sjr_score desc,dateofpublication desc")
                }
              />
            </Tooltip>
          )}
          {valueExists("H-Index", sortData) && (
            <Tooltip title="Hirsch index">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: "14px" },
                }}
                value="sjr_hindex desc,dateofpublication desc"
                control={<Radio />}
                label="H-Index"
                onChange={() =>
                  handleSort("sjr_hindex desc,dateofpublication desc")
                }
              />
            </Tooltip>
          )}
          {consortiamaster_id === 1874 && valueExists("NAAS", sortData) && (
            <Tooltip title="National Academy of Agricultural Sciences">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": { fontSize: "14px" },
                }}
                value="naas_value desc,dateofpublication desc"
                control={<Radio />}
                label="NAAS"
                onChange={() =>
                  handleSort("naas_value desc,dateofpublication desc")
                }
              />
            </Tooltip>
          )}
        </RadioGroup>
      </StyledMenu>
    </div>
  );
};

export default SortByRelevence;
