import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  get_emailnotificationfrequency,
  rssalertApi,
} from "../../api/rssalert";
import RSSAlert_Save from "./RSSAlert_Save";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { notify } from "../../utils/Notify";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { onSearch as search } from "../../screens/Search/onSearch";
import { alertTypeDetails } from "../../api/redux-thunks/alertTypeDetails";
import { usagelogApi } from "../../api/usageReportApi";
import http from "../../api/httpService";
import { useLocation } from "react-router-dom";
export interface State extends SnackbarOrigin {
  open: boolean;
}

// Default frequency
const DEFAULT_FREQUENCY = "1";

const RSSAlertSearch = ({
  openViewModal,
  handleCloseViewModal,
  id,
  resourceData,
  resourcetype,
  alertTypeSetting,
}: any) => {
  const [limit, setLimit] = useState<any>(DEFAULT_FREQUENCY);
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [rssChecked, setRssChecked] = useState<boolean>(false);
  const [intialAlert, setIntialAlert] = useState<boolean>(false);
  const [rssSavedSuccessfully, setrssSavedSuccessfully] = useState(false);
  const [url, setUrl] = useState("");
  const dispatch = useAppDispatch();
  const resourcemaster_id = resourceData?.resourcemaster_id;
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const [frequencyList, setFrequencyList] = useState<any>([]);

  const userId = sessionStorage.getItem("user_id");

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const getAlertData = useCallback(async () => {
    const params = {
      usermaster_id: userId,
      resourcemaster_ids: resourcemaster_id,
    };
    await http
      .get(
        "/api/check_resource_alert_setting_status/check_resource_alert_setting_status",
        { params }
      )
      .then(({ data }: any) => {
        console.log("abstractabstract", data);

        if (data.data[0][0][0].alert_type === 1) {
          setEmailChecked(true);
          setIntialAlert(false);
        }
        if (data.data[0][0][0].alert_type === 2) {
          setRssChecked(true);
          setIntialAlert(false);
        }
        if (data.data[0][0][0].alert_type === 3) {
          setEmailChecked(true);
          setRssChecked(true);
          setIntialAlert(false);
        }
        if (data.data[0][0][0].alert_type === 0) {
          setEmailChecked(false);
          setRssChecked(false);
          setIntialAlert(true);
        }
      });
  }, [resourcemaster_id, userId]);

  useEffect(() => {
    // setAlertTypeId();
    if (openViewModal) {
      getAlertData();
    }
  }, [openViewModal, getAlertData]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSavedResponse = () => {
    setrssSavedSuccessfully(!rssSavedSuccessfully);
  };

  const handleEmailCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailChecked(event.target.checked);
  };

  const handleRSSCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRssChecked(event.target.checked);
  };

  const getfrequencyList = async () => {
    const getListData: any = await get_emailnotificationfrequency();

    if (getListData) {
      const menulistdata: any = getListData?.map((item: any) => item);

      const frequencyListArray: any[] = [];
      menulistdata.forEach((element: any, index: number) => {
        let key = (index + 1).toString();
        frequencyListArray[index] = {
          label: element[key],
          value: key,
        };
      });
      setFrequencyList(frequencyListArray);
    }
  };

  // useEffect(() => {
  //   getfrequencyList();
  // }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      try {
        getfrequencyList();
      } catch (error) {
        console.log(error);
      }
    }, 100);

    return () => {
      clearTimeout(debounceTimeout); // Clear timeout if dependencies change
    };
  }, []);

  const handleSearchHistoryAlert = async (e: any) => {
    e.preventDefault();
    // if no alert is set initiall user should not setalert without checking the checkbox.
    let articleData: any[] = [];
    if (Array.isArray(alertTypeSetting)) {
      articleData = alertTypeSetting.filter(
        (obj) => obj?.resource_id === resourcemaster_id.toString()
      );
    }
    if (!emailChecked && !rssChecked && intialAlert) {
      notify("info", "Select any one alert type to set alert");
      return;
    }

    let alertId;
    if (rssChecked && emailChecked) {
      alertId = 3;
    } else if (emailChecked) {
      alertId = 1;
    } else if (rssChecked) {
      alertId = 2;
    }
    // resourceData?.resourcemaster_id == 8 ? logAlertUsageData(104, 105) : null;
    // resourceData?.resourcemaster_id == 24 ? logAlertUsageData(106, 107) : null;
    // resourceData?.resourcemaster_id == 4 ? logAlertUsageData(108, 109) : null;
    try {
      const userjson = {
        data: [
          {
            usermaster_id: userId,
            resourcemaster_id: resourceData?.resourcemaster_id,
            resourcetype_id: resourcetype.resource_type,
            alerttype_id: alertId,
          },
        ],
      };

      const alertquery: any = await rssalertApi({ userjson });

      if (alertquery.data.data[0][0].Message === "Limit exceed 100") {
        setOpen(true);
        setMessage(
          "Reached maximum alerts, Please delete the existing to continue"
        );
        setLimit("");
        setEmailChecked(false);
        setRssChecked(false);
      } else {
        const rss_URL = alertquery.data.data[0][0].rssurl;
        let successMsg = alertquery.data.data[0][0].status;
        if (successMsg) notify("success", successMsg);
        setUrl(rss_URL);
        handleCloseViewModal();
        setLimit("");
        setEmailChecked(false);
        setRssChecked(false);

        // update the alert setting in redux
        // dispatch(search());
        dispatch(alertTypeDetails(location));
        if (rss_URL) handleSavedResponse();
      }
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  return (
    <>
      <Dialog
        open={openViewModal}
        onClose={handleCloseViewModal}
        id={id}
        onClick={(e) => e.stopPropagation()}
      >
        {message && (
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="warning"
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
          </Stack>
        )}
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            textAlign="left"
            fontFamily={"Lora"}
            fontWeight={700}
            fontSize={{xs:19,md:25,lg:28}}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Create New Email/RSS Alert
          </Typography>

          <IconButton onClick={handleCloseViewModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <Typography
            fontSize={16}
            fontWeight={500}
            textAlign="left"
            color="#212121"
          >
            Email Alert Frequency
          </Typography>
          <Select
            onChange={handleChangeFrequency}
            sx={{ width: "300px" }}
            // displayEmpty
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={limit}
          >

            {Array.isArray(frequencyList) && frequencyList?.map((item: any, i: any) => {

              return (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              )
            })}
          </Select> */}

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailChecked}
                  onChange={handleEmailCheckboxChange}
                />
              }
              label="Email Alerts"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rssChecked}
                  onChange={handleRSSCheckboxChange}
                />
              }
              label="RSS Alerts"
            />
          </FormGroup>

          <DialogActions style={{ justifyContent: "flex-start" }}>
            <Button
              sx={{
                "&:hover": {
                  backgroundColor: "#7852FB",
                },

                textAlign: "left",
                backgroundColor: "#7852FB",
                color: " #FAF8F5",
                textTransform: "capitalize",
                borderRadius: "8px",
                fontSize: {xs:"14px",sm:"16px",md:"18px"},
                width: {xs:"90px",sm:"140px",md:"192px"},
                height: "51px",
              }}
              onClick={handleSearchHistoryAlert}
              // onClick={openViewModal}
            >
              Save
            </Button>

            <Button
              sx={{
                textAlign: "left",
                left: "42px",
                // fontWeight: 500,
                fontSize: {xs:"14px",sm:"16px",md:"18px"},
                backgroundColor: "#FFFFFF",
                color: "primary",
                textTransform: "capitalize",
                borderRadius: "8px",
                height: "51px",
              }}
              onClick={handleCloseViewModal}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <RSSAlert_Save
        urlLink={url}
        openViewModal={rssSavedSuccessfully}
        handleCloseViewModal={handleSavedResponse}
      />
    </>
  );
};

export default RSSAlertSearch;
