import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { hideModal } from "../../../store/slice/modalSlice";
import { useDispatch } from "react-redux";
import { searchHistoryStyles as sx } from "../../../screens/SearchHistory/SearchHistory.styles";

interface PropType {
  isOpen: boolean;
}

export default function SetSearchInfoModel(props: PropType) {
  const { isOpen } = props;
  const dispatch = useDispatch();
  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(hideModal())}
      //   maxWidth={false}
      fullWidth
      maxWidth="xl"
      PaperProps={{
        sx: {
          width: "95vw", // Forces full viewport width
          margin: 0, // Removes any margin
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: { xs: "100%", sm: "400px", md: "550px", lg: "900px" },
          //width: "400px",
        }}
      >
        <div>
          <Typography
            variant="h6"
            fontWeight={"bold"}
            // fontSize="30px"
            fontFamily="Lora"
            sx={{
              fontSize: {
                xs: "19px", // For extra small screens
                sm: "30px", // For small screens
                md: "31px", // For medium screens and above
                lg: "32px",
              },
            }}
          >
            {/* Personalise your search */}
            Examples for using set numbers in Query editor
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent sx={{ mt: "1px" }}>
        <Stack
          direction="column"
          alignItems="left"
          //   sx={{
          //     marginLeft: { xs: "10px", sm: "10px", md: "0px" },
          //     paddingTop: { xs: "0px", sm: "0px", md: "28px" },
          //     paddingRight: { xs: "15px", sm: "160px" },
          //     width: "100%",
          //   }}
        >
          {/* <Typography>
            Examples for using set numbers in Query editor
          </Typography> */}
          <Typography sx={sx.example}>#S2 AND #S3 OR #S4</Typography>
          <Typography sx={sx.example}>
            (#S2 AND #S3) OR (#S4 AND #S6)
          </Typography>
          <Typography sx={sx.example}>
            {" "}
            (#S1 OR #S4) AND "Digital Library"
          </Typography>
          <Typography sx={sx.example}>#S4 NOT clone</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
