import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useAppDispatch } from "../../store/hooks";
import { hideModal } from "../../store/slice/modalSlice";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { logout } from "../../store/slice/loginSlice";
import { userLogout } from "../../api/userLogoutApi";
import { clearCustomerDetails } from "../../store/slice/customerDetails";
interface PropType {
  open: boolean;
  navigate: any;
}
export default function SessionTimeoutModal(props: PropType) {
  const { open, navigate } = props;
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(30);

  useEffect(() => {
    new Notification("Your session is going to expire");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev && prev - 1);
      count === 0 && handleLogout();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleLogout = async () => {
    dispatch(hideModal());
    if (sessionStorage.getItem("isIPLogin") === "true") {
      navigate("/thankYou");
      return;
    }
    const querylogout: any = await userLogout();
    dispatch(logout(querylogout));
    dispatch(clearCustomerDetails());
    sessionStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <Dialog open={open}>
      {/* ------------------------Title --------------------------------------- */}

      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
          Your session is about to expire!
        </Typography>
      </DialogTitle>

      {/*----------------------------- Content------------------------------------- */}
      <DialogContent
        sx={{ width: { sx: "100%", sm: "100%", md: "450px", lg: "450px" } }}
      >
        <Typography fontSize={"14px"}>
          You will be logged out in {count} seconds. Do you want to stay signed
          in?
        </Typography>

        <Stack direction={{ xs: "column", sm: "row" }} gap={2} mt={1}>
          <Button variant="contained" onClick={() => dispatch(hideModal())}>
            Yes, Keep me signed in
          </Button>
          <Button variant="contained" onClick={handleLogout}>
            No, Sign me out
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
