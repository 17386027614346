import { SxProps } from "@mui/material/styles";

const search: SxProps = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  //alignItems: "flex-end",
  alignItems: {xs:"start", sm:"flex-end",md:"flex-end",lg:"flex-end",xl:"flex-end"},
  mb: 1,
};

const searchWrapper: SxProps = {
  //width: "50%",
  width: {xs:"100%", sm:"100%", md:"50%",lg:"50%",xl:"50%",},
  display: "flex",
  alignItems: "center",
  height: "84%",
  gap:{xs:1,sm:0}
};

const textfield: SxProps = {
  // width: "77%",
  width: "98%",
};

const searchButton: SxProps = {
  px: "3rem",
  py: "0.5rem",
  ml: 1,
  //height: "6vh",
  height: { xs: "50.5px", sm: "50px", md: "50px", lg: "50px" },

  width:{xs:"99%", sm:"auto"},
  marginLeft:{xs:"4.5px"},
  marginRight:{xs:"3px"},
  
};

const select: SxProps = {
  // width: "28vw",
  width:{xs:"100%", sm:"28vw"},
};

const listWrapper: SxProps = {
  width: "100%",
  p: {xs:0, sm:1,md:1,lg:1,xl:1,},
};

export const folderStyles = {
  search,
  searchWrapper,
  textfield,
  searchButton,
  select,
  listWrapper,
};
