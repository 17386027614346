import React from "react";
import { Colors } from "../../utils/constants";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const RedOpenLock: React.FC = ({
  setGridDisplay,
  GridDisplay,
  resource_id,
  setGridId,
  showGrid,
}: any) => {
  return (
    <div
      onMouseEnter={(e) => {
        e.stopPropagation();
        showGrid();
        setGridDisplay(true);
        setGridId(resource_id?.resourcemaster_id);
      }}
      onMouseLeave={(e) => {
        e.stopPropagation();
        setGridDisplay(false);
      }}
    >
      <LockOpenIcon
        style={{ color: Colors.red500 }}
        onClick={(e) => {
          e.stopPropagation();
          showGrid();
          setGridDisplay(!GridDisplay);
          setGridId(resource_id?.resourcemaster_id);
        }}
        sx={{ fontSize: { xs: "24px", md: "24px" } }}
      />
    </div>
  );
};

export default RedOpenLock;
