import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { Colors } from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  filterByCountry,
  filterByPublisher_Book,
  filterBYSource,
  filterBySubject,
  filterBySubject_Book,
} from "../../api/browseJournalSearch";
import {
  alertsAndFav,
  browseJournalSearchResult,
} from "../../store/slice/browseJournal";
import {
  resourceSettingAlert,
  searchBookCoreFacet,
  searchResourceCoreFacet,
} from "../../api/browseJournal";
import { useSelector } from "react-redux";
import { toTitleCase } from "../../utils/helper";
import useDebounce from "../../hooks/useDebounce";
import { queryConstructor } from "../../api/formQuery";
import { updateBrowserPage } from "../../store/slice/browseFilterSlice";
interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  modalName: string;
  getFilterdValues?: (e: string[]) => void;
  filterData?: any;
  applyFilter?: (e: string[]) => void;
  placeholderName?: string;
  appliedFilterValues?: string[];
  facetKey?: string;
  setFilters: any;
}

const ModalOneBook = (props: Proptype) => {
  const {
    isOpen,
    handleClose,
    modalName,
    getFilterdValues,
    filterData,
    applyFilter,
    placeholderName,
    appliedFilterValues,
    facetKey,
    setFilters,
  } = props;
  const dispatch = useAppDispatch();
  const [filteredValues, setFilteredvalues] = useState<string[]>(
    Array.isArray(appliedFilterValues) ? appliedFilterValues : []
  );
  const [toggleValue, setToggleValue] = useState("relevance");
  const [facetData, setFacetData] = useState(filterData);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm);
  const subscribedSubjects = useAppSelector(
    (state) => state.searchReducer.subjectType
  );
  let userId = sessionStorage.getItem("user_id");
  let session_key = sessionStorage.getItem("session_key");

  useEffect(() => {
    if (debouncedValue) {
      handleSearch();
    } else {
      setFacetData(filterData);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValues();
  }, [isOpen]);

  function setValues() {
    if (Array.isArray(appliedFilterValues))
      setFilteredvalues(appliedFilterValues);

    setFacetData(filterData);
  }

  const selectedValues = (data: any) => {
    if (filteredValues.length === 0) {
      setFilteredvalues([data]);
    } else if (filteredValues.includes(data)) {
      setFilteredvalues(() => filteredValues.filter((e) => e !== data));
    } else {
      setFilteredvalues((prev) => [...prev, data]);
    }
  };

  const handleSubmit = () => {
    dispatch(updateBrowserPage(1));
    getFilterdValues && getFilterdValues(filteredValues);
    applyFilter && applyFilter(filteredValues);
    setFilteredvalues([]);
    handleClose();
  };
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setToggleValue(value);
    if (value === "alphabetical") {
      handleSort();
    } else {
      setFacetData(filterData);
    }
  };

  function handleCancel() {
    setFilteredvalues([]);
    handleClose();
  }

  function clearAll() {
    getFilterdValues && getFilterdValues([]);
    setFilteredvalues([]);
  }

  useEffect(() => {
    if (toggleValue === "alphabetical") {
      handleSort();
    } else {
      setFacetData(filterData);
    }
  }, [toggleValue]);

  function handleSort() {
    let sortedData = [...facetData].sort((a: any, b: any) => {
      let valA = Object.keys(a)[0]?.toUpperCase();
      let valB = Object.keys(b)[0]?.toUpperCase();

      if (valA > valB) {
        return 1;
      }
      if (valA < valB) {
        return -1;
      }
      return 0;
    });
    setFacetData(sortedData);
  }

  // Handle search
  async function handleSearch() {
    if (!facetKey) return;
    try {
      let json_facet = {
        [facetKey]: {
          type: "terms",
          field: facetKey,
          limit: 100,
          sort: "index asc",
          prefix: searchTerm.toLocaleLowerCase(),
        },
      };
      let subject = {};
      if (Array.isArray(subscribedSubjects) && subscribedSubjects.length) {
        Object.assign(subject, { fq__subjects_id_l2: subscribedSubjects });
      }
      let queryObj = {
        page: 1,
        rows: 15,
        profileuserid: userId,
        sessionid: session_key,
        facet_fields: "publisher_name,subjects_name_l3,resource_name_initial",
        fq__active_flag: true,
        fq__resource_type: 12,
        json_facet: JSON.stringify(json_facet),
      };
      if (Object.keys(subject).includes("fq__subjects_id_l2")) {
        Object.assign(queryObj, subject);
      }
      let query = await queryConstructor(queryObj);
      let response = await searchBookCoreFacet("?" + query);

      let data = response?.data?.jsonfacets[facetKey].buckets;
      let formArray = [];
      for (let obj of data) {
        let newObj: any = {};
        newObj[obj?.val] = obj?.count;
        formArray.push(newObj);
      }
      setFacetData(formArray);
    } catch (e) {
      console.log(e);
    }
  }

  function popupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {modalName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      {popupTitle()}

      <DialogContent sx={{ width: { xs: "100%", sm: 500, md: 700 } }}>
        {/* Search Bar */}
        <TextField
          size="small"
          fullWidth
          placeholder={`Select ${placeholderName} you want to add to your filter`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2, marginBlock: 3 }}
        >
          <Stack direction={"row"} alignItems="center">
            <ToggleButtonGroup
              onChange={handleChange}
              color="primary"
              exclusive
              aria-label="Platform"
              size="small"
            >
              <ToggleButton
                sx={[
                  toggleValue === "relevance"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    ":hover": {
                      background:
                        toggleValue === "relevance" ? Colors.primary : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
                value={"relevance"}
              >
                {/* Relevance  */}
                Document Count
              </ToggleButton>
              <ToggleButton
                value="alphabetical"
                sx={[
                  toggleValue === "alphabetical"
                    ? styles.checkedStyle
                    : styles.unCheckedStyle,
                  {
                    ":hover": {
                      background:
                        toggleValue === "alphabetical"
                          ? Colors.primary
                          : "#fff",
                      opacity: 0.85,
                    },
                  },
                ]}
              >
                Alphabetical
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Button onClick={clearAll}>
            Clear All <CloseIcon fontSize="small" />
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ gap: 2, overflowY: "auto" }}
          flexWrap="wrap"
          maxHeight={"60vh"}
        >
          {facetData?.map((data: any) => {
            return (
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ gap: 2 }}
                width={{xs:"250px",md:"400px"}}
              >
                <Stack direction={"row"} alignItems="center">
                  <Checkbox
                    onClick={() => selectedValues(Object.keys(data)[0])}
                    checked={filteredValues?.includes(Object.keys(data)[0])}
                  />
                  <Tooltip title={toTitleCase(Object.keys(data)[0])}>
                    <Typography
                      sx={{
                        width: {xs:"150px",md:"250px"},
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {toTitleCase(Object.keys(data)[0])}
                    </Typography>
                  </Tooltip>
                </Stack>
                {/* @ts-ignore */}
                <Typography>({Object.values(data)[0]})</Typography>
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{
          gap: 2,
          marginBlock: 2,
          position: "sticky",
          padding: "16px 24px",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ minWidth: { xs: 100, sm: 150 }  }}
        >
          Apply
        </Button>
        <Button
          onClick={handleCancel}
          sx={{ minWidth: { xs: 100, sm: 150 } , coloFr: "#111928" }}
        >
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ModalOneBook;

const styles = {
  checkedStyle: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    minWidth: "100px",
    borderRadopis: "6px",
  },
  unCheckedStyle: {
    minWidth: "100px",
    color: "#7852FB",
    backgroundColor: "#FFFFFF",
    borderRadopis: "6px",
  },
};
