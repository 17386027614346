import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Button,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Stack } from "@mui/system";
import Radio from "@mui/material/Radio";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  addArticleToFolder,
  getMyFolders,
  createFolderAndSave,
  isArticleExist,
  removeArticleFromFolder,
  checkMultipleArticleExistence,
} from "../../api/MyLibrary/MyFolders.api";
import { useQuery } from "@tanstack/react-query";
import { GetMyFoldersData } from "../../types/GetMyFolders.types";
import { notify } from "../../utils/Notify";
import { onSearch as search } from "../../screens/Search/onSearch";
import { useLocation } from "react-router-dom";
import { usagelogApi } from "../../api/usageReportApi";
import { onAuthorResult } from "../../screens/AuthorFinder/onAuthorResult";
import { SaverArticlesType } from "../../types/interface";
import { updateSavedArticles } from "../../store/slice/searchSlice";
//Types
interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  articleId: string[] | string;
  handleAlert?: Function;
  setJournalResults?: Function;
  from?: "markedResult";
  articleData?: any;
  resultFrom?: any;
}

type Option = "create" | "select";

export default function AddToFolder(props: Proptype) {
  const {
    isOpen,
    handleClose,
    articleId,
    handleAlert,
    setJournalResults,
    from,
    articleData,
    resultFrom,
  } = props;
  let userId = useAppSelector((state) => state.login.user_id);
  const location = useLocation();

  const [selectedOption, setSeletedOption] = useState<Option>("create");
  const [folderList, setFolderList] = useState<GetMyFoldersData[]>([]);
  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [folderId, setFolderId] = useState<number>(0);
  const [folderName, setFolderName] = useState("");
  const [isExist, setIsExist] = useState(false);
  const [isCancelled, setCancelled] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  //Get Folders
  useQuery({
    queryKey: ["GetMyFolders"],
    queryFn: () => getMyFolders(userId),
    select: ({ data }): GetMyFoldersData[] => data.data[0][0],
    onSuccess(myFolders) {
      setIsExist(false);
      setCancelled(Array.isArray(articleId));
      setFolderList(myFolders);
    },
    enabled: isOpen,
  });

  function handleChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (target.value === "create" || target.value === "select")
      setSeletedOption(target.value);
  }

  async function handleFolderChange(name: string, id: number) {
    setSelectedFolderName(name);
    setFolderId(id);
  }
  const isAuthorSearch = useAppSelector(
    (state: any) => state?.authorReducer?.isAuthorResult
  );
  const isBrowseJournalSearch = useAppSelector(
    (state: any) => state?.browseJournalSearch?.isBrowseJournalSearch
  );

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state?.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state?.browseFilterSlice
  );

  const is_ONOSCustomer = useAppSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder?.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch?.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logAddToFolderUsageData = (usageActionID: any, details?: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const isArticleONOS = details?.is_onos_resource;
    const isONOS =
      is_ONOSCustomer &&
      (selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue ===
        "ONOSCollections" ||
        selectedCollectionFromBrowseJournalSearch.collectionsValueJournal ===
          "ONOS Collections" ||
        isArticleONOS)
        ? 1
        : 0;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };

  // Save article to selected folder
  async function saveToFolder() {
    const formData = new FormData();
    let id = articleId.toString();
    let folderIds = folderId.toString();

    if (!id && !folderIds) return;

    if (!isCancelled) {
      let isExist = await isArticleSaved();
      if (isExist) {
        setIsExist(true);
        if (!isCancelled) return;
      } else {
        setIsExist(false);
      }
    }

    formData.append("articleId", id);
    formData.append("folderid", folderIds);

    const response = await addArticleToFolder(formData);
    if (location?.pathname === "/authorfindersearch" || isAuthorSearch) {
      dispatch(onAuthorResult());
    }
    if (
      location?.pathname !== "/BrowseJournalTableSearch/BrowseJournalSearch" &&
      location.pathname !== "/authorfindersearch" &&
      !isAuthorSearch &&
      !isBrowseJournalSearch
    ) {
      dispatch(search());
    }
    if (
      location?.pathname == "/BrowseJournalTableSearch/BrowseJournalSearch" ||
      isBrowseJournalSearch
    ) {
      setJournalResults && setJournalResults();
      checkArticleExistanceInFolder(id);
    }

    notify(
      "success",
      from === "markedResult"
        ? "Folder content updated successfully"
        : response?.Message
    );

    handleAlert && handleAlert(true, response?.Message);
    handleClose();
    from === "markedResult" && dispatch(search());
    logAddToFolderUsageData(110, articleData);
  }

  async function checkArticleExistanceInFolder(id: string) {
    const articleData = new FormData();

    const articleIds = id;

    articleData.append("profile_id", userId);
    articleData.append("article_ids", articleIds.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateSavedArticles(filter_is_saved));
  }

  async function createFolder() {
    // usagelogApi(
    //   profileData.user_id,
    //   profileData.informaticscustomer_id,
    //   profileData1?.consortiamaster_id,
    //   110,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   user_ipv4_address,
    //   null,
    //   profileData.session_key,
    //   1,
    //   null
    // );

    logAddToFolderUsageData(110);

    const formData = new FormData();
    let id = articleId.toString();

    if (!id) return;
    if (!folderName) {
      setError("Folder Name cannot be empty!");
      return;
    }

    if (folderName?.length > 20) {
      setError("Folder Name should be less than 20 character");
      return;
    }

    formData.append("user_master_id", userId);
    formData.append("folder_name", folderName);
    formData.append("articleId", id);

    const response = await createFolderAndSave(formData);
    notify("success", response?.Message);

    if (location?.pathname === "/authorfindersearch" || isAuthorSearch) {
      dispatch(onAuthorResult());
    }
    if (
      location?.pathname !== "/BrowseJournalTableSearch/BrowseJournalSearch" &&
      location.pathname !== "/authorfindersearch" &&
      !isAuthorSearch &&
      !isBrowseJournalSearch
    ) {
      dispatch(search());
    }
    if (
      location?.pathname == "/BrowseJournalTableSearch/BrowseJournalSearch" ||
      isBrowseJournalSearch
    ) {
      setJournalResults && setJournalResults();
      checkArticleExistanceInFolder(id);
    }
    if (response?.message === "Not Ok") {
      notify("error", response?.data[0][0].Message);
    } else {
      // notify("success", response?.Message);
    }

    dispatch(search());

    handleAlert && handleAlert(true, response?.Message);
    handleClose();
  }

  async function isArticleSaved(): Promise<boolean> {
    if (!articleId) return false;
    if (Array.isArray(articleId)) return false;

    let formData = new FormData();
    formData.append("profile_id", userId);
    formData.append("article_ids", articleId);

    const data = await isArticleExist(formData);
    if (!data?.is_saved) return false;
    return data?.is_saved;
  }

  async function removeArticle() {
    console.log("ergwsdfz", location?.pathname);

    let formData = new FormData();
    if (!folderId) return;
    if (Array.isArray(articleId)) return;

    formData.append("folderid", folderId.toString());
    formData.append("articleId", articleId);

    const data = await removeArticleFromFolder(formData);
    if (data?.message === "ok") {
      handleClose();
    }
    console.log("ergwsdfz", data, location?.pathname);
    if (location?.pathname == "/basicSearchScreen") {
      dispatch(search());
    }
    setIsExist(false);
    setCancelled(true);
  }

  function Title(props: any) {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minWidth: { xs: "100%", sm: "400px", md: "550px" },
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {props?.title}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.handleCloseIconClick()}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  function closeModal() {
    setIsExist(false);
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
        {/* Title */}
        <Box sx={{ opacity: isExist ? 0 : 1 }}>
          <Title handleCloseIconClick={handleClose} title="Add To Folder" />
          <DialogContent>
            <Box sx={{ my: 2 }}>
              <RadioGroup
                value={selectedOption}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <FormControlLabel
                  value="create"
                  control={<Radio />}
                  label="Create Folder"
                />
                <FormControlLabel
                  value="select"
                  control={<Radio />}
                  label="Select Folder"
                />
              </RadioGroup>
            </Box>
            <Box sx={{ my: 2 }}>
              {selectedOption === "create" ? (
                <>
                  <Typography>Folder Name</Typography>
                  <TextField
                    fullWidth
                    onChange={(e) => {
                      setFolderName(e.target.value);
                      if (!e.target.value) {
                        setError("Folder Name cannot be empty!");
                      } else {
                        setError("");
                      }
                    }}
                  />
                  {error ? (
                    <Typography
                      sx={{ color: "#F05252", position: "absolute" }}
                      fontSize="12px"
                    >
                      {error}
                    </Typography>
                  ) : null}
                </>
              ) : (
                <>
                  {/* <Typography>Select Folder</Typography> */}
                  <FormControl fullWidth>
                    <InputLabel
                      sx={{ backgroundColor: "white", padding: "0 5px" }}
                      id="demo-select-small-label"
                    >
                      Select Folder
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      fullWidth
                      value={selectedFolderName}
                    >
                      {folderList?.map((data) => (
                        <MenuItem
                          key={data?.userfolder_id}
                          value={data?.folder_name}
                          onClick={() =>
                            handleFolderChange(
                              data?.folder_name,
                              data?.userfolder_id
                            )
                          }
                        >
                          {data.folder_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            <Box sx={{ my: 2 }}>
              <Stack direction={"row"} gap={1}>
                {selectedOption === "create" ? (
                  <Button
                    variant="contained"
                    sx={{ minWidth: 100 }}
                    onClick={createFolder}
                  >
                    Create
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ minWidth: 100 }}
                    onClick={saveToFolder}
                    disabled={!selectedFolderName ? true : false}
                  >
                    Add
                  </Button>
                )}
                <Button
                  sx={{ minWidth: 150, color: "#111928" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      <Dialog open={isExist} onClose={closeModal} maxWidth={false}>
        <Title handleCloseIconClick={closeModal} title="Remove From Folder" />

        <DialogContent sx={{ mt: "30px" }}>
          <Typography>
            Document already added to folder, Do you want to remove it from the
            folder?
          </Typography>

          <Stack direction={"row"} gap={1} mt={"20px"}>
            <Button
              variant="contained"
              sx={{ minWidth: 100 }}
              onClick={removeArticle}
            >
              Remove
            </Button>
            <Button
              sx={{ minWidth: 150, color: "#111928" }}
              onClick={() => {
                setCancelled(true);
                setIsExist(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
