import { Box, Divider, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../components/Nav/style.css";
import AboutUsDetails from "../../components/AboutUsDetails";
import ContactUs from "../../components/ContactUs";
import FAQ from "../../components/FAQ";
import Navbar from "../../components/Nav/Navbar";
import { useParams } from "react-router-dom";
import FeedBcakForm from "../../components/FeedBcakForm";
import FAQDetails from "../../components/FAQDetails";

const AboutUs = () => {
  const { id } = useParams();
  const [tabIndex, setTabIndex] = useState(id);
  const pathSegments = window.location.pathname.split("/");
  const pathSegments_Id = pathSegments[2];

  useEffect(() => {
    if (pathSegments_Id === "1") {
      setTabIndex("1");
    } else if (pathSegments_Id === "2") {
      setTabIndex("2");
    } else if (pathSegments_Id === "3") {
      setTabIndex("3");
    } else {
      setTabIndex("4");
    }
  }, [pathSegments_Id]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue);
  };

  return (
    <>
      {/* <Navbar /> */}

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderColor: "divider",
          //height: "100vh",
        }}
      >
        <Tabs
          onChange={handleChange}
          value={tabIndex}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ margin: "15px",marginBottom:0 }}
        >
          <Tab sx={TabsStyle.tabs} label="About Us" value="1" />
          <Tab sx={TabsStyle.tabs} label="Contact Us" value="2" />
          <Tab sx={TabsStyle.tabs} label="FAQ’s" value="3" />
          <Tab sx={TabsStyle.tabs} label="Feedback" value="4" />
        </Tabs>
        <Divider />

      <div style={{margin:"5px"}}>
        {tabIndex === "1" && <AboutUsDetails />}
        {tabIndex === "2" && <ContactUs />}
        {/* {tabIndex === "3" && <FAQ setTabIndex={setTabIndex} />} */}
        {tabIndex === "3" && <FAQDetails setTabIndex={setTabIndex} />}
        {tabIndex === "4" && <FeedBcakForm />}
      </div>
      </Box>
    </>
  );
};

export default AboutUs;

const TabsStyle = {
  tabs: {
    fontSize: { sx: "10px", md: "19px" },
    fontWeight: 500,
  },
};
