import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./style.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Text from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

// Assets
import InformaticsLogo from "../../assets/img/informatics.png";
import informatics_logo from "../../assets/img/informaticslogo.png";
import { margin, Stack } from "@mui/system";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AppleIcon from "@mui/icons-material/Apple";
import YoutubeIcon from "../../assets/img/youtube.png";
import dayjs from "dayjs";
import copyrightIcon from "../../assets/img/copyright.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Link } from "@mui/material";
import api from "../../api/endpoints";
import playStore from "../../assets/images/playstore-svgrepo-com.svg";
import styled from "@emotion/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { setModal } from "../../store/slice/modalSlice";
import twitter from "../../assets/img/twitter.png";

const Footer = () => {
  const [isBetaFeedback, setIsBetaFeedback] = useState(false);
  const navigate = useNavigate();
  let logo = useAppSelector((state) => state.customer.customerDetails);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (api.baseApi !== "https://diskoverapi.jgatenext.com") {
      setIsBetaFeedback(true);
    } else {
      setIsBetaFeedback(false);
    }
  }, [isBetaFeedback]);

  const CustomTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} followCursor />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#FFF", // Set your desired color here
      backgroundColor: "black",
    },
  }));

  function openLoginInfoModal() {
    dispatch(
      setModal({
        modalType: "BROWSER_COMPATIBILITY",
        modalProps: {
          isOpen: true,
        },
      })
    );
  }
  return (
    <div>
      <Stack
        alignItems={"center"}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        className="footer"
        gap={1}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={ "center"}
          // paddingY={"4px"}
          // spacing={1}
        >
          <a href="https://informaticsglobal.ai/" target="_blank">
            <img
              src={InformaticsLogo}
              className="footer-image"
              alt="J-Gate is an electronic gateway to global e-journal literature"
            />
          </a>
          <Stack
            direction={"row"}
            alignItems="center"
            // justifyContent={"space-between"}
            // spacing={{xs:2,sm:1,md:2}}
          >
            <Stack  direction={{xs:"row",md:"column",lg:"row"}}
            alignItems="center"
            justifyContent={"space-between"}
            spacing={{ xs: 2, sm: 1, md: 2 }}
            marginLeft={2}

            >
            <Text
              onClick={() => navigate("/aboutus/1")}
              sx={{ color: "#FFF", cursor: "pointer" ,fontSize:{sm:"14px",md:"medium"}}}
            >
              About
            </Text>
           
              <Divider
                orientation="vertical"
                flexItem
                sx={{ color: "#FFF", background: "#FFF", height: 20,display: { xs: "none", lg: "block" } }}
              />
          
            <Text
              onClick={() => navigate("/aboutus/2")}
              sx={{ color: "#FFF", cursor: "pointer" ,fontSize:{sm:"14px",md:"medium"}}}
            >
              Contact Us
            </Text>
         
              <Divider
                orientation="vertical"
                flexItem
                sx={{ color: "#FFF", background: "#FFF", height: 20 ,display: { xs: "none", lg: "block" } }}
              />
         

            </Stack>
            <Stack direction={{xs:"row",md:"column",lg:"row"}}
            alignItems="center"
            marginLeft={2}
            justifyContent={"space-between"}
            spacing={{ xs: 2, sm: 1, md: 2 }}>
            <Text
              onClick={() => navigate("/aboutus/3")}
              sx={{ color: "#FFF", cursor: "pointer",fontSize:{sm:"14px",md:"medium"} }}
            >
              FAQ
            </Text>
         
              <Divider
                orientation="vertical"
                flexItem
                sx={{ color: "#FFF", background: "#FFF", height: 20,display: { xs: "none", lg: "block" }  }}
              />
         
            {/* {isBetaFeedback ? (
            <Text
              // onClick={() => navigate("http://tinyurl.com/jgate3beta")}
              sx={{ color: "#FFF", cursor: "pointer" }}
            >
              <Link
                target="_blank"
                href=" http://tinyurl.com/jgate3beta"
                sx={{ color: "orange", cursor: "pointer" }}
                underline="none"
              >
                Beta-Feedback
              </Link>
            </Text>
          ) : (
            <Text
              // onClick={() => navigate("http://tinyurl.com/jgate3beta")}
              sx={{ color: "#FFF", cursor: "pointer" }}
            >
              <Link
                target="_blank"
                // href=" http://tinyurl.com/jgate3beta"
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=M_4_nylwpkuTMKIkglk7dh9jpA7zC89LgjVE64YxnDtUOEFNTEM4WkZTNVJSQVI1SktIQlFXNDg5RS4u"
                sx={{ color: "#FFF", cursor: "pointer" }}
                underline="none"
              >
                Feedback
              </Link>
            </Text>
          )} */}

            <Text
              // onClick={() => navigate("http://tinyurl.com/jgate3beta")}
              sx={{ color: "#FFF", cursor: "pointer" }}
            >
              <Link
                target="_blank"
                // href=" http://tinyurl.com/jgate3beta"
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=M_4_nylwpkuTMKIkglk7dh9jpA7zC89LgjVE64YxnDtUOEFNTEM4WkZTNVJSQVI1SktIQlFXNDg5RS4u"
                sx={{ color: "#FFF", cursor: "pointer" ,fontSize:{sm:"14px",md:"medium"}}}
                underline="none"
              >
                Feedback
              </Link>
            </Text>
         
              <Divider
                orientation="vertical"
                flexItem
                sx={{ color: "#FFF", background: "#FFF", height: 20 ,display: { xs: "none", lg: "block" } }}
              />
         
         
        </Stack>
            <Stack  direction={{md:"column",lg:"row"}}
         alignItems="center"
         marginLeft={2}

            justifyContent={"space-between"}
            spacing={{ xs: 2, sm: 1, md: 2 }}>   
              <Text sx={{ cursor: "pointer" ,display: { xs: "none", md: "block" } }}>
                <Link
                  target="_blank"
                  href="https://admin.jgatenext.com"
                  style={{ cursor: "pointer", color: "#FFF" }}
                  underline="none"
                >
                  Admin
                </Link>
              </Text>
        
              </Stack>
          {logo?.logo !== null &&
          logo?.logo !== "\\x" &&
          logo?.banner !== "\\x" &&
          logo?.banner !== null &&
          logo?.banner &&
          logo?.logo? (
            <Stack  display={{ xs: "none", md: "block" }} >
              {/* <img src={logo?.logo} width={200} height={45}></img> */}
              <img
                src={logo?.logo}
                alt="logo"
                style={{
                  width: "80px",
                  marginLeft:"5px",
                  // height: "70px",
                  // height: "auto",
                  height: "50px",
                  objectFit: "contain",
                }}
              ></img>
            </Stack>
          ) : null}
        </Stack>
         
        </Stack>
        <Stack  alignItems={{xs: "flex-start", sm: "center" }}>
        <Stack
        // marginLeft={"-130px"}
        display={ { xs: "none", md: "flex" } }
        direction={{ xs: "column", sm: "row"}}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
      >
        <Text
          sx={{
            color: "#FFF",
            display: "flex",
            alignItems: "center",
            gap: "3px",
            fontSize: "12px",
          }}
        >
          {/* Copyright  */}
          <img src={copyrightIcon} alt="jgate copyright" />{" "}
          {dayjs().year()}. Informatics India Ltd. All Rights Reserved.
        </Text>
      </Stack>
          {/* <Stack direction={"row"} alignItems="center" 
        // marginLeft={"-110px"}
        > */}
        <Stack direction={{ xs: "row", sm: "row", }}
        >
        {logo?.logo !== null &&
          logo?.logo !== "\\x" &&
          logo?.banner !== "\\x" &&
          logo?.banner !== null &&
          logo?.banner &&
          logo?.logo ? (
            <Stack alignItems={"start"} display={{ xs: "flex", md: "none" }}>
              {/* <img src={logo?.logo} width={200} height={45}></img> */}
              <img
                src={logo?.logo}
                alt="logo"
                style={{
                  width: "40px",
                  // height: "70px",
                  // height: "auto",
                  height: "50px",
                  objectFit: "contain",
                }}
              ></img>
            </Stack>
          ) : null}
        <Stack display={"flex"}>
          <Stack
            direction={{ xs: "column", sm: "row", }}
            alignItems={{ xs: "flex-start", md: "center" }}
          >
              <Stack
                sx={{
                  color: "#FFF",
                  display: { xs: "none", md: "flex" } ,
                  alignItems: "center",
                  gap: "3px",
                  fontSize:{md: "10px",lg:"12px"},
                  cursor: "pointer",
                }}
                onClick={openLoginInfoModal}
              >
                Browser compatibility
              </Stack>
            <Stack direction={"row"} spacing={1}>
              <IconButton
                href="https://www.facebook.com/jgateplus/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookRoundedIcon sx={{ color: "#FFF" }} />
              </IconButton>

              {/* <IconButton
            href="https://twitter.com/jgateplus"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon sx={{ color: "#FFF" }} />
          </IconButton> */}
              <Stack>
                <a href="https://x.com" target="_blank" rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    width="20"
                    height="19"
                    style={{ marginTop: "11px" }}
                  >
                    <path
                      d="M 18.244 2.25 h 3.308 l -7.227 8.26 l 8.502 11.24 H 16.17 l -5.214 -6.817 L 4.99 21.75 H 1.68 l 7.73 -8.835 L 1.254 2.25 H 8.08 l 4.713 6.231 Z"
                      stroke="#ffffff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </Stack>

              <IconButton
                style={{marginLeft:"3px"}}
                href="https://www.linkedin.com/company-beta/13306692/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon sx={{ color: "#FFF" }} />
              </IconButton>
              <CustomTooltip style={{marginLeft:"-5px"}} title="Download the app">
                <IconButton
                  href="https://apps.apple.com/us/app/j-gate/id1670549103"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppleIcon sx={{ color: "#FFF" }} />
                </IconButton>
              </CustomTooltip>
              <Stack>
                <CustomTooltip title="Download the app">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.informatics.jgate"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={playStore}
                      alt="img"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    />
                  </a>
                </CustomTooltip>
              </Stack>
            </Stack>
          </Stack>
      
        </Stack>

        </Stack>
       <Stack
        display={ { xs: "flex", md: "none" } }
            // marginLeft={"-130px"}
            // direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
            // alignItems={{
            //   xs: "flex-start",
            //   sm: "center",
            //   md: "center",
            //   lg: "center",
            // }}
          >
            <Text
              sx={{
                color: "#FFF",
                display: "flex",
                alignItems: "center",
                gap: "3px",
                // marginLeft:"5%",
                justifyContent:"center",
                fontSize: "12px",
              }}
            >
              {/* Copyright */}
               <img src={copyrightIcon} alt="jgate copyright" />{" "}
              {dayjs().year()}. Informatics India Ltd. All Rights Reserved.
            </Text>
          </Stack>
        </Stack>

        {/* <Stack>
        <Stack
          marginLeft={"-120px"}
          direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
          alignItems={{
            xs: "flex-start",
            sm: "center",
            md: "center",
            lg: "center",
          }}
        >
          <Text
            sx={{
              color: "#FFF",
              display: "flex",
              alignItems: "center",
              gap: "3px",
              fontSize: "12px",
            }}
          >
            Download the app
          </Text>
        </Stack>
        <Stack direction={"row"} alignItems="center" marginLeft={"-100px"}>
          <IconButton
            href="https://apps.apple.com/us/app/j-gate/id1670549103"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AppleIcon sx={{ color: "#FFF" }} />
          </IconButton>
          <Stack>
            <a
              href="https://play.google.com/store/apps/details?id=com.informatics.jgate"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={playStore}
                alt="img"
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              />
            </a>
          </Stack>
        </Stack>
      </Stack> */}

        {/* social media links */}
        <Stack direction={"row"} width={150} alignItems="center">
          {/* <IconButton
          href="https://www.facebook.com/jgateplus/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookRoundedIcon sx={{ color: "#FFF" }} />
        </IconButton> */}

          {/* <IconButton
          href="https://twitter.com/jgateplus"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon sx={{ color: "#FFF" }} />
        </IconButton>

        <IconButton
          href="https://www.linkedin.com/company-beta/13306692/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon sx={{ color: "#FFF" }} />
        </IconButton> */}
        </Stack>

        {/* {logo?.logo !== null &&
      logo?.logo !== "\\x" &&
      logo?.banner !== "\\x" &&
      logo?.banner !== null &&
      logo?.banner &&
      logo?.logo ? (
        <Stack>
          <img src={logo?.logo} width={200} height={45}></img>
        </Stack>
      ) : null} */}
      </Stack>
    </div>
  );
};

export default Footer;
// 2022 . Informatics India Ltd. All Rights Reserved. Copyright
