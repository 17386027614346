import Text from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { alertStyles as styles } from "./Alerts.style";
import { Add } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setModal } from "../../../store/slice/modalSlice";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import {
  GetSubjectAlertData,
  GetUserL2L3Subject3Detail,
} from "../../../types/GetUserL2L3Subjects";
import {
  setAVAlert,
  useGetAVSubjects,
  useMutateDeleteAVSubjects,
} from "../../../api/MyLibrary/AV.api";
import { Button, Tooltip } from "@mui/material";
import { IconButton, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Colors } from "../../../utils/constants";
import MoreIcon from "@mui/icons-material/MoreVert";
import RSSIcon from "@mui/icons-material/RssFeed";
import RSSAlertModal from "./RssAlert.modal";
import ThesisMenu from "./ThesisMenu";
import AudioVideoMenu from "./AudioVideoMenu";
import AlertModal from "./AlertModal";

const AudioVideo = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);
  const deleteAVHandler = useMutateDeleteAVSubjects();

  const [selectedL3, setSelectedL3] = useState<GetUserL2L3Subject3Detail[]>([]);

  const { data: avSubjects, refetch } = useGetAVSubjects(selector.user_id);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, updateSelectedRow] = useState();
  const [openRss, setOpenRss] = useState(false);
  const [rssData, setRssData] = useState();
  const [subjectId, setSubjectId] = useState("");
  const [resourceId, setResourceId] = useState("8");
  const [currentAlert, setCurrentAlert] = useState<GetSubjectAlertData | null>(
    null
  );
  const [alertId, setAlertId] = useState("");
  const [tempData, setTempData] = useState("");
  const [alertTopic, setAlertTopic] = useState("");
  const [freqData, setFreqData] = useState("");

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (avSubjects) {
      let selected: GetUserL2L3Subject3Detail[] = [];

      avSubjects.forEach((l2) =>
        l2.subject3_details.forEach((l3) => {
          if (l3.is_favorite) selected.push(l3);
        })
      );

      setSelectedL3(() => selected);
    }
  }, [avSubjects]);

  function closeRssModal() {
    setOpenRss(false);
  }

  function openMenu(event: React.MouseEvent<HTMLElement>, row: any) {
    setAnchorEl(event.currentTarget);
    updateSelectedRow(row);
  }

  const closeMenu = () => {
    setAnchorEl(null);
  };

  function openRssMenu(row: any) {
    setOpenRss(true);
    setRssData(row);
    setSubjectId(row?.subject_id3);
    setCurrentAlert(row);
    setTempData(row.alert_type);
    setAlertTopic(row.alert_topic);
    setFreqData(row.frequency);
  }

  const handleAddSubjectsModal = () =>
    dispatch(
      setModal({
        modalType: "ADD_AV_THESIS",
        modalProps: {
          show: true,
          title: "Subjects",
          type: "av",
          l2Subjects: avSubjects,
          selectedL3: selectedL3,
        },
      })
    );

  return (
    <>
      <Button
        variant="outlined"
        sx={styles.addButton}
        onClick={handleAddSubjectsModal}
      >
        Add Subjects
      </Button>

      <TableContainer component={Paper} sx={{ marginTop: "2%" }}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>Title</TableCell>
              <TableCell sx={{ textAlign: "left" }}>Subject Name</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedL3?.map((subject: any, index) => {
              return (
                <TableRow
                  key={subject.subject_id3}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Text fontSize="0.875rem">{index + 1}</Text>
                    {/* {subject.subject_id3} */}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ textAlign: "left" }}
                  >
                    <Text fontSize="0.875rem">{subject.subject_name3}</Text>
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    sx={styles.addManageCell}
                  >
                    <Text fontSize="0.875rem">&nbsp;</Text>
                    <Box sx={{display:"flex"}}>
                      <Tooltip
                        title={
                          subject?.alert_type > 0 ? "Modify Alert" : "Set Alert"
                        }
                      >
                        <IconButton
                          aria-label="rss"
                          sx={{
                            "&:hover": {
                              color: "#F48120",
                            },
                            color: subject?.alert_type > 0 ? "#F48120" : "",
                          }}
                          onClick={() => openRssMenu(subject)}
                        >
                          <RSSIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton onClick={(e) => openMenu(e, subject)}>
                        <MoreIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <AudioVideoMenu
          open={open}
          onClose={closeMenu}
          anchor={anchorEl}
          currentAlert={selectedRow}
          // refetch={refetch}
        />
        {currentAlert && (
          <AlertModal
            show={openRss}
            onClose={closeRssModal}
            alertData={rssData}
            subjectID={subjectId}
            resourceId={resourceId}
            currentAlert={currentAlert}
            refetch={refetch}
            alertValue={tempData}
            alertTopicData={alertTopic}
            frequencyData={freqData}
          />
        )}
      </TableContainer>
    </>
  );
};

export default AudioVideo;
