import http from "../httpService";
import {
  GetAutoCompleteRoot,
  GetAutoCompleteKeyword,
} from "../../types/GetAutocomplete.types";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useAppSelector } from "../../store/hooks";
import { temporaryQueryConstructor } from "../../screens/AdvancedSearch/TemporaryQueryConstructor";
import { useRef } from "react";

export const useGetAutoComplete = (query: string) => {
  const l1SubjectIds = useAppSelector(
    (state) => state.searchReducer.subjectL1Type
  );
  const uniqueIds = [...new Set(l1SubjectIds || [])];
  const encodedSubjectIds = temporaryQueryConstructor(uniqueIds);

  const abortControllerRef = useRef<AbortController | null>(null);

  if (abortControllerRef?.current) {
    abortControllerRef.current.abort();
  }

  const abortController = new AbortController();
  abortControllerRef.current = abortController;

  return useQuery(
    ["GetAutoComplete", query],
    () =>
      http.get(
        // "/api/indexingGen/solrGeneral/autofillcore?page=1&rows=1&facet_limit=25&facet_fields=keyword&datsubjectmasterlevel1_ids=(5)",
        `/api/indexingGen/solrGeneral/autofillcore?page=1&rows=1&facet_limit=25&facet_fields=keyword&datsubjectmasterlevel1_ids=(${encodedSubjectIds})`,
        {
          params: {
            facet_prefix: query,
          },
          signal: abortController.signal,
        }
      ),
    {
      select: (
        res: AxiosResponse<GetAutoCompleteRoot>
      ): GetAutoCompleteKeyword[] => {
        return res.data.data.facets.facet_fields.keyword;
      },
      enabled: query.length >= 3, //Wont work for <2 chars
    }
  );
};
