import React, { useEffect, useRef, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Colors } from "../../utils/constants";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  clearDataTypeFilters,
  clearSearchTerm,
  removeSearchTerm,
  sliceSearchTerm,
  updateAll,
  updateDataType,
  updateFullText,
  updateIsNavigatingBack,
  updateIsSettled,
  updateLogSearch,
  updateMarkedArticleCount,
  updateNewSearchStatus,
  updatePage,
  updateRow,
  updateSearchTerm,
  updateSubjectType,
} from "../../store/slice/searchSlice";
import { onSearch as search } from "../../screens/Search/onSearch";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  removeAllFilterType,
  spliceAllFilterArray,
  updateAllFilter,
  updateAuthor,
  updateConfrence,
  updateCountryOfPublication,
  updateCountryOfPublishingAuthor,
  updateDateFrom,
  updateDateset,
  updateEarlyOnline,
  updateInstitution,
  updateJournal,
  updateJournalRank,
  updateLoadingState,
  updateMonthFrom,
  updateOpenAccess,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateRetractedArticle,
  updateSort,
  updateSourceType,
  updateSpeaker,
  updateSubject,
  updateUniversityName,
  updateYearFrom,
} from "../../store/slice/filterSlice";
import { useGetAutoComplete } from "../../api/Search/Autocomplete.api";
import useClickOutsideListner from "../../hooks/ClickOutsideListner.hook";
import { useForm } from "react-hook-form";
import { useGetMySearchPreference } from "../../api/Preferences/Subjects.api";
import {
  isPreferenceAddedSubjectAndDataType,
  showIsProfileToast,
  toTitleCase,
} from "../../utils/helper";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { setModal } from "../../store/slice/modalSlice";
import AutoCompleteField from "../../screens/Search/AutoCompleteField";
import { validateSearchTerm } from "../../utils/validateSearchTerm";
import { updateQueries } from "../../store/slice/appliedQueries";
import { usagelogApi } from "../../api/usageReportApi";
import { GetMyPreferenceData } from "../../types/GetMySubjects.types";
import { clearAllSetSearch } from "../../store/slice/setSearch";
import { CustomTooltip } from "../../screens/MyLibrary/MyAlerts/Alerts.style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AdvancedSearch } from "../../routes/LazyRoutes";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune"; // For refining search
import FilterListIcon from "@mui/icons-material/FilterList"; // Alternative refine icon

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 19,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 18,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#31C48D",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 15,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

interface Proptype {
  totalResult?: string | number;
}
interface SearchInput {
  searchterm: string;
}

const BasicSearchTopBar = (props: Proptype) => {
  const navigate = useNavigate();
  const searchReducer = useAppSelector((state) => state.searchReducer);
  const selectedFilters = useAppSelector((state) => state.filterReducer);
  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const { allAppliedFilter, yearFrom, earlyOnline, dateFrom, monthFrom } =
    useAppSelector((state) => state.filterReducer);

  const { openAccess, retractedArticle, datesetAvailability } = useAppSelector(
    (state) => state.filterReducer
  );

  const isAuthenticated = useAppSelector(
    (state) => state.login.informaticscustomer_id
  );

  const loading = useAppSelector((state) => state.filterReducer.loading);

  const user_id = useAppSelector((state) => state.login.user_id);

  const setSearchReducer = useAppSelector((state) => state.setSearch);

  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [autoCompleteData, setAutoCompleteData] = useState<string[] | null>(
    null
  );
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const is_ONOSCustomer = useAppSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  const isPreferenceEnabled = sessionStorage.getItem("preference") === "true";
  const [isPreference, setIsPreference] = useState(isPreferenceEnabled);
  const [isProfileUser, setIsProfileUser] = useState(false);
  const trigger = useRef(0);

  const [showAll, setShowAll] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // xs screens only

  const { handleSubmit, control, setValue, watch, setError, clearErrors } =
    useForm<SearchInput>();

  const {
    ref,
    showList: showAutoComplete,
    setShowList: setShowAutoComplete,
  } = useClickOutsideListner(false);

  const { data: autoComplete } = useGetAutoComplete(searchTerm);

  //Get Personalized Data for Preference
  const { data: myPreference, refetch } = useGetMySearchPreference(
    user_id,
    isPreference
  );

  const triggerPreference = useRef(0);

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;
  const logBasicSearchUsageData = (usageActionID: any) => {
    const isONOS =
      is_ONOSCustomer && selectedFilters?.collectionValue === "ONOSCollections"
        ? 1
        : 0;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      0
    );
  };

  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }

    const debounce = setTimeout(() => {
      refetch();
    }, 100);

    return () => {
      clearTimeout(debounce);
    };
  }, []);

  useEffect(() => {
    triggerPreference.current && applyPreference();
  }, [isPreference, myPreference]);

  useEffect(() => {
    const subscription = watch(({ searchterm }: string | any) => {
      if (searchterm) {
        setSearchTerm(searchterm?.toLocaleLowerCase());
        clearErrors("searchterm");
        searchterm.length >= 3
          ? setShowAutoComplete(true)
          : setShowAutoComplete(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [setShowAutoComplete, watch]);

  useEffect(() => {
    if (autoComplete) {
      const arr = autoComplete?.map((x) => Object.keys(x)[0]);
      setAutoCompleteData(() => arr);
    }
  }, [autoComplete]);

  const handleAutoCompleteSelect = (term: string) => {
    const searchTerm = `"${term}"`;
    setValue("searchterm", searchTerm);
    setShowAutoComplete(false);
  };

  useEffect(() => {
    if (trigger.current) onSearch();
  }, [trigger.current]);

  function onRefineSearch() {
    // usageReport("27");
    logBasicSearchUsageData(27);
    if (!searchTerm.trim()) {
      setError("searchterm", {
        type: "required",
        message: "Try for some appropriate keywords / terms",
      });
      return;
    }
    if (!validateSearchTerm(searchTerm)) {
      setError("searchterm", {
        type: "required",
        message: "Try for some appropriate keywords / terms",
      });
      return;
    }
    if (
      (Array.isArray(searchReducer.searchTerm) &&
        searchReducer.searchTerm.includes(searchTerm.trim())) ||
      searchReducer.advanceSearchQuery.includes(searchTerm.trim()) ||
      (Array.isArray(searchReducer.searchTerm) &&
        searchReducer.searchTerm.some((term) =>
          term.toLowerCase().includes(searchTerm.toLowerCase())
        ))
    ) {
      setError("searchterm", {
        type: "custom",
        message: "Search term already included",
      });
      return;
    }
    const searchedData = {
      key: "search-term",
      value: searchTerm.trim(),
    };
    if (!isAuthenticated) return;
    dispatch(updateFullText(true));
    dispatch(updateAll(false));

    dispatch(
      updateQueries({
        key: "searchTerm",
        value: searchTerm.trim(),
      })
    );

    dispatch(updateAllFilter(searchedData));
    dispatch(updateLoadingState(true));
    dispatch(updateSearchTerm(searchTerm.trim()));
    dispatch(updatePage(1));
    setValue("searchterm", "");
    dispatch(search("", null, true));
    setShowAutoComplete(false);
  }

  async function onNewSearch() {
    // usagelogApi(26)
    logBasicSearchUsageData(26);
    if (!searchTerm.trim()) {
      setError("searchterm", {
        type: "required",
        message: "Try for some appropriate keywords / terms",
      });
      return;
    }
    if (!validateSearchTerm(searchTerm)) {
      setError("searchterm", {
        type: "required",
        message: "Try for some appropriate keywords / terms",
      });
      return;
    }
    const searchedData = {
      key: "search-term",
      value: searchTerm.trim(),
    };

    dispatch(
      updateQueries({
        key: "searchTerm",
        value: searchTerm.trim(),
      })
    );
    dispatch(updateLogSearch(false));

    dispatch(updateSort("dateofpublication desc"));
    dispatch(clearAllFiltersValue());
    dispatch(updateLoadingState(true));
    dispatch(updateFullText(true));
    dispatch(clearSearchTerm());
    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(updateAllFilter(searchedData));
    dispatch(updateSearchTerm(searchTerm.trim()));
    dispatch(updateNewSearchStatus(true));
    dispatch(updatePage(1));
    dispatch(updateRow(15));
    navigate("/basicSearchScreen?searchterm=" + searchTerm.trim());
    setValue("searchterm", "");
    dispatch(updateLogSearch(true));
    dispatch(clearAllSetSearch());
    await dispatch(search("", null, true));

    setShowAutoComplete(false);
    dispatch(updateMarkedArticleCount([]));
    dispatch(updateIsSettled(true));
  }

  function removeSearchKeyword(value: string) {
    dispatch(removeSearchTerm(value));
    dispatch(removeAllFilterType(value));

    dispatch(search());
  }

  function clearAll() {
    dispatch(sliceSearchTerm());
    dispatch(clearAllFilters());
    dispatch(spliceAllFilterArray());
    trigger.current = trigger.current + 1;
  }

  // On search
  async function onSearch() {
    if (searchReducer.searchTerm.length || searchReducer.advanceSearchQuery) {
      dispatch(search());
    }
    dispatch(updateNewSearchStatus(false));
  }

  const removeFilter = (
    value: string,
    arr: string[],
    setterFunction: Function
  ) => {
    let filteredValues = arr.filter((data) => data != value);
    dispatch(setterFunction(filteredValues));
    // remove data from the allappliedfilter state
    dispatch(removeAllFilterType(value));

    dispatch(search());
  };

  async function checkPreferenceAdded(myPreference: GetMyPreferenceData) {
    if (!myPreference) return;

    const filteredSelectedTags = myPreference?.DataTypes.filter(
      ({ is_favorite }: any) => is_favorite
    ).map(({ datatype_id }) => datatype_id);
    if (filteredSelectedTags?.filter((ele: any) => ele !== 2)?.length === 0) {
      setIsPreference(false);
      sessionStorage.setItem("preference", "false");
    }
  }

  function handlePreference(event: React.ChangeEvent<HTMLInputElement>) {
    logBasicSearchUsageData(112);
    if (isProfileUser) {
      let isMyPrefAdded: boolean = true;
      if (myPreference) {
        isMyPrefAdded = isPreferenceAddedSubjectAndDataType(myPreference);
      }

      if (!isMyPrefAdded && event.target.checked) {
        dispatch(
          setModal({
            modalType: "PREFERENCE",
            modalProps: {
              open: true,
              checkPreferenceAdded,
            },
          })
        );
      } else {
        setIsPreference(event.target.checked);
      }
      if (!event.target.checked) {
        dispatch(clearDataTypeFilters());
      }
      sessionStorage.setItem("preference", event.target.checked.toString());
      triggerPreference.current = triggerPreference.current + 1;
    } else {
      showIsProfileToast("Please login as profile user to use the preferences");
    }
  }

  async function applyPreference() {
    if (!user_id || !customerData) return;
    if (!myPreference) return;

    dispatch(clearDataTypeFilters());

    if (isPreference && myPreference) {
      // Update preference data type
      const preferedSources = myPreference.DataTypes.filter(
        (type) => type.is_favorite
      )?.map((source) => source.datatype_id);

      if (preferedSources.includes(1)) {
        preferedSources.push(2);
        preferedSources.push(24);
        preferedSources.push(5);
      }

      dispatch(updateDataType(preferedSources));

      // update subject ids
      const preferedSubjects = myPreference.Subjects?.map((l1) =>
        l1.subject2_details.filter((l2) => l2.is_favorite)
      )
        .flat()
        ?.map((l2) => {
          return {
            datsubjectmasterlevel1_id: -1, //Unused, Just for Integrity's Sake
            datsubjectmasterlevel2_id: l2.subject_id2,
            level2subject_name: l2.subject_name2,
          };
        });

      const subjectIds = preferedSubjects?.map(
        (x) => x.datsubjectmasterlevel2_id
      );

      dispatch(updateSubjectType(subjectIds));
      dispatch(search());
    } else {
      const subjectIds = customerData.subject?.map(
        (x: any) => x?.datsubjectmasterlevel2_id
      );
      const dataIds = customerData?.datatypes?.map(
        (item: any) => item?.dat_resourcetype_id
      );
      dispatch(updateDataType(dataIds));

      //Update Subject Ids
      dispatch(updateSubjectType(subjectIds));
      dispatch(search());
    }
  }

  function onRemove(key: string, value: string) {
    if (key === "search-term") {
      removeSearchKeyword(value);
    }
    if (key === "data_type") {
      removeFilter(value, selectedFilters.sourceType, updateSourceType);
    }
    if (key === "subject") {
      removeFilter(value, selectedFilters.subject, updateSubject);
    }

    if (key === "author") {
      removeFilter(value, selectedFilters.author, updateAuthor);
    }

    if (key === "journal") {
      removeFilter(value, selectedFilters.journal, updateJournal);
    }

    if (key === "speaker") {
      removeFilter(value, selectedFilters.speaker, updateSpeaker);
    }

    if (key === "research") {
      removeFilter(value, selectedFilters.research, updateResearch);
    }

    if (key === "researcher") {
      removeFilter(value, selectedFilters.researcher, updateResearcher);
    }

    if (key === "journalRank") {
      removeFilter(value, selectedFilters.journalRank, updateJournalRank);
    }

    if (key === "countryOfPublication") {
      removeFilter(
        value,
        selectedFilters.countryOfPublication,
        updateCountryOfPublication
      );
    }

    if (key === "countryOfPublishingAuthor") {
      removeFilter(
        value,
        selectedFilters.countryOfPublishingAuthor,
        updateCountryOfPublishingAuthor
      );
    }

    if (key === "publisher") {
      removeFilter(value, selectedFilters.publisher, updatePublisher);
    }

    if (key === "institutions") {
      removeFilter(value, selectedFilters.institutions, updateInstitution);
    }

    if (key === "yearFrom") {
      if (Array.isArray(selectedFilters.yearFrom))
        removeFilter(value, selectedFilters.yearFrom, updateYearFrom);
    }

    if (key === "conference_name") {
      removeFilter(value, selectedFilters.conference_name, updateConfrence);
    }

    if (key === "university_name") {
      removeFilter(
        value,
        selectedFilters.university_name,
        updateUniversityName
      );
    }
  }

  const getBreadscrumLabel = (obj: any) => {
    if (obj?.key === "yearFrom") {
      return "Publication date:" + obj?.value;
    }
    if (obj?.key === "author") {
      return toTitleCase(
        obj?.value
          ?.trim()
          .replace(/^\,+|\,+$/g, "")
          .trim()
      );
      // return obj?.value
    }
    if (searchReducer.advanceSearchQuery) {
      return obj?.value;
    }
    return obj?.value?.replace(",", "");
  };

  function openPreferenceInfoModal() {
    dispatch(
      setModal({
        modalType: "PREFERENCE_INFO_MODEL",
        modalProps: {
          isOpen: true,
        },
      })
    );
  }

  const buttonConfigs = [
    {
      condition: openAccess,
      onClick: () => {
        dispatch(updateOpenAccess(false));
        dispatch(search());
      },
      label: "Open Access",
    },
    {
      condition: retractedArticle,
      onClick: () => {
        dispatch(updateRetractedArticle(false));
        dispatch(search());
      },
      label: "Retracted Articles",
    },
    {
      condition: datesetAvailability,
      onClick: () => {
        dispatch(updateDateset(false));
        dispatch(search());
      },
      label: "Dataset Availability",
    },
    {
      condition:
        yearFrom &&
        yearFrom.toString() !==
          `[${dayjs().year()} TO ${dayjs().year()}]`.toString(),
      onClick: () => {
        dispatch(updateYearFrom(""));
        dispatch(search());
      },
      label: yearFrom,
    },
    {
      condition:
        yearFrom &&
        yearFrom.toString() ===
          `[${dayjs().year()} TO ${dayjs().year()}]`.toString(),
      onClick: () => {
        dispatch(updateYearFrom(""));
        dispatch(search());
      },
      label: "Current Year",
    },
    {
      condition: monthFrom,
      onclick: () => {
        dispatch(updateMonthFrom(""));
        dispatch(search());
      },
      label: "Last One Month",
    },
    {
      condition: dateFrom,
      onClick: () => {
        dispatch(updateDateFrom(""));
        dispatch(search());
      },
      label: "Last One Week",
    },
    {
      condition: earlyOnline,
      onClick: () => {
        dispatch(updateEarlyOnline(false));
        dispatch(search());
      },
      label: "Early Online",
    },
  ];

  const ResponsiveCombinedButtons = ({
    allAppliedFilter, // array of applied filters; each object has a key and value
    buttonConfigs, // array of config buttons with condition, onClick, and label properties
    isSmallScreen, // boolean flag for xs screens
    onRemove, // function to remove a filter item (expects key and value)
    webStyle,
    getBreadscrumLabel,
  }: any) => {
    const [showAll, setShowAll] = useState(false);

    // Normalize applied filters into our common button shape
    const filterButtons =
      Array.isArray(allAppliedFilter) && allAppliedFilter.length > 0
        ? allAppliedFilter.map((obj, index) => ({
            type: "filter",
            key: obj?.key || index,
            label: getBreadscrumLabel(obj),
            // For the first filter, we don’t attach an onClick (or you can allow removal as needed)
            onClick:
              index !== 0 ? () => onRemove(obj?.key, obj?.value) : undefined,
            // We only show a CloseIcon for filters beyond the first (adjust if needed)
            showIcon: index > 0,
          }))
        : [];

    // Normalize config buttons – include only those where the condition is true
    const configButtons = buttonConfigs
      .filter((btn: { condition: any }) => btn.condition)
      .map((btn: { label: any; onClick: any }, index: any) => ({
        type: "config",
        key: `config-${index}-${btn.label}`,
        label: btn.label,
        onClick: btn.onClick,
        showIcon: true,
      }));

    // Combine both arrays
    const allButtons = [...filterButtons, ...configButtons];

    if (allButtons.length === 0) return null;

    // For small screens, show only the first button if not expanded
    const visibleButtons =
      isSmallScreen && !showAll ? allButtons.slice(0, 1) : allButtons;
    const remainingCount = allButtons.length - visibleButtons.length;

    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Stack direction="row" spacing={2} flexWrap="wrap">
          {visibleButtons.map((btn, index) => (
            <Button
              key={btn.key}
              size="small"
              variant="outlined"
              // Show a CloseIcon for filter buttons (if applicable)
              endIcon={btn.showIcon ? <CloseIcon /> : null}
              sx={webStyle.searchTextFont}
              onClick={btn.onClick}
            >
              {btn.label}
            </Button>
          ))}

          {/* If on small screen and not all buttons are visible, show the More button */}
          {isSmallScreen && !showAll && allButtons.length > 1 && (
            <Button
              size="small"
              variant="text"
              onClick={() => setShowAll(true)}
              sx={{ textTransform: "none" }}
            >
              More +{remainingCount}
            </Button>
          )}
          {isSmallScreen && showAll && allButtons.length > 1 && (
            <Button
              size="small"
              variant="text"
              onClick={() => setShowAll(false)}
              sx={{ textTransform: "none", mt: 1 }}
            >
              Less
            </Button>
          )}
        </Stack>
      </Box>
    );
  };

  return (
    <Box bgcolor={Colors.white} py={"1%"} key={"top-bar"}>
      <Box sx={{ paddingInline: { xs: "10px", sm: "35px", md: "66px" } }}>
        <Box
          sx={{
            ...webStyle.flexRow,
            justifyContent: { xs: "space-between", sm: "initial" },
          }}
        >
          <Box sx={webStyle.flexRow}>
            <IconButton
              onClick={() => {
                dispatch(updateIsNavigatingBack(true));
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon sx={{ alignSelf: "center" }} fontSize="small" />
            </IconButton>
            <Typography sx={webStyle.textResult}>Search</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={[webStyle.toogleText]}>Preferences</Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginLeft={"6%"}
            >
              <AntSwitch
                checked={isPreference}
                inputProps={{ "aria-label": "ant design" }}
                onChange={handlePreference}
              />
            </Stack>
            <Stack>
              <IconButton size="medium" onClick={openPreferenceInfoModal}>
                <InfoOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </Box>
        </Box>

        {/* Input box */}
        <Box sx={[{ marginTop: "1%", display: "flex", flexDirection: "row" }]}>
          <Box width={"100%"} position={"relative"}>
            <AutoCompleteField
              fieldName="searchterm"
              options={autoCompleteData}
              control={control}
              handleSubmit={handleSubmit}
              handleSearch={onRefineSearch}
              handleAutoCompleteSelect={handleAutoCompleteSelect}
              setValue={setValue}
              fullWidth
              loading={loading}
            />
          </Box>

          <Button
            variant="contained"
            sx={{ ...webStyle.button, display: { xs: "none", sm: "flex" } }}
            onClick={onRefineSearch}
          >
            Refine Search
          </Button>
          <Stack sx={{ display: { xs: "initial", sm: "none" } }}>
            <IconButton size="large" onClick={onRefineSearch}>
              <FilterListIcon fontSize="inherit" />
              {/* <TuneIcon fontSize="inherit" /> */}
            </IconButton>
          </Stack>
          <Button
            variant="outlined"
            sx={{ ...webStyle.button, display: { xs: "none", sm: "flex" } }}
            onClick={onNewSearch}
          >
            New Search
          </Button>
          <Stack sx={{ display: { xs: "initial", sm: "none" } }}>
            <IconButton size="large" onClick={onNewSearch}>
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </Box>

        {/* bottom text */}
        {/* @ts-ignore */}
        <Box
          sx={[
            // webStyle.flexRow,
            {
              display: "flex",
              flexDirection: "row",
              // marginTop: "3.5%",
              marginTop: { xs: "3.5%", sm: "3.5%", md: "1%" },
              // marginBottom: "1%",
              flexWrap: "wrap",
              gap: 1,
              alignItems: "center",
            },
          ]}
        >
          <Button sx={webStyle.clearallText} onClick={clearAll}>
            Clear All
          </Button>

          {/* {Array.isArray(allAppliedFilter) &&
            allAppliedFilter?.map((obj, i) => (
              <Stack direction="row" spacing={2} key={i}>
                <Button
                  key={i}
                  size="small"
                  variant="outlined"
                  endIcon={i > 0 ? <CloseIcon /> : null}
                  sx={webStyle.searchTextFont}
                  onClick={() => {
                    if (i > 0) {
                      onRemove(obj?.key, obj?.value);
                    }
                  }}
                >
                  {getBreadscrumLabel(obj)}
                </Button>
              </Stack>
            ))}
          {buttonConfigs
            .filter((btn) => btn.condition)
            .map((btn, index) => (
              <Button
                key={index}
                size="small"
                variant="outlined"
                endIcon={<CloseIcon />}
                sx={webStyle.searchTextFont}
                onClick={btn.onClick}
              >
                {btn.label}
              </Button>
            ))}
            ))} */}

          <ResponsiveCombinedButtons
            allAppliedFilter={allAppliedFilter}
            buttonConfigs={buttonConfigs}
            isSmallScreen={isSmallScreen}
            onRemove={onRemove}
            webStyle={webStyle}
            getBreadscrumLabel={getBreadscrumLabel}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(BasicSearchTopBar);

const webStyle = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "red",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    fontSize: { xs: "1.25rem", sm: 24 },
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
  },
  toogleText: {
    fontWeight: "500",
    marginLeft: { xs: "0%", sm: "20%" },
    fontSize: { xs: "1.1rem", sm: "18px" },
  },
  clearallText: {
    // fontFamily: "Helvetica Neue",
    // fontWeight: "500",
    // color: Colors.primary,
    // fontSize: 14,
    // alignSelf: "center",
    height: 22,
    fontSize: { xs: "0.878rem", sm: "16px" },
    // textTransform: "none",
  },
  searchTextContainer: {
    marginLeft: "1%",
    border: 1,
    borderColor: Colors.gray_400,
    borderRadius: 1,
  },
  searchText: {
    // padding:"0.1%"
    // padding:0.5
  },
  searchTextFont: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: Colors.gray900,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: Colors.gray_400,
    marginLeft: 1,
    textTransform: "capitalize",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 22,
    borderRadius: "6px",
    fontSize: { xs: "0.878rem", sm: "14px" },
  },
  button: {
    marginLeft: "0.9%",
    padding: { xs: ".2rem", sm: "0.7rem" },
    width: "10%",
    minWidth: { xs: "60px", sm: "128px" },
    fontWeight: "500",
    fontSize: { xs: "10px", sm: "14px" },
  },
  inputField: {
    // width: "77%",
    marginRight: "1%",
    borderRadius: 60,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.coolGray300,
        borderRadius: 2,
      },
    },
    fontFamily: "Helvetica Neue",
    fontSize: "0.875rem",
  },
};
