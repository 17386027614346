import {
  Checkbox,
  Divider,
  Grid,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { ChangeEvent, useEffect, useState } from "react";
import logo from "../assets/img/logo.png";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box, { BoxProps } from "@mui/material/Box";
import {
  loginPending,
  loginSuccess,
  loginFail,
} from "../store/slice/loginSlice";
import { profileDetailsAPI, userLogin } from "../api/userApi";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import LoginImage from "../assets/images/Login.jpg";
import "../styles/Login.css";
import Captcha from "../components/captcha";
import { updateCaptchaStatus } from "../store/slice/captchaVerification";
import { reset } from "../store/slice/searchSlice";
import { setModal } from "../store/slice/modalSlice";
import { RememberMe } from "@mui/icons-material";
import { usagelogApi, usageReport } from "../api/usageReportApi";
import getCustomerDetailsByIp from "../api/getCustomerDetailByIp";
import api from "../api/endpoints";
import Footer from "../components/footer";
import { isMobile } from "react-device-detect";

const storedCredentials = (
  email: any,
  password: any,
  rememberMe: any,
  checked: any
) => {
  if (rememberMe && checked) {
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
    localStorage.setItem("rememberMe", rememberMe.toString());
    localStorage.setItem("checked", checked);
  } else {
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("checked");
  }
};

const retrieveCredentials = () => {
  const storedEmail = localStorage.getItem("email");
  const storedPassword = localStorage.getItem("password");
  const storedRememberMe = localStorage.getItem("rememberMe");
  const storedCheckedState = localStorage.getItem("checked");

  if (storedEmail && storedRememberMe && storedCheckedState) {
    return {
      email: storedEmail,
      password: storedPassword,
      rememberMe: storedRememberMe === "true",
      checked: storedCheckedState,
    };
  }

  return {
    email: "",
    password: "",
    rememberMe: false,
    checked: true,
  };
};

const removeStoredCredentials = (checked: any) => {
  if (!RememberMe && !checked) {
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("checked");
  }
};

export interface State extends SnackbarOrigin {
  open: boolean;
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        width: {xs:"90%",md:"60%"},
        textAlign: "center",
        ...sx,
      }}
      {...other}
    />
  );
}

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState<any>("");
  const [ipAddress, setIpAddress] = React.useState<string>("");
  const [ip4Address, setIp4Address] = React.useState<string>("");

  const [message, setMessage] = useState("");
  const [link, setLink] = useState("");
  const [open, setOpen] = useState(false);

  const [rememberMe, setRememberMe] = useState<any>(false);
  const [checked, setChecked] = useState<any>(false);

  useEffect(() => {
    const storedCredentials = retrieveCredentials();
    setEmail(storedCredentials.email);
    setPassword(storedCredentials.password);
    setRememberMe(storedCredentials.rememberMe);
    setChecked(storedCredentials.checked);
  }, []);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const { data, csrf_token } = useSelector((state: any) => state.login);

  useEffect(() => {
    if (csrf_token === sessionStorage.getItem("csrf_token")) {
      navigate("/initialSearch");
    }
  }, [csrf_token, navigate]);

  useEffect(() => {
    const closeCaptcha = () => {
      dispatch(
        setModal({
          modalType: "CAPTCHA",
          modalProps: {
            open: false,
          },
        })
      );
    };
    closeCaptcha();
  }, []);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        // const responses = await fetch("https://api.ipify.org?format=json");
        const responses = await fetch(
          "https://diskoverapi.jgatenext.com/api/get_client_ip/"
        );
        const datas = await responses.json();
        //ipAddress = datas?.ip;
        setIp4Address(datas?.client_ip);
        sessionStorage.setItem("user_ipv4_address", datas?.client_ip);
      } catch (error) {
        setIp4Address("0");
        console.log("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
  }, []);

  const onSubmit = async (e: any) => {
    setCaptchaVerified(true);
    sessionStorage.setItem("isIPLogin", "false");
    e.preventDefault();

    storedCredentials(email.trim(), password.trim(), rememberMe, checked);

    removeStoredCredentials(checked);

    if (validate()) {
      dispatch(loginPending());
      dispatch(reset());
      try {
        const isAuth: any = await userLogin({
          email: email.trim(),
          password: password.trim(),
          navigate,
        });
        const USER_DATA = data;
        dispatch(loginSuccess(isAuth));
        const userid = isAuth?.user_id;

        const UserData: any = await profileDetailsAPI({ userid });
        let ipAddress;
        const fetchIpAddress = async () => {
          try {
            // const responses = await fetch("https://api.ipify.org?format=json");
            const responses = await fetch(
              "https://diskoverapi.jgatenext.com/api/get_client_ip/"
            );
            const datas = await responses.json();
            ipAddress = datas?.client_ip;
          } catch (error) {
            setIpAddress("0");
            console.log("Error fetching IP address:", error);
          }
        };
        fetchIpAddress();
        let ipResponse;
        if (!ipAddress) {
          // ipResponse = await getCustomerDetailsByIp(ipAddress);
        } else {
          ipResponse = await getCustomerDetailsByIp(ipAddress);
        }

        if (UserData?.data?.data[0][0]?.user_type === 2) {
          usagelogApi(
            isAuth.user_id,
            isAuth.informaticscustomer_id,
            null,
            2,
            null,
            null,
            null,
            null,
            null,
            null,
            ip4Address,
            null,
            isAuth.session_key,
            1,
            null,
            0
          );
          // usageReport("2");
        } else if (ipResponse?.length > 0) {
          // usageReport("6");
          usagelogApi(
            isAuth.user_id,
            isAuth.informaticscustomer_id,
            null,
            6,
            null,
            null,
            null,
            null,
            null,
            null,
            ip4Address,
            null,
            isAuth.session_key,
            1,
            null,
            0
          );
        } else {
          console.log("usagereport2");
          // usageReport("3");
          usagelogApi(
            isAuth.user_id,
            isAuth.informaticscustomer_id,
            null,
            3,
            null,
            null,
            null,
            null,
            null,
            null,
            ip4Address,
            null,
            isAuth.session_key,
            1,
            null,
            0
          );
        }
        // usageReport("8");
        usagelogApi(
          isAuth.user_id,
          isAuth.informaticscustomer_id,
          null,
          8,
          null,
          null,
          null,
          null,
          null,
          null,
          ip4Address,
          null,
          isAuth.session_key,
          1,
          null,
          0
        );
        isCaptchaVerified && dispatch(updateCaptchaStatus(true));
        // navigate("/initialSearch");
      } catch (error: any) {
        setOpen(true);
        if (
          error?.response?.data?.data ===
          "Login Pending.Get method is not allowed."
        ) {
          setMessage("Invalid username or pasword");
        } else if (
          error?.response?.data?.data === "Client Subscription Expired"
        ) {
          setMessage("Subscription Expired, Please contact ");
          setLink("mailto:jcoordinator@informaticsglobal.ai");
        } else {
          setMessage(error?.response?.data?.data);
        }
        //   setMessage('Invalid username or password.')
        dispatch(loginFail(error.message));
      }
    }
  };

  // useEffect(() => {
  //   const fetchIpAddress = async () => {
  //     try {
  //       const response = await fetch("https://api.ipify.org?format=json");
  //       const data = await response.json();
  //       setIpAddress(data?.ip);
  //       console.log("IP Address:", data?.ip);
  //     } catch (error) {
  //       setIpAddress("0");
  //       console.log("Error fetching IP address:", error);
  //     }
  //   };

  //   fetchIpAddress();
  // }, []);

  // useEffect(() => {
  //   getDetailsByIp();
  // }, [ipAddress]);

  // async function getDetailsByIp() {
  //   if (!ipAddress) return;

  //   const response = await getCustomerDetailsByIp(ipAddress);
  //   console.log(response);
  //   if (response?.informaticscustomer_id > 0) {
  //     sessionStorage.setItem(
  //       "informaticscustomer_id",
  //       response?.informaticscustomer_id
  //     );
  //     sessionStorage.setItem("isProfileUser", "false");
  //     notify("success", "Login with IP Address");
  //     dispatch(
  //       loginSuccess({
  //         informaticscustomer_id: response?.informaticscustomer_id,
  //       })
  //     );
  //     navigate("/initialSearch");
  //   }
  // }

  const validate = () => {
    let result = true;
    if (email === "" || email === null) {
      result = false;
      setOpen(true);
      setMessage("Please Enter Email");
    }
    if (password === "" || password === null) {
      result = false;
      setOpen(true);
      setMessage("Please Enter Password");
    }
    if (
      (email === "" && password === "") ||
      (email === null && password === null)
    ) {
      //modify later
      result = false;
      setOpen(true);
      setMessage("Please Enter Email and Password");
    }
    return result;
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    captchaText: string
  ) {
    if (e.target.value == captchaText) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  }

  function onCaptchaReset() {
    setCaptchaVerified(false);
  }
  const windowHeight = window.innerHeight;

  return (
    <>
      <div className="container" style={{ overflowY: "hidden" }}>
        {message && (
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {message}
                {message == "Subscription Expired, Please contact " && (
                  <a href={link} target="_blank" rel="noreferrer">
                    jcoordinator@informaticsglobal.ai
                  </a>
                )}
              </Alert>
            </Snackbar>
          </Stack>
        )}

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
          className="container-info"
          sx={{
            justify: "space-between",
            alignItems: "100%",
            height: "100vh",
            // height: '1024px',
            // maxHeight: '1024px'
          }}
        > 
          <img src={logo} alt="" className="logoStyle" />
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                height: "100%",
              }}
              className="login-container"
            >
              <Box
                component="form"
                noValidate
                height="100vh"
                sx={{
                  marginTop: {xs:"0",md:"12%"},
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Item
                  sx={{
                    margin: { md: 0, lg: "8px" },
                    padding: { md: 0, lg: "8px" },
                  }}
                >
                  <Typography
                    component="h1"
                    className="heading"
                    fontWeight={700}
                    fontSize={34}
                    fontFamily={"Lora"}
                  >
                    Log In
                  </Typography>
                </Item>
                <Item>
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel
                      sx={{
                        color: "#9CA3AF",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                      htmlFor="outlined-adornment-email"
                    >
                      Email
                    </InputLabel>

                    <OutlinedInput
                      onKeyPress={(e: any) => {
                        if (!isCaptchaVerified) return;
                        if (e.key === "Enter") {
                          onSubmit(e);
                        }
                      }}
                      name="email"
                      autoComplete="off"
                      id="email"
                      sx={{
                        width: "100%",
                        background: "#fffff",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                      value={email}
                      onChange={(e: any) => setEmail(e.target.value)}
                      label="Email"
                      size={windowHeight <= 720 ? "small" : "medium"}
                    />
                  </FormControl>
                </Item>
                <Item>
                  <FormControl
                    variant="outlined"
                    sx={{ width: "100%", marginTop: "-3%" }}
                  >
                    <InputLabel
                      sx={{
                        color: "#9CA3AF",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                      htmlFor="outlined-adornment-password"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent default Enter key behavior
                          console.log("Enter key pressed");
                          onSubmit(e);
                        }
                      }}
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                      value={password}
                      onChange={(e: any) => setPassword(e.target.value)}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      size={windowHeight <= 720 ? "small" : "medium"}
                    />
                  </FormControl>
                </Item>
                {/* <Item className="block">
                  <Captcha
                    onChange={handleChange}
                    onCaptchaReset={onCaptchaReset}
                    isCaptchaVerified={isCaptchaVerified}
                    onSubmit={onSubmit}
                  />
                </Item> */}
                <Item sx={{ padding: 0 }}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={6}>
                      <Box
                        textAlign="left"
                        className="checkboxStyle"
                        display="flex"
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: "#6B7280",
                          }}
                        >
                          Remember Me
                        </Typography>
                        <Checkbox
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                          // defaultChecked sx={{ marginTop: "-3%" }}
                          sx={{ marginTop: "-3%" }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sx={{ textAlign: "right" }}>
    <Link to="/resetpassword" className="forgotpassword_link">
      Forgot password?
    </Link>
  </Grid>
                  </Grid>
                </Item>
                <Item sx={{ marginTop: "10px" }}>
                  <Button
                    className="commonbutton"
                    onClick={onSubmit}
                    size="large"
                    variant="contained"
                    disabled={!email.length || !password.length}
                  >
                    Log In
                  </Button>
                </Item>

                <Item>
                  <Divider
                    variant="middle"
                    sx={{
                      height: "1%",
                      background: "#D9D9D9",
                      marginTop: "4%",
                    }}
                    className="block"
                  />
                </Item>
                <Item className="block">
                  <Box>
                    <Typography
                      fontWeight={700}
                      textAlign="center"
                      fontFamily={"Helvetica Neue"}
                      fontSize={"14px"}
                    >
                      Want to Login with Shibboleth?{" "}
                      <Link
                        className="linktext_redirect"
                        to={`${api.baseApi}/api/shibboleth_login/shibboleth_log_in`}
                      >
                        Login
                      </Link>
                    </Typography>
                  </Box>
                </Item>
                <Item className="block">
                  <Box>
                    <Typography
                      fontWeight={700}
                      textAlign="center"
                      fontFamily={"Helvetica Neue"}
                      fontSize={"14px"}
                    >
                      Sign up for a{" "}
                      <a
                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=M_4_nylwpkuTMKIkglk7dh9jpA7zC89LgjVE64YxnDtUMjdYVUFKRVM3NFU5U0FJVU1ENzhOT0lZNy4u"
                        target="_blank"
                        rel="noreferrer"
                        className="linktext_redirect"
                      >
                        {/* <Link className="linktext_redirect" to=""> */}
                        trial
                        {/* </Link> */}
                      </a>
                    </Typography>
                  </Box>
                </Item>
                <Item sx={{ my: 0, py: 0 }}>
                  <Box sx={{ marginTop: "10px" }}>
                    <Typography
                      fontWeight={500}
                      textAlign="center"
                      fontFamily={"Helvetica Neue"}
                      fontSize={"14px"}
                      sx={{ color: "#212121" }}
                    >
                      Don&apos;t have an account?{" "}
                      <Link to="/signup" className="linktext_redirect">
                        Register
                      </Link>
                    </Typography>
                  </Box>
                </Item>
              </Box>
            </div>
          </div>
        </Grid>

        <Grid
          className="container-main"
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            height: "100vh",
            // backgroundColor: "#7852FB",
            // height: '1024px',
          }}
        >
          <img
            src={LoginImage}
            alt="loginImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Grid>
      </div>
      {/* { isMobile?<Box>
        <Footer />
      </Box>: */}
      {/* <Box sx={{ 
        position:{xs: "static", md: "sticky" },
        bottom: 0, zIndex: 1000 }}>
        <Footer />
      </Box> */}
      {/* } */}
    </>
  );
};

export default Login;
