import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { alertStyles as styles } from "../../../screens/MyLibrary/MyAlerts/Alerts.style";
import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Colors } from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { modalBaseStyles } from "./DeleteSearch.modal";
import {
  checkAlertTopicAvailability,
  subscribeToRss,
} from "../../../api/MyLibrary/SearchAlerts.api";
import { TextField } from "@mui/material";
import { usagelogApi } from "../../../api/usageReportApi";
import { notify } from "../../../utils/Notify";

interface Props {
  show: boolean;
  onClose: () => void;
  alertData: any;
  isSavedSearch?: boolean;
  refetch?: any;
  emailLog?: any;
  rssLog?: any;
  setOpenRss: any;
}

const frequency = [
  {
    i: 1,
    freq: "Daily",
  },
  {
    i: 2,
    freq: "Weekly",
  },
  {
    i: 3,
    freq: "Bi-Weekly",
  },
  {
    i: 4,
    freq: "Monthly",
  },
  {
    i: 5,
    freq: "Quarterly",
  },
];

const RSSAlertModal = ({
  show,
  onClose,
  alertData,
  isSavedSearch,
  refetch,
  emailLog,
  rssLog,
  setOpenRss,
}: Props) => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rssURL, setRSSurl] = useState("");
  const [isEmail, setIsEmail] = useState(true);
  const [isRSS, setRSS] = useState(false);
  const [selectedFreq, setSelectedFreq] = useState<string>(
    frequency[0].i.toString()
  );
  const [alertTopic, setAlertTopic] = useState("");
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFreq(event.target.value);
  };
  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmail(event.target.checked);
  };
  const handleRSS = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRSS(event.target.checked);
  };

  useEffect(() => {
    setIsEmail(alertData?.alert_type === 3 || alertData?.alert_type === 1);
    setRSS(alertData?.alert_type === 3 || alertData?.alert_type === 2);
    setAlertTopic(alertData?.alert_topic);
    //cleanup funciton
    return () => {
      setShowConfirmation(false);
      setIsEmail(false);
      setRSS(false);
      setAlertTopic("");
    };
  }, [alertData?.alert_type, show]);

  const logAlertUsageData = (emailActionID: any, rssActionID: any) => {
    if (isEmail) {
      usagelogApi(
        profileData.user_id,
        profileData?.informaticscustomer_id,
        profileData1?.consortiamaster_id,
        emailActionID,
        null,
        null,
        null,
        null,
        null,
        null,
        // profileData.ip_v4,
        null,
        null,
        profileData.session_key,
        1,
        null
      );
    }
    if (isRSS) {
      usagelogApi(
        profileData.user_id,
        profileData?.informaticscustomer_id,
        profileData1?.consortiamaster_id,
        rssActionID,
        null,
        null,
        null,
        null,
        null,
        null,
        // profileData.ip_v4,
        null,
        null,
        profileData.session_key,
        1,
        null
      );
    }
    // isEmail
    //   ? usagelogApi(
    //       profileData.user_id,
    //       profileData?.informaticscustomer_id,
    //       profileData1?.consortiamaster_id,
    //       emailActionID,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       // profileData.ip_v4,
    //       null,
    //       null,
    //       profileData.session_key,
    //       1,
    //       null
    //     )
    //   : null;
    // isRSS
    //   ? usagelogApi(
    //       profileData.user_id,
    //       profileData?.informaticscustomer_id,
    //       profileData1?.consortiamaster_id,
    //       rssActionID,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       null,
    //       // profileData.ip_v4,
    //       null,
    //       null,
    //       profileData.session_key,
    //       1,
    //       null
    //     )
    //   : null;
  };

  const handleAdd = async () => {
    let alertType = null;
    if (isEmail && isRSS) {
      alertType = 3;
    } else if (isEmail && !isRSS) {
      alertType = 1;
    } else if (isRSS && !isEmail) {
      alertType = 2;
    }
    // logAlertUsageData(emailLog, rssLog);

    let data = {
      data: [
        {
          usermaster_id: selector.user_id,
          alert_topic: alertTopic,
          usersearchlog_id: isSavedSearch
            ? alertData?.search_id
            : alertData?.alert_id,
          mail_frequency: selectedFreq,
          alert_type_id: alertType,
        },
      ],
    };
    const formData = new FormData();
    formData.append("alertjson", JSON.stringify(data));

    const res = await subscribeToRss(formData);
    const rssUrl = res?.data?.data[0].rssurl;
    setRSSurl(rssUrl);
    if (rssURL) {
      setOpenRss(false);
    }
    setShowConfirmation(true);
    refetch && refetch();
  };
  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="RSSAlertModal-Title"
      aria-describedby="RSSAlertModal-Description"
    >
      {!showConfirmation ? (
        <Box
          sx={{
            ...modalBaseStyles,
            display: "flex",
            flexDirection: "column",
            // height: "30vh",
            // width: "500px",
            width: { xs: "90%", sm: "60%", md: "50%", lg: "40%", xl: "40%" },
            alignItems: "flex-start",
            justifyContent: "space-between",
            px: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mb: 3,
            }}
          >
            <Text
              id="modal-modal-title"
              sx={{
                fontWeight: 600,
                // fontSize: "1.4rem",
                fontSize: { xs: "1.2rem", sm: "1.4rem" },
                textAlign: "center",
              }}
            >
              Create New Email/RSS Alert
            </Text>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ width: { xs: "100%" } }}>
            <Text
              fontSize={16}
              fontWeight={500}
              textAlign="left"
              color="#212121"
            >
              Alert Topic
            </Text>

            <TextField
              margin="normal"
              id="text"
              placeholder="Enter topic name"
              name="name"
              autoComplete="name"
              autoFocus
              value={alertTopic}
              onChange={(e: any) => setAlertTopic(e.target.value)}
              sx={{ width: "100%", background: "#fffff" }}
              fullWidth={true}
            />
          </Box>

          <Box sx={{ width: "100%", mb: 1 }}>
            <Text>Email Alert Frequency</Text>
            <Select
              value={selectedFreq}
              onChange={handleChange}
              displayEmpty
              sx={styles.frequency}
              inputProps={{ "aria-label": "Without label" }}
            >
              {frequency?.map((item) => (
                <MenuItem value={item.i} key={item.i}>
                  {item.freq}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <FormGroup sx={{ my: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  value={isEmail}
                  checked={isEmail}
                  onChange={handleEmail}
                />
              }
              label="Email Alerts"
            />
            <FormControlLabel
              control={
                <Checkbox value={isRSS} checked={isRSS} onChange={handleRSS} />
              }
              label="RSS Alerts"
            />
          </FormGroup>

          <Box sx={{ width: "100%", my: 1 }}>
            <Button
              variant="contained"
              sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
              onClick={handleAdd}
            >
              Save
            </Button>
            <Button sx={{ color: Colors.black }} onClick={() => onClose()}>
              Cancel
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            ...modalBaseStyles,
            display: "flex",
            flexDirection: "column",
            //width: "35vw",
            width: { xs: "90%", sm: "60%", md: "50%", lg: "40%", xl: "40%" },
            maxHeight: "77vh",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Text
              id="modal-modal-title"
              sx={{
                fontWeight: 600,
                fontSize: "1.4rem",
                textAlign: "center",
              }}
            >
              Alert Saved Successfully
            </Text>
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Text>Please add the below url in RSS Reader to receive alerts</Text>

          <a
            style={{
              color: Colors.primary,
              marginBlock: 2,
              textOverflow: "ellipsis",
              // width: "400px",
              overflow: "hidden",
              cursor: "copy",
              wordBreak: "break-word",
            }}
            href={rssURL}
            target="_blank"
            rel="noreferrer"
          >
            {rssURL}
          </a>

          <Box sx={{ width: "100%", my: 2 }}>
            <Button
              variant="contained"
              sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
              onClick={() => {
                onClose();
                setShowConfirmation(false);
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RSSAlertModal;
