import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

type DataType = { val: string; count: string };
interface Proptype {
  isOpen: boolean;
  handleClose: () => void;
  modalName: string;
  getFilterdValues?: (e: string[]) => void;
  yearData?: DataType[];
  filterloader?: boolean;
}

const ModalThree = (props: Proptype) => {
  const {
    isOpen,
    handleClose,
    modalName,
    getFilterdValues,
    yearData,
    filterloader,
  } = props;
  const [filteredValues, setFilteredvalues] = useState<string[]>([]);

  const selectedValues = (data: string) => {
    if (filteredValues.length === 0) {
      setFilteredvalues([data]);
    } else if (filteredValues.includes(data)) {
      setFilteredvalues(() => filteredValues.filter((e) => e !== data));
    } else {
      setFilteredvalues((prev) => [...prev, data]);
    }
  };

  const handleSubmit = () => {
    getFilterdValues && getFilterdValues(filteredValues);
    setFilteredvalues([]);
    handleClose();
  };

  function popupTitle() {
    return (
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            {modalName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      {popupTitle()}

      <DialogContent sx={{ width: 700 }}>
        {filterloader && (
          <Backdrop
            open={filterloader}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)", // Light overlay effect
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={40} color="primary" />
          </Backdrop>
        )}
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2 }}
          flexWrap="wrap"
        >
          {Array.isArray(yearData) &&
            yearData?.map((data) => (
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ gap: 2 }}
                minWidth={300}
              >
                <Stack direction={"row"} alignItems="center" minWidth={200}>
                  <Checkbox onClick={() => selectedValues(data.val)} />
                  <Typography>{data.val}</Typography>
                </Stack>
                <Typography>({data.count})</Typography>
              </Stack>
            ))}
        </Stack>

        {/* Buttons */}
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ gap: 2, marginBlock: 2 }}
        >
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ minWidth: 150 }}
          >
            Apply
          </Button>
          <Button
            onClick={handleClose}
            sx={{ minWidth: 150, color: "#111928" }}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ModalThree;
