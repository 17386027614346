import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  Typography as Text,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  basicSearchForCounts,
  basicSearchMain,
} from "../../api/Search/BasicSearch.api";
import { setSearch } from "../../api/Search/SetSearch";
import { queryConstructor } from "../../api/formQuery";
import { usagelogApi } from "../../api/usageReportApi";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateQueries } from "../../store/slice/appliedQueries";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updateAllFilter,
  updateSort,
} from "../../store/slice/filterSlice";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import {
  clearSearchTerm,
  updateAllFacets,
  updateAuthorFacets,
  updateAuthorFiltersLoader,
  updateFullText,
  updateNewSearchStatus,
  updateOtherFiltersLoader,
  updatePage,
  updateRow,
  updateSearchResults,
  updateSearchTerm,
  updateSubjectFacets,
  updateSubjectFilterLoader,
} from "../../store/slice/searchSlice";
import {
  updateSetInNumber,
  updateSetSearchQuery,
  updateSetSearchStatus,
} from "../../store/slice/setSearch";
import { notify } from "../../utils/Notify";
import { Colors } from "../../utils/constants";
import { onSearch } from "../Search/onSearch";
import { linkToDisplay } from "./HistoryTable";
import { SearchHistory, stringSanitizer } from "./SearchHistory";
import { searchHistoryStyles as sx } from "./SearchHistory.styles";
import { setModal } from "../../store/slice/modalSlice";

type FieldArray = {
  id: number;
  conditon: "AND" | "OR";
  value: string;
};

type QueryObj = {
  customerid: string;
  sessionid: string;
  sort: string;
  fq__resource_type: string;
  fq__subjects_id_l2: string;
  rows: number;
  op_mode: string;
  datsearchtype_id: string;
  titleKeywordsAbs: string;
};
type Props = {
  searchHistory: SearchHistory[];
};

const QueryBuilder = ({ searchHistory }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessionKey = useAppSelector((state) => state.login.session_key);
  const customerId = useAppSelector(
    (state) => state.login.informaticscustomer_id
  );
  const customerData = useAppSelector(
    ({ customer }) => customer.customerDetails
  );

  const [firstField, setfirstField] = useState<string>("");
  const [fieldArray, setFieldArray] = useState<FieldArray[]>([
    { id: new Date().valueOf(), conditon: "AND", value: "" },
  ]);
  const [query, setQuery] = useState("");
  const [isModifiedQueryValid, setIsModifiedQueryValid] = useState(true);
  const [isValidatingQuery, setIsValidatingQuery] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const profileData = useAppSelector((state) => state.login);
  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handleAddField = (index: number) => {
    let newFieldArray: FieldArray[] = [];
    const newField: FieldArray = {
      id: new Date().valueOf(),
      conditon: "AND",
      value: "",
    };

    if (index === fieldArray.length - 1) {
      //if last then add new field
      newFieldArray = [...fieldArray, newField];
    } else {
      newFieldArray = [...fieldArray];
      newFieldArray.splice(index + 1, 0, newField);
    }

    setFieldArray(newFieldArray);
  };

  const handleRemoveField = (id: number) => {
    if (fieldArray.length > 1) {
      setFieldArray(fieldArray.filter((x) => x.id !== id));
    }
  };

  const handleInputField = (id: number, value: string) => {
    setFieldArray(
      fieldArray.map((x) => {
        if (x.id === id) {
          x.value = value;
        }
        return x;
      })
    );
  };

  const handleSelectField = (id: number, value: "AND" | "OR") => {
    setFieldArray(
      fieldArray.map((x) => {
        if (x.id === id) {
          x.conditon = value;
        }
        return x;
      })
    );
  };

  const buildQuery = useCallback(() => {
    let _query = firstField !== "" ? `#${firstField} ` : "";
    fieldArray.forEach((x) => {
      if (x.value !== "") {
        _query += `${x.conditon} #${x.value} `;
      }
    });
    setQuery(_query.trim());
  }, [fieldArray, firstField]);

  useEffect(() => {
    buildQuery();
  }, [buildQuery, firstField, fieldArray]);

  const handleQueryValidation = () => {
    if (query.toLowerCase().includes("#s")) {
      setIsModifiedQueryValid(true);
    } else {
      setIsModifiedQueryValid(false);
    }
  };

  const handlePreSearchPrep = () => {
    // Phase 1
    // Check if searched numbers are available in search history
    // get only the numbers from fieldArray and see if it matches with search history
    const _query = query.replace(/#/g, "");
    const queryFields = _query.split(" ");
    const numberFields = _query
      .split(" ")
      .filter((_, i) => i % 2 === 0)
      .map((x) => Number(x));

    //Check 1
    if (numberFields.length > searchHistory?.length) {
      setIsModifiedQueryValid(false);
      return;
    }

    //Check 2: if each numberField denotes to index+1 of searchHstory as per the table
    for (let i = 0; i < numberFields.length; i++) {
      if (!searchHistory[numberFields[i] - 1]) {
        setIsModifiedQueryValid(false);
        return;
      }
    }

    // Phase 2
    // Begin search preparation

    setIsLoading(true);

    // Step 1: get fq_resource & fq_subjects from url
    // so we pass it through URLSearchParams and loop through it
    const fqResource: string[] = [];
    const fqSubjects: string[] = [];

    numberFields.forEach((x, i) => {
      const searchParams = new URLSearchParams(
        searchHistory[x - 1]?.search_url
      );

      for (let p of searchParams) {
        if (["fq__resource_type"].includes(p[0])) {
          fqResource.push(p[1]);
        }
        if (["fq__subjects_id_l2"].includes(p[0])) {
          fqSubjects.push(p[1]);
        }
      }
    });

    // Step 2: cleanup fq_resource & fq_subjects
    const fq__resource_type = fqResource.join(" OR ").replace(/[()]/g, "");

    const fq__subjects_id_l2 = fqSubjects.join(" OR ").replace(/[()]/g, "");

    // Step 3: get searchTerms
    let keywords: string[] = queryFields;
    for (let i = 0; i < queryFields.length; i++) {
      if (!["AND", "OR"].includes(queryFields[i])) {
        const indexFromQueryNumber = Number(queryFields[i]) - 1;
        keywords[i] = searchHistory[indexFromQueryNumber]?.SearchTerms;
      }
    }

    const keywordString = keywords.toString().split(",").join(" ");
    let modifiedSearchTerm = keywordString.split(/:(.*)/);
    const queryObj: any = {
      customerid: customerId,
      sessionid: sessionKey,
      sort: "dateofpublication desc",
      fq__resource_type: `(${fq__resource_type})`,
      fq__subjects_id_l2: `(${fq__subjects_id_l2})`,
      rows: 15,
      op_mode: "and",
      datsearchtype_id: "1",
    };
    queryObj[modifiedSearchTerm[0]] = modifiedSearchTerm[1];
    // //Finally: proceed to search
    handleSearch(queryObj, keywordString);
  };

  const handleSearch = async (queryObj: QueryObj, dirtyString: string) => {
    try {
      dispatch(clearSearchTerm());

      const keywordString = stringSanitizer(dirtyString);
      if (!keywordString) {
        return;
      }

      let fullTextQuery = await queryConstructor({
        ...queryObj,
        // fq__fulltext: true,
        "fq__(fulltext": `true OR acl_group=(${customerId}))`,
      });
      fullTextQuery += "&" + keywordString;

      const fullTextData = await basicSearchMain(fullTextQuery);

      // To Register the current new search
      const registerNewSearch = await queryConstructor({
        ...queryObj,
        logsearchhistory: true,
      });
      await basicSearchMain(registerNewSearch);

      const allQuery = await queryConstructor({
        ...queryObj,
      });

      const allText = await basicSearchForCounts(allQuery);
      const fulltext = await basicSearchForCounts(fullTextQuery);

      dispatch(
        updateQuery({
          fullTextQuery: fullTextQuery,
          allTextQuery: allQuery,
        })
      );

      const searchedData = {
        key: "search-term",
        value: keywordString.trim(),
      };

      dispatch(
        updateQueries({
          key: "searchTerm",
          value: keywordString,
        })
      );

      dispatch(updateSort("dateofpublication desc"));
      dispatch(clearAllFiltersValue());
      dispatch(updateFullText(true));
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearPublicationFilter());
      dispatch(updateAllFilter(searchedData));
      dispatch(updateSearchTerm(keywordString.trim()));
      dispatch(updateNewSearchStatus(true));
      dispatch(updatePage(1));
      dispatch(updateRow(15));
      dispatch(onSearch());

      setIsLoading(false);

      if (fullTextData?.docs) {
        navigate(`/basicSearchScreen?searchterm=${keywordString}`, {
          state: {
            searchTerm: keywordString,
            fullTextQuery: fullTextQuery,
            allQuery: allQuery,
            allCount: allText?.hits,
            fullCount: fulltext?.hits,
          },
        });
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSetSearch = async () => {
    if (query === "") {
      notify("warning", "Please enter a query");
      return;
    }

    if (!query.toLowerCase().includes("#s")) {
      notify(
        "error",
        "The input format for the search set number is incorrect"
      );
      return;
    }
    setIsLoading(true);

    if (!isModifiedQueryValid) {
      notify(
        "error",
        "The input format for the search set number is incorrect"
      );
      return;
    }

    if (!validateSearchTerm(query)) {
      setIsModifiedQueryValid(false);
      setIsLoading(false);
      setError("Enter Valid Set Number");
      return;
    }

    dispatch(clearSearchTerm());
    dispatch(clearAllFilters());
    dispatch(clearAllFiltersValue());
    dispatch(clearPublicationFilter());
    setError("");

    dispatch(
      updateQueries({
        key: "searchTerm",
        value: query,
      })
    );

    dispatch(
      updateAllFilter({
        key: "searchTerm",
        value: query,
      })
    );

    const queryObj: any = {
      set_number_in: query,
      sort: "dateofpublication desc",
    };

    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      Object.assign(queryObj, {
        fq__acl_group: customerData?.consortia_filter,
      });
    }

    let finalQuery = await queryConstructor(queryObj);
    //setSearch(finalQuery, "all", "count", null, true);
    const logCount = await setSearch(
      finalQuery,
      "all",
      "count",
      "false",
      true,
      "all",
      ""
    );

    // const response = await setSearch(finalQuery, "fulltext", null, null, false);
    const response = await setSearch(
      finalQuery,
      "fulltext",
      "records",
      "false",
      false,
      "all",
      ""
    );
    dispatch(updateSearchResults(response));
    dispatch(updateSetInNumber(query));
    dispatch(updateSetSearchQuery(finalQuery));
    dispatch(updateSetSearchStatus(true));
    // const allText = await setSearch(finalQuery, "all", "count");
    // const fullText = await setSearch(finalQuery, "fulltext", "count");
    setIsLoading(false);

    if (!response?.docs || !response) {
      notify("error", "No result found");
      setIsLoading(false);
      setIsModifiedQueryValid(false);
      return;
    }
    setIsModifiedQueryValid(true);

    dispatch(updateSubjectFilterLoader(true));
    dispatch(updateAuthorFiltersLoader(true));
    dispatch(updateOtherFiltersLoader(true));

    if (response?.docs) {
      navigate("/basicSearchScreen?searchterm=" + query, {
        state: {
          // allCount: allText?.hits,
          // fullCount: fullText?.hits,
          allCount: logCount?.hits,
          fullCount: response?.hits,
        },
      });
    }
    await linkToDisplay(response?.docs, customerData, dispatch);
    logBasicSearchUsageData(96);

    //JsonFacets
    const subjectFacetData = await setSearch(
      finalQuery,
      "fulltext",
      "all",
      // "records",
      "true",
      false,
      "all",
      1
    );
    dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
    dispatch(updateSubjectFilterLoader(false));
    const authorFacetData = await setSearch(
      finalQuery,
      "fulltext",
      "all",
      //"records",
      "true",
      false,
      "all",
      2
    );
    dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
    dispatch(updateAuthorFiltersLoader(false));
    const otherFacetData = await setSearch(
      finalQuery,
      "fulltext",
      "all",
      //"records",
      "true",
      false,
      "all",
      3
    );
    dispatch(updateAllFacets(otherFacetData?.jsonfacets));
    dispatch(updateOtherFiltersLoader(false));
  };

  function validateSearchTerm(keyword: string): boolean {
    try {
      let keys = keyword.split(" ");
      const filterKeys = keys.filter((str) => str.match(/#s/i));

      const filteredNumbers: any = filterKeys
        .map((item) => {
          const match = item.match(/\d+/);
          return match ? parseInt(match[0], 10) : null;
        })
        .filter((number) => number !== null);

      if (!filteredNumbers) {
        return false;
      }

      let x = [];
      for (let i = 0; i < searchHistory.length; i++) {
        if (filteredNumbers[i] && filteredNumbers[i] > searchHistory.length) {
          x.push(false);
        } else {
          x.push(true);
        }
      }

      if (!x.every((e) => e === true)) {
        return false;
      }

      if (Array.isArray(searchHistory)) {
        const toCompare = searchHistory
          .filter((obj) => obj.is_delete === false)
          .map((obj) => obj?.row_number);
        for (let value of filteredNumbers) {
          if (!toCompare.includes(value)) {
            return false;
          }
        }
      }
      // default
      return true;
    } catch (err) {
      return true;
    }
  }

  function openSetSearchInfoModal() {
    dispatch(
      setModal({
        modalType: "SETSEARCH_INFO_MODEL",
        modalProps: {
          isOpen: true,
        },
      })
    );
  }
  return (
    <Stack sx={sx.historyTable}>
      {/* <Text sx={sx.heading}>Query</Text> */}
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          gap: { xs: 0, sm: 0, md: 2 },
        }}
      >
        <Box sx={sx.card}>
          <Text sx={sx.heading}>Query Editor</Text>

          <Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: { xs: "space-between", sm: "flex-start" },
                alignItems: "center",
              }}
            >
              <Text>Query</Text>
              <Stack sx={{ display: { xs: "flex", sm: "none" } }}>
                <IconButton size="medium" onClick={openSetSearchInfoModal}>
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Box>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              gap={2}
            >
              <TextField
                placeholder="#S1 AND #S2 OR Artificial Intelligence"
                sx={{
                  width: { xs: "100%", sm: "80%" },
                  height: "52px",
                }}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onBlur={handleQueryValidation}
                error={!isModifiedQueryValid}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isValidatingQuery && <CircularProgress size={20} />}
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSetSearch();
                  }
                }}
              />
              <Button
                sx={{
                  height: "52px",
                  px: "24px",
                  minWidth: { xs: "100%", sm: "100px" },
                }}
                variant="contained"
                onClick={handleSetSearch}
              >
                {isLoading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#fff",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                ) : (
                  "Search"
                )}
              </Button>
            </Stack>
            {error && (
              <Text
                style={{
                  fontSize: "12px",
                  color: Colors.red500,
                  padding: 0,
                  marginBottom: 0,
                  marginTop: 10,
                }}
              >
                {error}
              </Text>
            )}
            {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              gap: 2,
              marginTop: 10,
              fontStyle: "italic",
              color: "gray",
            }}
          >
            <span style={{ fontStyle: "normal", fontWeight: "500" }}>
              Examples for using set numbers in Query editor{" "}
              <CustomWidthTooltip
                title={`#S2 AND #S3 OR #S4 \n (#S2 AND #S3) OR (#S4 AND #S6) \n (#S1 OR #S4) AND "Digital Library" \n #S4 NOT clone`}
              >
                <IconButton size="small">
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </CustomWidthTooltip>
            </span>
          </div> */}
          </Stack>
        </Box>
        <Stack
          direction="column"
          alignItems="left"
          sx={{
            display: { xs: "none", sm: "flex" },
            marginLeft: { xs: "10px", sm: "10px", md: "0px" },
            paddingTop: { xs: "0px", sm: "0px", md: "28px" },
            paddingRight: { xs: "15px", sm: "160px" },
          }}
        >
          <Typography>
            Examples for using set numbers in Query editor
          </Typography>
          <Typography sx={sx.example}>#S2 AND #S3 OR #S4</Typography>
          <Typography sx={sx.example}>
            (#S2 AND #S3) OR (#S4 AND #S6)
          </Typography>
          <Typography sx={sx.example}>
            {" "}
            (#S1 OR #S4) AND "Digital Library"
          </Typography>
          <Typography sx={sx.example}>#S4 NOT clone</Typography>
        </Stack>
      </Card>
    </Stack>
  );
};

export const CustomWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    whiteSpace: "pre-line",
  },
});
export default QueryBuilder;
