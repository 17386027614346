import { useEffect, useRef, useState } from "react";
import { GetFolderArticlesDoc } from "../../../types/GetMyFolders.types";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import RightDrawer from "../../../components/rightDrawer/rightDrawer";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updateAllFilter,
  updateAuthor,
  updateConfrence,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateSpeaker,
  updateUniversityName,
} from "../../../store/slice/filterSlice";
import {
  clearSearchTerm,
  updatePage,
  updateRow,
} from "../../../store/slice/searchSlice";
import { onSearch as search } from "../../Search/onSearch";
import SearchResultCard from "../../basicSearchScreen/SearchResultCard";
import { usagelogApi } from "../../../api/usageReportApi";
import { useSelector } from "react-redux";
import {
  clearAuthorSearchTerm,
  updateAuthorPage,
  updateAuthorRow,
} from "../../../store/slice/authorFinderSlice";
import { clearQuery } from "../../../store/slice/queryForCountSlice";
import { onAuthorResult as authorsearch } from "../../../screens/AuthorFinder/onAuthorResult";
import { AuthorFinderObj } from "../../../types/interface";
import { updateBrowserPage } from "../../../store/slice/browseJournal";
import { dataTypes } from "../../../utils/constants";
import { journalLock } from "../../../api/browseJournal";
import objectToQueryString from "../../browseJournal/objToQuery";
import {
  LinkResolverType,
  getBulkLinkRecords,
} from "../../../api/linkResolverApi";
import { basicSearchAuthorEmail } from "../../../api/Search/BasicSearch.api";

//To be modified
interface Proptype {
  data: GetFolderArticlesDoc;
  key?: number | string;
  getMarkedResult: (data: any, id: string) => void;
  markedArticlesid?: string[];
  folderId?: number;
  articles: any;
}
const FolderDetailCard = (props: Proptype) => {
  const { data, getMarkedResult, markedArticlesid, folderId, articles } = props;
  const [expanded, setExpanded] = useState(false);
  const [linkToDisplay, setLinkToDisplay] = useState<LinkResolverType[] | null>(
    null
  );
  const customerId = useAppSelector(
    (state) => state.customer.customerDetails?.informaticscustomer_id
  );

  const consortia_virtualid = useAppSelector(
    (state) => state.customer.customerDetails?.consortia_virtualid
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );
  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );
  const triggerEvent = useRef(0);
  const [authorEmailData, setAuthorEmailData] = useState([]);
  const currentYear: number = new Date().getFullYear();

  const is_ONOSCustomer = useSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  let dataClone = data?.authors
    ? structuredClone({
        ...data,
        author_details: data.authors,
      })
    : data?.speakers
    ? structuredClone({
        ...data,
        author_details: data?.speakers,
      })
    : structuredClone(data);

  useEffect(() => {
    handleAuthorEmailData();
  }, []);
  const selectedFullText = useAppSelector(
    (state) => state.searchReducer.fullText
  );
  const selectedFilters = useAppSelector((state) => state.filterReducer);
  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "Consortia Collections"
      ? 2
      : selectedFilters.collectionValue === "My Library Collections"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logSearchResultCardUsageData = (usageActionID: any, details?: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isArticleONOS = details?.is_onos_resource;
    const isONOS = is_ONOSCustomer && isArticleONOS ? 1 : 0;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };

  async function fetchLinkDetails(arr: any) {
    if (!Array.isArray(arr)) return;
    let formData = new FormData();

    let x = arr?.map((obj: any) => {
      let object: any = {
        customer_id: customerId,
        article_id: obj?.article_id,
        consortia_virtualid: consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }
      return object;
    });

    let formApiData = {
      data: x,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      setLinkToDisplay(response.data);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchLinkDetails(articles);
        if (triggerEvent.current) findAuthor();
      } catch (error) {
        console.error("Error fetching link details:", error);
      }
    };

    fetchData(); // Call the async function
    if (triggerEvent.current) findAuthor();
  }, [triggerEvent.current]);

  function getButtonType(article_id: string) {
    try {
      if (!article_id) return;
      if (!linkToDisplay) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === article_id) {
          return obj?.button_to_display;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function handleResource(data: any) {
    // Make new search, claar all filters

    if (
      data?.data_type !== dataTypes.journalArticle ||
      data?.data_type !== dataTypes.journals
    ) {
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearPublicationFilter());
      dispatch(clearAllFiltersValue());
    }
    if (
      data?.data_type === dataTypes.journalArticle ||
      data?.data_type === dataTypes.journals
    ) {
      const item = data;
      // Get acces lock type
      let accessLockType;
      if (item?.openaccess_type !== "Open Access" || !item?.openaccess_type) {
        const payloadData = {
          customer_id: sessionStorage.getItem("informaticscustomer_id"),
          resource_id: item?.resourcemaster_id,
          access_type: 1,
        };
        let journalLockRes = await journalLock(
          "?" + objectToQueryString(payloadData)
        );
        accessLockType = Array.isArray(journalLockRes?.data?.data)
          ? journalLockRes?.data?.data[0]
          : {};
      }
      dispatch(updateBrowserPage(1));
      const yearData = Array.isArray(data?.yearfrom)
        ? data?.yearfrom[0]
        : data?.yearfrom;

      if (currentYear === yearData) {
        logSearchResultCardUsageData(90, data);
      } else {
        logSearchResultCardUsageData(87, data);
      }
      logSearchResultCardUsageData(92, data);
      logSearchResultCardUsageData(91, data);
      navigate(`/BrowseJournalTableSearch/BrowseJournalSearch`, {
        state: {
          item: item,
          from: "basicSearch",
        },
      });
    } else if (data?.data_type === dataTypes.conferenceProceedings) {
      dispatch(updateConfrence([data?.conference_name]));
      dispatch(
        updateAllFilter({
          key: "conference_name",
          value: data?.conference_name,
        })
      );
      let res = dispatch(search());
      logSearchResultCardUsageData(115, data);
      logSearchResultCardUsageData(11, data);
      if (selectedFullText == true) {
        logSearchResultCardUsageData(29, data);
      } else {
        logSearchResultCardUsageData(28, data);
      }
      !!res && navigate("/basicSearchScreen");
    } else if (data?.data_type === dataTypes.dissertationThesis) {
      dispatch(updateUniversityName([data?.university_name]));
      dispatch(
        updateAllFilter({
          key: "university_name",
          value: data?.university_name,
        })
      );
      let res = dispatch(search());
      logSearchResultCardUsageData(23, data);
      logSearchResultCardUsageData(11, data);
      if (selectedFullText == true) {
        logSearchResultCardUsageData(29, data);
      } else {
        logSearchResultCardUsageData(28, data);
      }
      !!res && navigate("/basicSearchScreen");
    } else if (data?.data_type === dataTypes.audioVideo) {
      dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      let res = dispatch(search());
      logSearchResultCardUsageData(25, data);
      logSearchResultCardUsageData(11, data);
      if (selectedFullText == true) {
        logSearchResultCardUsageData(29, data);
      } else {
        logSearchResultCardUsageData(28, data);
      }
      !!res && navigate("/basicSearchScreen");
    } else if (data?.data_type === dataTypes.preprint) {
      if (data?.publisher_name?.length)
        dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      let res = dispatch(search());
      logSearchResultCardUsageData(24, data);
      logSearchResultCardUsageData(11, data);
      if (selectedFullText == true) {
        logSearchResultCardUsageData(29, data);
      } else {
        logSearchResultCardUsageData(28, data);
      }
      !!res && navigate("/basicSearchScreen");
    } else if (data.data_type === dataTypes.magazine) {
      dispatch(updatePublisher([data?.resourcemaster_id]));
      dispatch(
        updateAllFilter({
          key: "magazine",
          value: data?.resource_name_tk,
        })
      );
      let res = dispatch(search());
      logSearchResultCardUsageData(116, data);
      logSearchResultCardUsageData(11, data);
      if (selectedFullText == true) {
        logSearchResultCardUsageData(29, data);
      } else {
        logSearchResultCardUsageData(28, data);
      }
      !!res && navigate("/basicSearchScreen");
    }
  }

  const handleDawer = (data: any) => {
    logSearchResultCardUsageData(30, data);
  };

  async function handleAuthor(authorName: string, data: any) {
    //handleDawer && handleDawer(data);
    dispatch(clearSearchTerm());
    dispatch(clearAuthorSearchTerm());
    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(clearQuery());
    dispatch(clearAllFiltersValue());

    dispatch(updatePage(1));
    dispatch(updateRow(15));

    dispatch(updateAuthorPage(1));
    dispatch(updateAuthorRow(15));

    if (
      data.data_type === dataTypes.journalArticle ||
      data.data_type === dataTypes.journals ||
      data.data_type === dataTypes.conferenceProceedings ||
      data.data_type === dataTypes.magazine ||
      data.data_type === dataTypes.preprint ||
      data.data_type === dataTypes.bookSeries
    ) {
      logSearchResultCardUsageData(19, data);
    }

    if (data.data_type === dataTypes.dissertationThesis) {
      logSearchResultCardUsageData(19, data);
      logSearchResultCardUsageData(20, data);
    }

    if (data.data_type === dataTypes.audioVideo) {
      logSearchResultCardUsageData(22, data);
    }

    if (data?.authors?.length) {
      dispatch(updateAuthor([authorName]));

      let appliedFilter = {
        key: "author",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (data?.speakers?.length) {
      dispatch(updateSpeaker([authorName]));
      let appliedFilter = {
        key: "speaker",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (data?.guide_name === authorName) {
      dispatch(updateResearch([authorName]));
      dispatch(
        updateAllFilter({
          key: "research",
          value: authorName,
        })
      );
    } else if (data?.researcher_tk == authorName) {
      dispatch(updateResearcher([authorName]));
      dispatch(
        updateAllFilter({
          key: "researcher",
          value: authorName,
        })
      );
    }

    if (authorReducer.isAuthorResult === true) {
      let res = dispatch(authorsearch());
      !!res && navigate("/authorfindersearch");
    } else if (authorReducer.isAuthorResult === false) {
      let res = dispatch(search());
      !!res && navigate("/basicSearchScreen");
      logSearchResultCardUsageData(11, data);
      if (selectedFullText == true) {
        logSearchResultCardUsageData(29, data);
      } else {
        logSearchResultCardUsageData(28, data);
      }
    }
  }
  async function findAuthor() {
    const data = dispatch(search());
    !!data && navigate("/basicSearchScreen");
  }

  const handleAuthorEmailData = async () => {
    const authorData = await basicSearchAuthorEmail(data?.article_id);
    setAuthorEmailData(authorData);
  };

  return (
    <>
      <SearchResultCard
        data={dataClone}
        handleDawer={handleExpandClick}
        handleAuthor={handleAuthor}
        handleResource={handleResource}
        getMarkedResult={getMarkedResult}
        markedData={markedArticlesid}
        folderId={folderId}
        getButtonType={getButtonType}
        linkToDisplay={linkToDisplay}
      />
      <RightDrawer
        dialogueData={dataClone}
        handleDawer={handleExpandClick}
        state={expanded}
        linkToDisplay={linkToDisplay}
        authorEmailData={authorEmailData}
        setTop={expanded}
      />
    </>
  );
};

export default FolderDetailCard;
