import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Text from "@mui/material/Typography";
// Icons
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// Assets
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import { searchHistoryApi } from "../../api/searchHistoryapi";
import { usagelogApi } from "../../api/usageReportApi";
import logo from "../../assets/img/logo.png";
import { CustomWidthTooltip } from "../../screens/SearchHistory/QueryBuilder";
import { useAppDispatch } from "../../store/hooks";
import { updateIsNavigatingBack } from "../../store/slice/searchSlice";
import { notify } from "../../utils/Notify";
import { throttleFunction } from "../../utils/helper";
import { useMainNav } from "./MainNav.hook";
import { navStyles as sx } from "./Nav.style";
import ProfileMenu from "./ProfileMenu";
import SideDrawer from "./SideDrawer";
import "./style.css";
import api from "../../api/endpoints";
import { MoreVert } from "@mui/icons-material";

const MainNav = () => {
  const {
    navigate,
    drawerRef,
    appBarRef,
    toggleNav,
    anchorEl,
    closeNav,
    customerDetails,
    markedResult,
    open,
    me,
    closeMenu,
    handleDrawerToggle,
    handleClick,
  } = useMainNav();
  const profileData = useSelector((state: any) => state.login);
  const customerData = useSelector(
    (state: any) => state.customer.customerDetails
  );
  const [searchhistoryData, setSearchHistoryData] = useState([]);
  const [tooltipString, setTooltipString] = useState("Search History");

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const is_ONOSCustomer = useSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  const filterReducer = useSelector(
    (state: any) => state.filterReducer?.collectionValue
  );

  const dispatch = useAppDispatch();
  const userId = sessionStorage.getItem("user_id");
  const informaticscustomer_id = sessionStorage.getItem(
    "informaticscustomer_id"
  );

  // const ailoginTocken = customerDetails;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const isONOS =
      is_ONOSCustomer && filterReducer === "ONOSCollections" ? 1 : 0;
    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_id
      ? informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      0
    );
  };

  function AIToolIcon() {
    return (
      <div>
        <AutoAwesomeIcon
          sx={{
            fontSize: 25,
            color: "orange",
            "&:hover": {
              color: "purple",
            },
            marginTop: "8px",
          }}
        />
      </div>
    );
  }

  const fetchAIToken = async () => {
    try {
      const userId = sessionStorage.getItem("user_id");
      const response = await fetch(
        `${api.baseApi}/api/generate_ai_userlogin/generate_ai_userlogin?user_id=${userId}`
      );
      const data = await response.json();
      const aiToken = data?.data?.[0][0]?.user_token;

      if (api.baseApi === "https://betaapi.jgatenext.com") {
        window.open(
          // `https://ai.jgatenext.com/?token=${aiToken}`,
          `http://20.204.168.47:8000/?token=${aiToken}`,
          "_blank",
          "noopener,noreferrer"
        ); // Open in new tab with token
      } else {
        window.open(
          `https://ai.jgatenext.com/?token=${aiToken}`,
          // `http://20.204.168.47:8000/?token=${aiToken}`,
          "_blank",
          "noopener,noreferrer"
        ); // Open in new tab with token
      }
    } catch (error) {
      console.log("Error fetching AI token:", error);
    }
  };

  const HandleHoverSearchHistory = async () => {
    try {
      let res = await searchHistoryApi();
      setSearchHistoryData(res);
      // DF-2264
      // let tempstring = "";
      // res = res?.filter((obj: any) => obj?.is_delete === false);
      // res.map((item: any) => {
      //   tempstring += "S"
      //   tempstring += item?.row_number;
      //   tempstring +=". ";
      //   tempstring += item?.SearchTerms;
      //   tempstring += `(${item?.result_count})`;
      //   tempstring += "-";
      //   tempstring += item?.search_type;
      //   tempstring += "\n";
      // });
      // if (tempstring) setTooltipString(tempstring);
    } catch (error) {
      console.log(error);
    }
  };

  const throttledSearchHistoryHover = throttleFunction(
    HandleHoverSearchHistory,
    5000
  );

  const handleSearchHistory = async () => {
    logBasicSearchUsageData(96);
    const zero = searchhistoryData?.filter(
      (item: any) => !item?.is_delete
    )?.length;
    if (!searchhistoryData || !zero) {
      notify("warning", "Please make some searches to get search history");
    } else {
      navigate("/searchhistory");
    }
  };

  return (
    <>
      <AppBar color="inherit" position="sticky" className="nav" ref={appBarRef}>
        <Toolbar sx={sx.toolbar}>
        <Stack
  direction="row"
  alignItems="center"
  justifyContent="space-between"
  sx={{width:{xs:"100%",sm:"100%",md:"50%"}}}
>
  {/* Hamburger Menu */}
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="start"
    onClick={handleDrawerToggle}
    sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
  >
    {toggleNav ? <CloseIcon /> : <MenuIcon />}
  </IconButton>

  {/* Logo */}
  <Box sx={{ display: { xs: "none", sm: "none", md: "inherit" },mr:1 }}>
    <img
      src={logo}
      alt="logo"
      className="nav-logo"
      onClick={() => {
        logBasicSearchUsageData(8);
        dispatch(updateIsNavigatingBack(false));
        navigate("/initialSearch");
      }}
    />
  </Box>

  {/* Middle Section */}
  <Box
    sx={{
      display: "flex",
      flexDirection: { xs: "column", sm: "row", md: "row" },
      flexGrow: 1, // Ensures it expands to take available space
      minWidth: 0, // Prevents overflow issues
    }}
  >
   <Box
  sx={{
    display: "flex",
    flexDirection: { xs: "row", sm: "row", md: "row" },
    flexGrow: 1,
    minWidth: 0,
    alignItems: {xs:"top",md:"center"},
  }}
>
  <Box
    sx={{
      display: { xs: "flex", sm: "inherit", md: "none" },
      alignSelf: { xs: "top", sm: "center" },
      flexDirection: "column",
      mr:1
    }}
  >
    <img
      src={logo}
      alt="logo"
      className="nav-logo"
      onClick={() => {
        logBasicSearchUsageData(8);
        dispatch(updateIsNavigatingBack(false));
        navigate("/initialSearch");
      }}
    />
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerToggle}
      sx={{ display: { xs: "flex", sm: "none", md: "none" } }}
    >
      {toggleNav ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  </Box>

  {/* Customer Details + Profile Banner / Logo */}
  <Stack
    direction="row"
    alignItems="top"
    sx={{ flexGrow: 1, minWidth: 0, gap: 1 }} // Ensures space between name & banner
  >
    {/* Customer Details */}
    <Box sx={{ flexShrink: 1, minWidth: "auto" ,mt:1 }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Text sx={sx.consortia}>{customerDetails?.consortianame || ""}</Text>
      </Box>
      <Text
        sx={{
          ...sx.name,
          fontSize: { xs: "12px", sm: "20px", md: "22px" },
          color: customerDetails?.consortianame ? "" : "#5856d6",
        }}
      >
        {customerDetails?.domainname || customerDetails?.displayname}
      </Text>
    </Box>

    {/* Profile Banner / Logo */}
    {(profileData1?.banner !== "\\x" &&
      profileData1?.banner != null &&
      profileData1?.banner) ||
    (profileData1?.logo !== null &&
      profileData1?.logo !== "\\x" &&
      profileData1?.logo) ? (
      <Box sx={{ display: "flex", alignItems: "top",pt:.5 }}>
        {profileData1?.banner &&
        profileData1?.banner !== "\\x" &&
        profileData1?.banner != null ? (
          <img src={profileData1?.banner} alt="banner" className="banner-logo" />
        ) : profileData1?.logo ? (
          <img src={profileData1?.logo} alt="logo" className="banner-logo" />
        ) : null}
      </Box>
    ) : null}
  </Stack>
</Box>

  </Box>

  {/* More Options (Always at End) */}
  <IconButton sx={{  display: { xs: "flex", md: "none" }, ml: "auto" }} onClick={handleClick}>
    <MoreVert fontSize="small" />
  </IconButton>
</Stack>

          <Stack
            direction={"row"}
            spacing={1}
            sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
          >
            <Box sx={sx.iconWrapper}>
              {(customerDetails?.informaticscustomer_id == 18 ||
                customerDetails?.informaticscustomer_id == 4896) && (
                <Tooltip title="J-GateNext AI">
                  <IconButton
                    onClick={() => {
                      fetchAIToken();
                    }}
                    //href="http://20.198.68.235:8501"
                    // href={`https://ai.jgatenext.com/?token=${isAIToken}`}
                    // target="_blank"
                    // rel="noopener noreferrer"
                  >
                    <AIToolIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* <CustomTooltip title="AI">
                <a
                  href="http://20.198.68.235:8501"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={playStore}
                    alt="img"
                    style={{
                      width: "30px",
                      height: "30px",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  />
                </a>
              </CustomTooltip> */}
              <Tooltip title="Search">
                <IconButton
                  onClick={() => {
                    logBasicSearchUsageData(8);
                    dispatch(updateIsNavigatingBack(false));
                    navigate("/initialSearch");
                  }}
                >
                  <SearchOutlinedIcon />
                </IconButton>
              </Tooltip>
              <CustomWidthTooltip title={`${tooltipString}`}>
                <IconButton
                  onClick={handleSearchHistory}
                  onMouseEnter={throttledSearchHistoryHover}
                >
                  <AccessTimeOutlinedIcon />
                </IconButton>
              </CustomWidthTooltip>
              <Tooltip title="Bookmarked Articles">
                <IconButton
                  onClick={() => navigate("/marked-result")}
                  sx={{ fontSize: "14px" }}
                >
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: markedResult.length ? sx.markedView : "",
                    }}
                  />{" "}
                  {markedResult.length ? `(${markedResult?.length})` : ""}
                </IconButton>
              </Tooltip>
            </Box>

            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{
                cursor: "pointer",
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              }}
              onClick={handleClick}
            >
              <Text>
                Hi {me?.firstname} {me?.lastname}
              </Text>
              <Avatar
                sx={{
                  fontSize: "12px",
                  ml: 2,
                  bgcolor: "primary.main",
                  color: "#FFF",
                }}
                alt={me?.firstname}
                src={me?.profileuserimagepath}
              >
                {Array.isArray(me?.firstname) ? me?.firstname[0] : ""}
                {Array.isArray(me?.lastname) ? me?.lastname[0] : ""}
              </Avatar>
              <ArrowDropDownOutlinedIcon />
            </Stack>
          </Stack>

          <ProfileMenu
            anchorEl={anchorEl}
            open={open}
            closeMenu={closeMenu}
            profileDetail={me}
          />
        </Toolbar>
      </AppBar>

      {/* Nav Drawer */}
      <SideDrawer
        toggleNav={toggleNav}
        drawerRef={drawerRef}
        closeNav={closeNav}
      />
    </>
  );
};

export default MainNav;
