import { toast } from "react-toastify";
import { GetMyPreferenceData } from "../types/GetMySubjects.types";

export function getParamsByName(name: string) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(name);
}

// Sort author
export function customSort(a: any, b: any) {
  try {
    return Number(a.split("$")[2]) - Number(b.split("$")[2]);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export function sortAuthor(arr: string[]): string[] {
  try {
    let x = [...arr];
    let sortedArray = x.sort(customSort);
    // let filteredString = sortedArray?.map((str) =>
    //   str.replace(/[^a-zA-Z ]/g, "")
    // );
    let sliceedStr = sortedArray?.map((str) => str.split("$")[0]);
    // let removeSplChar = sliceedStr?.map((str) => str.replace(/[^a-zA-Z ]/g, ""));
    return sliceedStr;
  } catch (e) {
    console.log(e);
    return arr;
  }
}

// sort authodetails in json format
export function sortAuthorDetail(arr: any): string[] {
  try {
    console.log("arr",arr)
    if (!Array.isArray(arr)) return arr;

    let data = arr?.map((item) => JSON.parse(item));

    let sortData = data.sort((a: any, b: any) => {

      const typeA = a?.authortype_id === 2 ? 0 : 1;
      const typeB = b?.authortype_id === 2 ? 0 : 1;

      if (typeA !== typeB) {
        return typeA - typeB; // Prioritize authortype_id = 2
      }


      let valA = a?.sortorder;
      let valB = b?.sortorder;

      if (valA > valB) {
        return 1;
      }
      if (valA < valB) {
        return -1;
      }
      return 0;
    });

    return sortData;
  } catch (err) {
    // console.log(err);
    return [];
  }
}

// sort author by author role, ticket 1065
export function sortByAuthorRole(arr: any, isThesis: boolean): string[] {
  try {
    if (!isThesis) return arr;
    if (!Array.isArray(arr)) return arr;

    let sortData = arr.sort((a: any, b: any) => {
      let valA = a?.datauthorrole_id;
      let valB = b?.datauthorrole_id;

      if (valA > valB) {
        return 1;
      }
      if (valA < valB) {
        return -1;
      }
      return 0;
    });

    return sortData;
  } catch (err) {
    // console.log(err);
    return [];
  }
}

export function sortAuthorAffliliation(arr: string[]): string[] {
  try {
    let data = [...arr];
    let sortedArray = data.sort(customSort);
    return sortedArray;
  } catch (e) {
    console.log(e);
    return arr;
  }
}

export function goToTop() {
  // scroll to the top of the page when apply btn clicked
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function goToDown() {
  // scroll to the top of the page when apply btn clicked
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
}
type ReturnType = "GreenLock" | "RedOpenLock" | "RedClosedLock" | null;
export function accessTypeButtons(data: any, customerId: string): ReturnType {
  if (data?.fulltext === true || data?.full) {
    return "GreenLock";
  } else if (
    Array.isArray(data?.acl_group) &&
    data?.acl_group?.includes(customerId)
  ) {
    return "RedOpenLock";
  } else if (
    (Array.isArray(data?.acl_group) &&
      !data?.acl_group?.includes(customerId)) ||
    !data?.fulltext
  ) {
    return "RedClosedLock";
  }
  return null;
}

// Alphabetical sort
export const alphabeticalSort = (arr: any, key: string) => {
  try {
    if (!Array.isArray(arr) || !key) {
      return;
    }

    let sortedData: any = arr.sort((a: any, b: any) => {
      let valA = a[key].toUpperCase();
      let valB = b[key].toUpperCase();

      if (valA > valB) {
        return 1;
      } else if (valA < valB) {
        return -1;
      } else return 0;
    });
    return sortedData;
  } catch (err) {
    console.log(err);
    return [];
  }
};

// Checking the preferences added by user
export function isPreferenceAdded(myPreference: GetMyPreferenceData): boolean {
  try {
    let subjectCount, dataTypecount;

    // filtering subject
    let filteredSubjectData = myPreference.Subjects?.map(
      ({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
    ).flat();

    //filtering doctypes
    let filterDocTypes = myPreference?.DataTypes.filter(
      ({ is_favorite }) => is_favorite
    )?.map(({ datatype_id }) => datatype_id);

    subjectCount = filteredSubjectData.length;
    dataTypecount = filterDocTypes?.filter((ele: any) => ele !== 2)?.length;

    // if (subjectCount && dataTypecount) {
    //   return true;
    // }

    // ONLY DATA TYPES SHOULD BE MANDATORY NOT SUBJECT
    if (dataTypecount) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export function isPreferenceAddedSubjectAndDataType(
  myPreference: GetMyPreferenceData
): boolean {
  try {
    let subjectCount, dataTypecount;

    // filtering subject
    let filteredSubjectData = myPreference.Subjects?.map(
      ({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
    ).flat();

    //filtering doctypes
    let filterDocTypes = myPreference?.DataTypes.filter(
      ({ is_favorite }) => is_favorite
    )?.map(({ datatype_id }) => datatype_id);

    subjectCount = filteredSubjectData.length;
    dataTypecount = filterDocTypes?.filter((ele: any) => ele !== 2)?.length;
    if (subjectCount && dataTypecount) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

// Validate the type of Array

export function isStringOfArray(arr: any): boolean {
  try {
    if (!Array.isArray(arr)) return false;

    let x = arr.every((item) => item.search(/{/g));
    return x;
  } catch (err) {
    console.log("Error occour in isStringOfArray");
    return false;
  }
}

export function toTitleCase(str: string) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const redirectLink = (url: string,event:any): void => {
  
  window.open(url, "_blank");
  event.preventDefault();
};

export function extractMarkedWords(text: string): string[] {
  const regex = /<mark\s*>(.*?)<\/mark\s*>/g;
  const matches: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = regex.exec(text)) !== null) {
    matches.push(match[1]);
  }

  return matches;
}

export const showIsProfileToast = (message: string) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export function valueExists(value: string, sortData: any): boolean {
   return sortData?.find((item: any) => item.val === value) !== undefined;
}

export function valueExistsBrowse(metric: string, filterMetrics: { [key: string]: number }[]): boolean {
  return filterMetrics?.some((item) => Object.keys(item)[0] === metric) ?? false;
}


export function titleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    ?.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function removeSpecialCharacters(str: string) {
  // Regular expression to match special characters
  var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]+/g;

  // Replace special characters with an empty string
  var result = str.replace(regex, "");

  return result;
}

export function checkDuplicateFilter(
  data: any[],
  valueToCheck: string
): boolean {
  const isExist = data.some((obj) => obj?.value === valueToCheck);
  return isExist;
}

export function formatLatexEquation(equation: string): string {
  // Remove \( and \) at the start or end if present
  equation = equation.replace(/^\s*\\\(\s*/, "").replace(/\s*\\\)\s*$/, "");

  // Replace parentheses around fractions with \left( and \right)
  equation = equation.replace(
    /\((\\frac{[^}]*}{[^}]*)\)/g,
    "\\left($1\\right)"
  );

  return equation;
}

export function getQueryParams(url: string) {
  const params = new URLSearchParams(url);
  const queryParams: any = {};

  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}

export function removedQuotation(val: string): string {
  // let str = val.slice(0, val.indexOf('"')) + val.slice(val.indexOf('"') + 1, val.lastIndexOf('"')) + val.slice(val.lastIndexOf('"') + 1);
  let str = val;

  if (str.startsWith('"')) {
    str = str.slice(1);
  }

  if (str.endsWith('"')) {
    str = str.slice(0, -1);
  }

  return str;
}


export function removeOperatorFromEnd(val: string) {
  let value = val;
  let last3Chars = value.slice(-3);

// Check if the last 3 characters are an operator
if (last3Chars.match(/\b(AND|OR|NOT)\b/)) {
  // Remove the last occurrence of the operator
  value = val.slice(0, -3).trim();
} 
return value;
}

export const throttleFunction = (func:any, delay:any) => {
  let isThrottling = false;

  return async (...args:any) => {
      if (!isThrottling) {
          isThrottling = true;

          const result = await func(...args); // Call and await the async function

          setTimeout(() => {
              isThrottling = false; // Reset throttle state after the delay
          }, delay);

          return result; // Return the result of the async function
      } else {
          console.log('Throttled, try again later.');
          return null; // Return null or handle when throttled
      }
  };
};

export const sortTags = (allTags:any[]) =>{

  const sortOrder = [
    "All",
    "Journal Articles",
    "Conference Papers",
    "Theses & Dissertation",
    "Audio-visual lectures",
    "Books",
    "Datasets",
    "Patents",
    "Library Catalogue",
    "IR/DSPACE",
  ];

  return allTags.sort(
    (a, b) => sortOrder.indexOf(a.accesstype) - sortOrder.indexOf(b.accesstype)
  );
}

