import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { hideModal } from "../../../store/slice/modalSlice";
import { useDispatch } from "react-redux";

interface PropType {
  isOpen: boolean;
}

export default function PreferenceInfoModel(props: PropType) {
  const { isOpen } = props;
  const dispatch = useDispatch();
  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(hideModal())}
      maxWidth={false}
      // sx={{ width: { xs: "100%", sm: "400px", md: "50%", lg: "50%" } }}
      //maxWidth={sm:"small", lg::medium}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //minWidth: { xs: "100%", sm: "400px", md: "550px", lg: "900px" },
          //width: "400px",
        }}
      >
        <div>
          <Typography
            variant="h6"
            fontWeight={"bold"}
            // fontSize="30px"
            fontFamily="Lora"
            sx={{
              fontSize: {
                xs: "22px", // For extra small screens
                sm: "22px", // For small screens
                md: "22px", // For medium screens and above
                lg: "22px",
              },
            }}
          >
            Personalise your search
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent sx={{ mt: "10px" }}>
        {/* <Typography sx={{ fontSize: "20px" }}> */}
        <Typography
          sx={{
            fontSize: {
              xs: "18px",
              // sm: "25px",
              // md: "27px",
              // lg: "28px",

              sm: "18px",
              md: "18px",
              lg: "18px",
            },
          }}
        >
          <span style={{ color: "purple" }}>Personalise</span> your search by
          enabling and configuring
        </Typography>

        {/* <Typography sx={{ fontSize: "20px" }}> */}
        <Typography
          sx={{
            // fontSize: {
            //   xs: "18px",
            //   sm: "25px",
            //   md: "27px",
            //   lg: "28px",
            // },
            fontSize: "18px",
          }}
        >
          your <span style={{ color: "purple" }}>Preferences</span> by:
        </Typography>
        {/* <Typography sx={{ fontSize: "20px" }}> */}
        <Typography
          sx={{
            // fontSize: {
            //   xs: "18px",
            //   sm: "25px",
            //   md: "27px",
            //   lg: "28px",
            // },
            fontSize: "18px",
          }}
        >
          (1) Subject categories; and
        </Typography>
        {/* <Typography sx={{ fontSize: "20px" }}> */}
        <Typography
          sx={{
            // fontSize: {
            //   xs: "18px",
            //   sm: "25px",
            //   md: "27px",
            //   lg: "28px",
            // },
            fontSize: "18px",
          }}
        >
          (2) Document-Type of your choice.
        </Typography>

        {/* <Typography sx={{ fontSize: "20px", marginTop: "15px" }}> */}
        <Typography
          sx={{
            // fontSize: {
            //   xs: "18px",
            //   sm: "25px",
            //   md: "27px",
            //   lg: "28px",
            // },
            fontSize: "18px",
          }}
        >
          To <span style={{ color: "purple" }}>Personalise:</span>
        </Typography>
        {/* <Typography sx={{ fontSize: "20px" }}> */}
        <Typography
          sx={{
            // fontSize: {
            //   xs: "18px",
            //   sm: "25px",
            //   md: "27px",
            //   lg: "28px",
            // },
            fontSize: "18px",
          }}
        >
          <ul style={{ marginLeft: "20px" }}>
            <li>
              <span style={{ textDecoration: "underline" }}>First</span>, create
              your <span style={{ color: "purple" }}>Profile</span>;
            </li>
            <li>
              {/* Next, click <span style={{ color: "purple" }}>Preferences</span>{" "}
              in your Profile menu. */}
              <span style={{ textDecoration: "underline" }}>Next</span>, click{" "}
              <span style={{ color: "purple" }}>Preferences</span> in your
              Profile menu.
            </li>
          </ul>
        </Typography>
        {/* <Typography sx={{ fontSize: "20px", marginTop: "15px" }}> */}
        <Typography
          sx={{
            // fontSize: {
            //   xs: "18px",
            //   sm: "25px",
            //   md: "27px",
            //   lg: "28px",
            // },
            fontSize: "18px",
            marginTop: "15px",
          }}
        >
          Once you create your <span style={{ color: "purple" }}>Profile</span>,
          it will appear on the the top-right of the screen followed by
        </Typography>
        {/* <Typography>
          the top-right of the screen followed by a small purple
        </Typography> */}
        {/* <Typography sx={{ fontSize: "20px" }}> */}
        <Typography
          sx={{
            // fontSize: {
            //   xs: "18px",
            //   sm: "25px",
            //   md: "27px",
            //   lg: "28px",
            // },
            fontSize: "18px",
          }}
        >
          a small purple circle with drop-down arrow for viewing the menus.
        </Typography>

        {/* <Stack direction={"row"} gap={1} mt={"20px"}>
            <Button
              variant="contained"
              sx={{ minWidth: 100 }}
              onClick={() => dispatch(hideModal())}
            >
              OK
            </Button>
            <Button
              sx={{ minWidth: 150, color: "#111928" }}
              onClick={() => dispatch(hideModal())}
            >
              Cancel
            </Button>
          </Stack> */}
      </DialogContent>
    </Dialog>
  );
}
