import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  BoxProps,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { profile_ChangePasssword } from "../api/userApi";
import logo from "../assets/img/logo.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { notify } from "../utils/Notify";

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        // width: 450,
        width: { xs: "95%" },
        textAlign: "center",
        ...sx,
      }}
      {...other}
    />
  );
}

const ProfileChangePassword = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const windowHeight = window.innerHeight;

  // const minLengthCheck = /^.{6,}$/;
  // const lowerCaseCheck = /^(?=.*[a-z])/;
  // const upperCaseCheck = /^(?=.*[A-Z])/;
  // const specialCharacterCheck = /^(?=.*[\W_])/;
  // const numberCheck = /^(?=.*\d)/;

  const whitespaceCheck = /^(?!.*\s)/;

  const handleChangePassowrd = async (event: any) => {
    event.preventDefault();
    try {
      setErrorFlag(false);

      if (newPassword !== confirmPassword) {
        setErrorFlag(true);
        console.log("Passwords do not match");
        return;
      }
      if (oldPassword === newPassword) {
        setErrorFlag(true);
        setErrorMessage("New password should be different from old password");
        return;
      }

      switch (false) {
        case whitespaceCheck.test(newPassword) ||
          whitespaceCheck.test(confirmPassword):
          setErrorFlag(true);
          setErrorMessage("Password must not have whitespace");
          return;
      }

      const data = await profile_ChangePasssword({
        user_id: user_id,
        old_password: oldPassword,
        new_password: newPassword,
        re_enter_password: confirmPassword,
      });
      if (data?.error) {
        notify("error", data?.error?.data);
      } else {
        notify("success", "Password Updated Successfully Please login");
        sessionStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  const handleToggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClear = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <Box margin={2}>
      <img src={logo} alt="Jgate" onClick={() => navigate("/login")} />
      <Stack
        direction={"column"}
        height={"80vh"}
        justifyContent={"center"}
        sx={{
          alignItems: { xs: "start", sm: "center", md: "center", lg: "center" },
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}
          width={{
            xs: "100%",
            sm: "80%",
            md: "450px",
            lg: "450px",
            xl: "450px",
          }}
        >
          <Typography
            fontFamily={"Lora"}
            //fontSize={"34px"}
            fontWeight={"700"}
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px", lg: "30px" },
            }}
          >
            Change Password
          </Typography>

          {/* Form */}
          <Box
            component="form"
            onSubmit={handleChangePassowrd}
            width={{ xs: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box>
              <Typography sx={{ marginTop: "5px" }}>
                Old Password <span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ width: "100%", marginTop: "-3%" }}
              >
                <OutlinedInput
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginTop: "15px",
                  }}
                  type={showOldPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleOldPasswordVisibility}
                        edge="end"
                      >
                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  size={windowHeight <= 720 ? "small" : "medium"}
                />
              </FormControl>

              {(!oldPassword.trim() ||
                !newPassword.trim() ||
                !confirmPassword.trim()) &&
                errorFlag && (
                  <Typography sx={Styles.errorMessage}>
                    Passwords cannot be empty.
                  </Typography>
                )}
            </Box>
            <Box>
              <Typography sx={{ marginTop: "5px" }}>
                New Password <span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ width: "100%", marginTop: "-3%" }}
              >
                <OutlinedInput
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginTop: "15px",
                  }}
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleNewPasswordVisibility}
                        edge="end"
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  size={windowHeight <= 720 ? "small" : "medium"}
                />
              </FormControl>
              {(!oldPassword.trim() ||
                !newPassword.trim() ||
                !confirmPassword.trim()) &&
                errorFlag && (
                  <Typography sx={Styles.errorMessage}>
                    Passwords cannot be empty.
                  </Typography>
                )}
              {newPassword !== confirmPassword &&
                errorFlag &&
                newPassword.trim() &&
                confirmPassword.trim() && (
                  <Typography sx={Styles.errorMessage}>
                    New password and confirm password must match.
                  </Typography>
                )}
              {oldPassword === newPassword &&
                errorFlag &&
                oldPassword.trim() &&
                newPassword.trim() && (
                  <Typography sx={Styles.errorMessage}>
                    New password should be different from old password.
                  </Typography>
                )}
              {!whitespaceCheck.test(newPassword) ? (
                <>
                  <Typography sx={Styles.errorMessage}>
                    {errorMessage}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <Typography sx={{ marginTop: "5px" }}>
                Retype New Password <span style={{ color: "red" }}>*</span>
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ width: "100%", marginTop: "-3%" }}
              >
                <OutlinedInput
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "18px",
                    marginTop: "15px",
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  size={windowHeight <= 720 ? "small" : "medium"}
                />
              </FormControl>
              {(!oldPassword.trim() ||
                !newPassword.trim() ||
                !confirmPassword.trim()) &&
                errorFlag && (
                  <Typography sx={Styles.errorMessage}>
                    Passwords cannot be empty.
                  </Typography>
                )}
              {newPassword !== confirmPassword &&
                errorFlag &&
                newPassword.trim() &&
                confirmPassword.trim() && (
                  <Typography sx={Styles.errorMessage}>
                    New password and confirm password must match.
                  </Typography>
                )}

              {!whitespaceCheck.test(confirmPassword) ? (
                <>
                  <Typography sx={Styles.errorMessage}>
                    {errorMessage}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Box>
            <Button
              variant="contained"
              size="large"
              fullWidth
              type="submit"
              sx={{ marginTop: "20px" }}
              disabled={
                !oldPassword.length ||
                !newPassword.length ||
                !confirmPassword.length
              }
              className="commonbutton"
            >
              Submit
            </Button>
            <Item>
              <Button
                sx={{ height: "20px" }}
                className="cancelbtn"
                onClick={handleClear}
              >
                Clear
              </Button>
            </Item>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProfileChangePassword;

const Styles = {
  errorMessage: {
    color: "red",
    fontFamily: "Poppins",
    textAlign: "left",
    fontSize: "12px",
  },
};
