import Text from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../../utils/constants";
import FolderMenu from "./FolderMenu";
import MoreIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/FolderOpen";
import {
  FolderSharedByMe,
  FolderSharedToMe,
} from "../../../assets/FolderShareAssest";
import { useMyFolder } from "./MyFolder.hook";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    "& .MuiTooltip-arrow": {
      color: "#FFF",
    },
  },
}));

const MyFolder = () => {
  const {
    anchorEl,
    closeMenu,
    currentFolder,
    handleOptionClick,
    handleTableCellClick,
    myFolders,
    addModal,
  } = useMyFolder();

  return (
    <>
      <Button variant="outlined" sx={{ my: 2, ml: "auto" }} onClick={addModal}>
        Add New Folder
      </Button>

      {/* Table */}
      {/* <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell>Folder Name</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell># of Docs</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {myFolders &&
              myFolders?.map((row) => (
                <TableRow hover key={row.userfolder_id}>
                  <TableCell
                    onClick={() =>
                      handleTableCellClick({
                        id: row.userfolder_id,
                        name: row.folder_name,
                        records: row.no_of_records,
                      })
                    }
                    sx={{ padding: { xs: "6px" } }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        //alignItems: "center",
                        //display: { xs: "block", sm: "flex" },
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: {
                          xs: "start",
                          sm: "center",
                          md: "center",
                          lg: "center",
                          xl: "center",
                        },
                        padding: "6px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          //alignItems: "center",
                          //display: { xs: "block", sm: "flex" },
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: {
                            xs: "start",
                            sm: "center",
                            md: "center",
                            lg: "center",
                            xl: "center",
                          },
                        }}
                      >
                        <FolderIcon />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                          ml: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
                          width: "100%",
                        }}
                      >
                        <Text
                          fontSize={14}
                          marginLeft={{ xs: "none", sm: 1 }}
                          sx={{ cursor: "pointer" }}
                        >
                          {row.folder_name}
                        </Text>

                        {row.shared_by_me && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ml: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
                            }}
                          >
                            <FolderSharedByMe />
                            <Text
                              fontSize={"0.625rem"}
                              marginLeft={1}
                              color={Colors.primary}
                            >
                              Shared by me
                            </Text>
                          </Box>
                        )}
                        {row.shared_with_me && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // ml: 1,
                              ml: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 },
                              //width: "100%",
                              //direction: "column",
                              flexDirection: { xs: "row", sm: "row" },
                              width: "50px",
                            }}
                          >
                            <FolderSharedToMe />
                            <LightTooltip
                              title={row.shared_from}
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <Text
                                fontSize={"0.625rem"}
                                marginLeft={{
                                  xs: "-9px",
                                  sm: 1,
                                  md: 1,
                                  lg: 1,
                                  xl: 1,
                                }}
                                sx={{
                                  cursor: "default",
                                  letterSpacing: 0.4,
                                  //width: { xs: "100px", sm: "auto" },
                                  marginLeft: { xs: "2px" },
                                  //overflow: "hidden",
                                  //textOverflow: "initial",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  //overflow: "hidden",
                                }}
                              >
                                {" "}
                                Shared with me ({row.shared_from})
                              </Text>
                            </LightTooltip>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleTableCellClick({
                        id: row.userfolder_id,
                        name: row.folder_name,
                        records: row.no_of_records,
                      })
                    }
                    sx={{ padding: { xs: "6px" } }}
                  >
                    <Text fontSize={{ xs: 13, sm: 14 }}>{row.created_on}</Text>
                  </TableCell>

                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      //height: { xs: "120px", sm: "0px" },
                      //height: "auto",
                      flexDirection: { xs: "row", sm: "row" },
                      //padding: { xs: "26px", sm: "auto" },
                    }}
                  >
                    <Text>
                      {row.no_of_records ?? 0} <br />
                      {/* <br />
                      <br /> */}
      {/* </Text>

                    <IconButton onClick={(e) => handleOptionClick(e, row)}>
                      <MoreIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%", tableLayout: "fixed" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              <TableCell sx={{ width: "40%", verticalAlign: "top" }}>
                Folder Name
              </TableCell>
              <TableCell sx={{ width: "25%", verticalAlign: "top" }}>
                Creation Date
              </TableCell>
              <TableCell sx={{ width: "25%", verticalAlign: "top" }}>
                # of Docs
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {myFolders &&
              myFolders.map((row) => (
                <TableRow hover key={row.userfolder_id} sx={{ height: "100%" }}>
                  {/* Folder Name Cell */}
                  <TableCell
                    onClick={() =>
                      handleTableCellClick({
                        id: row.userfolder_id,
                        name: row.folder_name,
                        records: row.no_of_records,
                      })
                    }
                    sx={{
                      padding: "8px",
                      verticalAlign: "top",
                      wordBreak: "break-word",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <FolderIcon />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <Text
                          fontSize={14}
                          sx={{ cursor: "pointer", wordBreak: "break-word" }}
                        >
                          {row.folder_name}
                        </Text>

                        {row.shared_by_me && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              mt: 0.5,
                            }}
                          >
                            <FolderSharedByMe />
                            <Text fontSize={"0.625rem"} color={Colors.primary}>
                              Shared by me
                            </Text>
                          </Box>
                        )}

                        {row.shared_with_me && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              mt: 0.5,
                              flexWrap: "wrap", // Ensures wrapping instead of overflowing
                              maxWidth: "100%", // Restricts content within the cell
                              overflow: "hidden",
                              wordBreak: "break-word",
                            }}
                          >
                            <FolderSharedToMe />
                            <LightTooltip
                              title={row.shared_from}
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <Text
                                fontSize={"0.625rem"}
                                sx={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  wordBreak: "break-word",
                                  //maxWidth: "calc(100% - 20px)", // Ensures text stays inside cell
                                  display: "block",
                                  maxWidth: { xs: "150px", sm: "100%" },
                                }}
                              >
                                Shared with me ({row.shared_from})
                              </Text>
                            </LightTooltip>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </TableCell>

                  {/* Creation Date Cell */}
                  <TableCell
                    onClick={() =>
                      handleTableCellClick({
                        id: row.userfolder_id,
                        name: row.folder_name,
                        records: row.no_of_records,
                      })
                    }
                    sx={{
                      padding: "8px",
                      verticalAlign: "tocenter",
                      alignItems: "center",
                    }}
                  >
                    <Text fontSize={{ xs: 13, sm: 14 }}>{row.created_on}</Text>
                  </TableCell>

                  {/* # of Docs Cell */}
                  <TableCell sx={{ padding: "8px", verticalAlign: "top" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>{row.no_of_records ?? 0}</Text>
                      <IconButton onClick={(e) => handleOptionClick(e, row)}>
                        <MoreIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {currentFolder && (
        <FolderMenu
          open={Boolean(anchorEl)}
          anchor={anchorEl}
          onClose={closeMenu}
          currentFolder={currentFolder}
        />
      )}
    </>
  );
};

export default MyFolder;
