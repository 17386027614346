import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import AlphabetSearch from "./AlphabetSearch";
import { browserAutoSuggestion } from "../../api/browseJournal";
import objectToQueryString from "../browseJournal/objToQuery";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { UseFormSetValue } from "react-hook-form";
import { QBFormValues } from "../../hooks/useQBFormField";

type Props = {
  journalData: any[];
  handleCloseJournalData: any;
  onJournal: any;
  selectedLetterTagsPublisher: any;
  updateSelectedTagsPublisher: any;
  onChange: any;
  indexOf: any;
  setSelectedJournal: React.Dispatch<React.SetStateAction<string[]>>;
  selectedJournal: string[];
  fields: any[];
  setJournal: any;
  setAutoSearchKeyword: any;
  setAutoSearch: any;
  autoSearch: any;
  setJournalResult: any;
  onPaginate: any;
  setOnChange: any;
  setValue: UseFormSetValue<QBFormValues>;
};

function JournalPopUp({
  journalData,
  handleCloseJournalData,
  onJournal,
  selectedLetterTagsPublisher,
  updateSelectedTagsPublisher,
  onChange,
  indexOf,
  setSelectedJournal,
  selectedJournal,
  fields,
  setJournal,
  setAutoSearchKeyword,
  setAutoSearch,
  autoSearch,
  setJournalResult,
  onPaginate,
  setOnChange,
  setValue,
}: Props) {
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [selectedResource, setSelectedResource] = useState<string[]>([]); // To fix the issue cause by same resource name
  const storeData = (val: any, index: number) => {
    const currId = val.resource_name;
    const ids = checkedItems;

    // display purpose
    const idWithIndex = val?.resource_name + index;
    const arr = selectedResource;

    if (checkedItems.includes(currId)) {
      setCheckedItems(ids.filter((id) => id !== currId));
    } else {
      setCheckedItems([...ids, currId]);
    }

    if (selectedResource.includes(idWithIndex)) {
      setSelectedResource(arr.filter((id) => id !== idWithIndex));
    } else {
      setSelectedResource([...arr, idWithIndex]);
    }

    // ??????
  };
  const addingSearch = () => {
    const ofFields = [...fields];
    ofFields[indexOf].journalSelected = [
      // THIS LINE WAS ADDIG THE VALUE TWICE
      // ...ofFields[indexOf].journalSelected,
      checkedItems.join(", "),
    ];
    ofFields[indexOf].selectedJournal = [
      // THIS LINE WAS ADDIG THE VALUE TWICE
      // ...ofFields[indexOf].selectedJournal,
      checkedItems.join(", "),
    ];
    // Sets the Journal Text Value on to the Search Field
    setValue(
      `myFields.${indexOf}.searchTerm`,
      ofFields[indexOf].selectedJournal.join(", ")
    );
    setValue(
      `myFields.${indexOf}.selectedJournal`,
      ofFields[indexOf].journalSelected.join(", ")
    );
  };

  const beginsWithRadioBtn = () => {
    const ofFields = [...fields];
    ofFields[indexOf].start = !ofFields[indexOf].start;
    ofFields[indexOf].contain = !ofFields[indexOf].contain;
    setOnChange(ofFields);
  };

  const onChangeKeyword = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setAutoSearchKeyword(e.target.value);
    let searchedLetters: any = "";
    if (e.target.value.length >= 3) {
      // @ts-ignore
      let searchedArrayLetters = e.target.value.split(" ");
      searchedArrayLetters?.forEach((element: string) => {
        searchedLetters += `${element}\\ of\\ `;
      });

      let apiJournalData = {
        resource_name_tk: `${searchedLetters.slice(0, -6)}*`,
      };

      let response = await browserAutoSuggestion(
        objectToQueryString(apiJournalData)
      );

      setAutoSearch(
        response?.data?.data?.jsonfacets?.resource_name_tk?.buckets
      );
    }
  };

  function removeDuplicates(arr: any[]): typeof journalData {
    try {
      const uniqueArray = arr.reduce((accumulator, current) => {
        // Check if there is no object in the accumulator array with the same resource_name
        if (
          !accumulator.some(
            (obj: any) => obj?.resource_name === current?.resource_name
          )
        ) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);

      return uniqueArray;
    } catch (err) {
      return arr;
    }
  }
  return (
    <Dialog open={onJournal} maxWidth={false} onClose={handleCloseJournalData}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="h6" fontWeight={"600"} fontFamily="Lora">
            Look up Journals
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleCloseJournalData}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      {/* <DialogContent sx={{ width: 600 }}> */}
      <DialogContent
        sx={{
          width: { xs: "100%", sm: 600 },
          padding: { xs: "20px 10px", sm: "20px 24px" },
        }}
      >
        <Typography>Search</Typography>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          options={autoSearch?.map((option: any) => option.val) || []}
          sx={{ width: 300 }}
          size="small"
          onChange={() => {
            setJournalResult();
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              setJournalResult();
            }
          }}
          // @ts-ignore
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              onChange={onChangeKeyword}
              placeholder="Search"
              sx={[{ width: "100%", marginRight: "0.5%" }]}
              {...params}
            />
          )}
        />

        {/* <div
          style={{
            display: "flex",
            width: "50%",
            padding: "10px 0px 10px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              icon={<PanoramaFishEyeIcon />}
              checkedIcon={<TripOriginIcon />}
              checked={
                onChange[indexOf]?.contain === undefined
                  ? true
                  : onChange[indexOf]?.contain
              }
              onClick={() => beginsWithRadioBtn()}
            />
            <Typography>Contains</Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              icon={<PanoramaFishEyeIcon />}
              checkedIcon={<TripOriginIcon />}
              checked={onChange[indexOf]?.start}
              onClick={() => beginsWithRadioBtn()}
            />
            <Typography>Begins With</Typography>
          </div>
        </div> */}

        <Divider />

        <AlphabetSearch
          selectedTags={selectedLetterTagsPublisher}
          selectOnClick={updateSelectedTagsPublisher}
        />

        <Button
          variant="contained"
          style={{ margin: "20px 0px 20px 0px", padding: "10px" }}
          onClick={() => {
            addingSearch();
            setSelectedJournal([
              ...selectedJournal,
              ...checkedItems.map((i) => i.toString()),
            ]);
            setJournal(!onJournal);
          }}
        >
          Add to Search
        </Button>

        <div>
          <Paginate onPaginate={onPaginate}></Paginate>
        </div>

        <TableContainer
          component={Paper}
          sx={{ maxHeight: 400, overflowY: "scroll" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: "20px 0px 20px 23px" }} colSpan={2}>
                  Publication Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {removeDuplicates(journalData)?.map((row: any, i: number) => (
                <TableRow key={i}>
                  <TableCell>
                    <Checkbox
                      onClick={() => storeData(row, i)}
                      checked={selectedResource.includes(
                        row?.resource_name + i
                      )}
                    />
                    {/* <span>{row?.resource_name}</span> */}
                  </TableCell>
                  <TableCell>
                    {/* <Checkbox
                      onClick={() => storeData(row, i)}
                      checked={selectedResource.includes(
                        row?.resource_name + i
                      )}
                    /> */}
                    <Typography>{row?.resource_name}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}

export default JournalPopUp;

const Paginate = ({ onPaginate }: any) => {
  return (
    <Box
      // style={{
      //   display: "flex",
      //   justifyContent: "flex-end",
      //   alignItems: "center",
      //   marginBottom: "25px",
      // }}
      sx={{
        display: "flex",
        justifyContent: { xs: "space-between", sm: "flex-end" },
        alignItems: "center",
        marginBottom: "25px",
      }}
    >
      <Button
        onClick={() => onPaginate(-1)}
        size="small"
        variant="outlined"
        startIcon={<KeyboardArrowLeftIcon />}
        style={{
          color: "#6B7280",
          borderBlockColor: "#6B7280",
          marginRight: "10px",
          borderRadius: "4px",
        }}
      >
        Previous 15
      </Button>
      <Button
        onClick={() => onPaginate(1)}
        size="small"
        variant="outlined"
        endIcon={<KeyboardArrowRightIcon />}
        style={{
          color: "#6B7280",
          borderBlockColor: "#6B7280",
          borderRadius: "4px",
        }}
      >
        Next 15
      </Button>
    </Box>
  );
};
