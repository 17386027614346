import { createSlice } from "@reduxjs/toolkit";

export type InitialState = {
  loading: boolean;
  sourceType: string[];
  subject: string[];
  author: string[];
  speaker: string[];
  research: string[];
  journal: string[];
  countryOfPublication: string[];
  publisher: string[];
  institutions: string[];
  journalRank: string[];
  countryOfPublishingAuthor: string[];
  sort: string;
  sourceIndex: string[];
  yearFrom: string | string[];
  monthFrom: string;
  dateFrom: string;
  openAccess: boolean;
  earlyOnline: boolean;
  retractedArticle: boolean;
  datesetAvailability: boolean;
  page: number;
  rows: number;
  currentUpdate: boolean;
  selectAlphabet: string;
  collectionsValueJournal: any;
  sortName:string;
  onlyBooks:boolean
};

const initialState: InitialState = {
  loading: false,
  sourceType: [],
  subject: [],
  author: [],
  speaker: [],
  research: [],
  journal: [],
  countryOfPublication: [],
  publisher: [],
  institutions: [],
  journalRank: [],
   sort: "resource_name_tk asc",
  //sort: "sjr_score desc",
  countryOfPublishingAuthor: [],
  sourceIndex: [],
  yearFrom: "",
  monthFrom: "",
  dateFrom: "",
  openAccess: false,
  earlyOnline: false,
  retractedArticle: false,
  datesetAvailability: false,
  page: 1,
  rows: 15,
  currentUpdate: false,
  selectAlphabet: "",
  collectionsValueJournal: "",
  sortName:"Journal",
  //sortName:"SJR",
  onlyBooks:false
};

export const browserfilterSlice = createSlice({
  name: "browserfilters",
  initialState,
  reducers: {
    updateSourceType(state, { payload }) {
      state.sourceType = payload;
    },
    updateSubject(state, { payload }) {
      state.subject = payload;
    },
    updateAuthor(state, { payload }) {
      state.author = payload;
    },
    updateSpeaker(state, { payload }) {
      state.speaker = payload;
    },
    updateResearch(state, { payload }) {
      state.research = payload;
    },
    updateJournal(state, { payload }) {
      state.journal = payload;
    },
    updateCountryOfPublication(state, { payload }) {
      state.countryOfPublication = payload;
    },
    updatePublisher(state, { payload }) {
      state.publisher = payload;
    },
    updateInstitution(state, { payload }) {
      state.institutions = payload;
    },
    updateJournalRank(state, { payload }) {
      state.journalRank = payload;
    },
    updateSort(state, { payload }) {
      state.sort = payload;
    },
    updateCountryOfPublishingAuthor(state, { payload }) {
      state.countryOfPublishingAuthor = payload;
    },

    updateSourceIndex(state, { payload }) {
      state.sourceIndex = payload;
    },
    updateOpenAccess(state, { payload }) {
      state.openAccess = payload;
    },
    updateCurrentUpdate(state, { payload }) {
      state.currentUpdate = payload;
    },
    updateRetractedArticle(state, { payload }) {
      state.retractedArticle = payload;
    },
    updateDateset(state, { payload }) {
      state.datesetAvailability = payload;
    },

    updateYearFrom(state, { payload }) {
      state.yearFrom = payload;
    },
    updateMonthFrom(state, { payload }) {
      state.monthFrom = payload;
    },
    updateDateFrom(state, { payload }) {
      state.dateFrom = payload;
    },
    updateEarlyOnline(state, { payload }) {
      state.earlyOnline = payload;
    },

    updateBrowserRow(state, { payload }) {
      state.rows = payload;
    },

    updateBrowserPage(state, { payload }) {
      state.page = payload;
    },
    updatecollection(state, { payload }) {
      state.collectionsValueJournal = payload;
    },
    updateSortName(state, { payload }) {
      state.sortName = payload;
    },
    clearPublicationFilter(state) {
      state.yearFrom = "";
      state.dateFrom = "";
      state.monthFrom = "";
      state.earlyOnline = false;
    },

    clearAllFilters(state) {
      state.sourceType = [];
      state.subject = [];
      state.author = [];
      state.speaker = [];
      state.research = [];
      state.journal = [];
      state.countryOfPublication = [];
      state.publisher = [];
      state.institutions = [];
      state.journalRank = [];
      state.countryOfPublishingAuthor = [];
      state.retractedArticle = false;
      state.datesetAvailability = false;
      state.sourceIndex = [];
      state.openAccess = false;
      state.currentUpdate = false;
      // state.collectionsValueJournal = "";
    },

    clearSource(state) {
      state.sourceType = [];
    },
    clearSubject(state) {
      state.subject = [];
    },
    updateLoadingState(state, { payload }) {
      state.loading = payload;
    },
    updateselectAlphabet(state, { payload }) {
      state.selectAlphabet = payload;
    },
    updateOnlyBooks(state,{payload}){
      state.onlyBooks = payload
    }
  },
});

export const {
  updateSourceType,
  updateSubject,
  clearSource,
  clearSubject,
  updateLoadingState,
  updateAuthor,
  updateSpeaker,
  updateResearch,
  updateJournal,
  updateSourceIndex,
  updateCountryOfPublication,
  updatePublisher,
  updateInstitution,
  updateJournalRank,
  updateSort,
  updateCountryOfPublishingAuthor,
  clearAllFilters,
  updateYearFrom,
  updateMonthFrom,
  updateDateFrom,
  updateOpenAccess,
  updateEarlyOnline,
  updateRetractedArticle,
  updateDateset,
  updateBrowserPage,
  clearPublicationFilter,
  updateBrowserRow,
  updateCurrentUpdate,
  updateselectAlphabet,
  updatecollection,
  updateSortName,
  updateOnlyBooks
} = browserfilterSlice.actions;

export default browserfilterSlice.reducer;
