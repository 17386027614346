import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { Colors } from "../../utils/constants";
// Date picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  clearLimits,
  clearPublicationFilter,
  updateDateFrom,
  updateEarlyOnline,
  updateLoadingState,
  updateMonthFrom,
  updateYearFrom,
  clearMonthFromFilter,
} from "../../store/slice/filterSlice";
import { onSearch as search } from "../../screens/Search/onSearch";
import { updatePage, updateSearchResults } from "../../store/slice/searchSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import NewIcon from "../../assets/img/New.png";
import { updateQueries } from "../../store/slice/appliedQueries";
import { usagelogApi, usageReport } from "../../api/usageReportApi";
import { notify } from "../../utils/Notify";
import { goToTop } from "../../utils/helper";

const DateObj = {
  currentYear: dayjs().year(),
  month: dayjs().month() + 1,
  date: dayjs().date(),
};

interface PropType {
  publicationValue?: string;
  setPublicationValue?: any;
  clearAllLimits?: () => void;
  isOpac?: boolean;
  fromValue: string;
  toValue: string;
  setFromValue: React.Dispatch<React.SetStateAction<string>>;
  setToValue: React.Dispatch<React.SetStateAction<string>>;
  onlyBooks: boolean;
  isDspace?: boolean;
}

const PublicationDate = (props: PropType) => {
  const {
    publicationValue,
    setPublicationValue,
    clearAllLimits,
    isOpac,
    fromValue,
    toValue,
    setFromValue,
    setToValue,
    onlyBooks,
    isDspace,
  } = props;
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");

  const filters = useAppSelector((state: any) => state.filterReducer);
  const searchReducer = useAppSelector((state) => state.searchReducer);
  const dataType: any = useAppSelector((state) => state.searchReducer.dataType);
  // const dataTypeToString =
  //   Array.isArray(dataType) && dataType?.map((val) => val.toString());

  const dataTypeToString =
    Array.isArray(dataType) && dataType?.map((val) => String(val));

  const allAppliedFilter = useAppSelector(
    (state) => state.filterReducer.allAppliedFilter
  );

  const maxAllowedYear = DateObj.currentYear + 3;
  useEffect(() => {
    if (searchReducer.newSearchStatus) {
      setFromValue("");
      setToValue("");
    }
  }, [filters?.yearFrom]);

  useEffect(() => {
    if (fromValue && toValue) {
      if (+fromValue > maxAllowedYear || +toValue > maxAllowedYear) {
        // setError("Enter year less than current year");
        setError(`Entered year cannot be greater than ${maxAllowedYear}`);
      } else if (Number(toValue) < Number(fromValue)) {
        setError("From date cannot be greater than To date");
      } else if (fromValue.length > 4 || toValue.length > 4) {
        setError("The year you entered is more than four digits");
      } else {
        setError("");
      }
    }
  }, [fromValue, toValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublicationValue((event.target as HTMLInputElement).value);
    goToTop();
  };

  const profileData = useAppSelector((state: any) => state.login);

  const profileData1 = useAppSelector(
    (state: any) => state.customer.customerDetails
  );

  const selectedFilters = useAppSelector((state) => state.filterReducer);
  const is_ONOSCustomer = useAppSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isONOS =
      is_ONOSCustomer && selectedFilters.collectionValue === "ONOSCollections"
        ? 1
        : 0;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };
  function handleCurrentYear() {
    setFromValue("");
    setToValue("");
    dispatch(updateDateFrom(""));
    dispatch(updateMonthFrom(""));
    dispatch(updateEarlyOnline(false));

    let condition = "TO";
    let currentYear = `[${DateObj.currentYear} ${condition} ${DateObj.currentYear}]`;

    dispatch(updateYearFrom(currentYear));
    dispatch(
      updateQueries({
        key: "currentYear",
        value: currentYear.toString(),
      })
    );

    if (error) return;
    logBasicSearchUsageData(42);
    dispatch(search("", null, true));
  }

  function handleMonth() {
    setFromValue("");
    setToValue("");
    dispatch(updateDateFrom(""));
    dispatch(updateYearFrom(""));
    dispatch(updateEarlyOnline(false));

    // let lastOneMonth = x.subtract(1, "M").get("M") + 1;

    let lastmonth = dayjs()
      .subtract(1, "month")
      .set("year", DateObj.currentYear)
      .set("date", DateObj.date)
      .toISOString();

    if (DateObj.month === 1) {
      lastmonth = dayjs()
        .subtract(1, "month")
        .set("year", DateObj.currentYear - 1)
        .set("date", DateObj.date)
        .toISOString();
    }

    const currentMonth = dayjs()
      .set("year", DateObj.currentYear)
      .set("month", DateObj.month - 1) // Months are zero-indexed in JavaScript (January is 0)
      .set("date", DateObj.date)
      .toISOString();

    let condition = "TO";
    let yearRange = `[${lastmonth} ${condition} ${currentMonth}]`;

    dispatch(updateMonthFrom(yearRange));
    dispatch(
      updateQueries({
        key: "lastOneMonth",
        value: yearRange.toString(),
      })
    );
    if (error) return;
    logBasicSearchUsageData(40);
    dispatch(search("", null, true));
  }

  function handleDate() {
    setFromValue("");
    setToValue("");
    dispatch(updateYearFrom(""));
    dispatch(updateMonthFrom(""));
    dispatch(updateEarlyOnline(false));

    let x = dayjs();
    let lastOneWeek = x.subtract(7, "day").get("D");
    const lastWeek = dayjs()
      .subtract(1, "week")
      .set("year", DateObj.currentYear)
      .set("month", DateObj.month - 1) // Months are zero-indexed in JavaScript (January is 0)
      .set("date", lastOneWeek)
      .toISOString();

    let currentweek = dayjs()
      .subtract(1, "week")
      .set("year", DateObj.currentYear)
      .set("month", DateObj.month - 1) // Months are zero-indexed in JavaScript (January is 0)
      .set("date", DateObj.date)
      .toISOString();

    let condition = "TO";
    let weekRange = `[${lastWeek} ${condition} ${currentweek}]`;

    dispatch(
      updateQueries({
        key: "lastOneWeek",
        value: weekRange.toString(),
      })
    );

    dispatch(updateDateFrom(weekRange));
    if (error) return;
    logBasicSearchUsageData(41);
    dispatch(search("", null, true));
  }

  async function yearRange() {
    setPublicationValue("");
    dispatch(clearMonthFromFilter());
    dispatch(updateEarlyOnline(false));

    const maxAllowedYear = DateObj.currentYear + 3;
    if (
      Number(toValue) > maxAllowedYear ||
      Number(fromValue) > maxAllowedYear
    ) {
      //setError("Enter year less then current year");
      setError(`Entered year cannot be greater than ${maxAllowedYear}`);
      return;
    }
    setError("");
    if (!fromValue && !toValue) return;
    let condition = "TO";
    let yearRange = `[${fromValue} ${condition} ${toValue}]`;

    if (isOpac) {
      yearRange = `${fromValue}-${toValue}`;
    }

    if (isDspace) {
      yearRange = `[${fromValue} ${condition} ${toValue}]`;
    }

    dispatch(updateYearFrom(yearRange));
    dispatch(updatePage(1)); // Reset to Page 1
    dispatch(
      updateQueries({
        key: "yearRange",
        value: yearRange.toString(),
      })
    );
    if (error) return;
    logBasicSearchUsageData(43);
    dispatch(search("", null, true));
    goToTop();
  }

  function handleEarlyOnline() {
    // usageReport("39");
    logBasicSearchUsageData(39);
    setFromValue("");
    setToValue("");
    dispatch(updateDateFrom(""));
    dispatch(updateYearFrom(""));
    dispatch(updateMonthFrom(""));
    if (filters.earlyOnline) {
      dispatch(updateEarlyOnline(true));
      dispatch(
        updateQueries({
          key: "earlyOnline",
          value: true,
        })
      );
    } else {
      dispatch(updateEarlyOnline(true));
      dispatch(
        updateQueries({
          key: "earlyOnline",
          value: true,
        })
      );
    }
    if (error) return;

    dispatch(search("", null, true));
  }

  const showEarlyOnline =
    Array.isArray(dataTypeToString) &&
    (dataTypeToString.includes("1") ||
      dataTypeToString.includes("2") ||
      dataTypeToString.includes("3"));

  return (
    <Box sx={{ marginTop: 1 }}>
      <Box sx={webStyle.leftBox}>
        <Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                paddingBlock: "1rem",
                paddingInline: "7%",
                marginInline: "3%",
              }}
              fontSize={"20px"}
              fontWeight={"600"}
              fontFamily={"Lora"}
            >
              Limits
            </Typography>
            <Button
              onClick={() => {
                setError("");
                clearAllLimits && clearAllLimits();
                goToTop();
              }}
              sx={{fontSize:{md:"11px",lg:"14px"}}}
            >
              Clear Limits
            </Button>
          </Stack>
          <Divider />
        </Box>
        <Box sx={{ margin: "3%", paddingInline: "7%", paddingBlock: "4%" }}>
          <Typography sx={webStyle.titleHeader} gutterBottom>
            Publication Date
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={publicationValue}
            onChange={handleChange}
          >
            {!isOpac && showEarlyOnline && !isDspace && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  },
                }}
                value="EarlyOnline"
                control={<Radio />}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      fontFamily: "Lora",
                    }}
                  >
                    Early Online
                    <img src={NewIcon} style={{ width: "50px" }} />{" "}
                  </Box>
                }
                onChange={handleEarlyOnline}
              />
            )}
            {!isOpac && !onlyBooks && !isDspace && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="LastOneWeek"
                control={<Radio />}
                label="Last One Week"
                onChange={handleDate}
              />
            )}
            {!isOpac && !onlyBooks && !isDspace && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="LastOneMonth"
                control={<Radio />}
                label="Last One Month"
                onChange={handleMonth}
              />
            )}
            {!isOpac && !isDspace && (
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                    fontFamily: "Lora",
                    fontWeight: "600",
                  },
                }}
                value="CurrentYear"
                control={<Radio />}
                label="Current Year"
                onChange={handleCurrentYear}
              />
            )}
          </RadioGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction={"row"} spacing={2} marginY={2}>
              {/* <DatePicker
                views={["year", "month"]}
                label="From"
                value={fromValue}
                components={{
                  OpenPickerIcon: KeyboardArrowDownOutlinedIcon,
                }}
                onChange={(newValue) => {
                  setFromValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" helperText={null} />
                )}
              />
              <DatePicker
                views={["year", "month"]}
                label="To"
                value={toValue}
                components={{
                  OpenPickerIcon: KeyboardArrowDownOutlinedIcon,
                }}
                onChange={(newValue) => {
                  setToValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" helperText={null} />
                )}
              /> */}
              <TextField
                placeholder="From"
                size="small"
                value={fromValue}
                onChange={(e) => {
                  const regex = /^[0-9]*$/;
                  if (e.target.value.match(regex)) {
                    setFromValue(e.target.value);
                  }
                }}
                onKeyUp={(e) => {
                  if (
                    (toValue.length !== 0 && toValue.length !== 4) ||
                    (fromValue.length !== 0 && fromValue.length !== 4)
                  ) {
                    setError("Enter a valid year range");
                  } else if (
                    toValue.length === 4 &&
                    fromValue.length === 4 &&
                    !error
                  ) {
                    const yearFromFilter = allAppliedFilter.find(
                      (filter) => filter.key === "yearFrom"
                    );
                    if (yearFromFilter) {
                      notify(
                        "warning",
                        `Year filter already applied with ${yearFromFilter.value}`
                      );
                    } else {
                      yearRange();
                    }
                  } else if (
                    +fromValue > maxAllowedYear ||
                    +toValue > maxAllowedYear
                  ) {
                    // setError("Enter year less than current year");
                    setError(
                      `Entered year cannot be greater than ${maxAllowedYear}`
                    );
                  } else {
                    setError("");
                  }
                }}
              />

              <TextField
                placeholder="To"
                size="small"
                value={toValue}
                onChange={(e) => {
                  const regex = /^[0-9]*$/;
                  if (e.target.value.match(regex)) {
                    setToValue(e.target.value);
                  }
                }}
                onKeyUp={(e) => {
                  if (
                    (toValue.length !== 0 && toValue.length !== 4) ||
                    (fromValue.length !== 0 && fromValue.length !== 4)
                  ) {
                    setError("Enter a valid year range");
                  } else if (
                    toValue.length === 4 &&
                    fromValue.length === 4 &&
                    !error
                  ) {
                    const yearFromFilter = allAppliedFilter.find(
                      (filter) => filter.key === "yearFrom"
                    );
                    if (yearFromFilter) {
                      notify(
                        "warning",
                        `Year filter already applied with ${yearFromFilter.value}`
                      );
                    } else {
                      yearRange();
                    }
                  } else if (
                    +fromValue > maxAllowedYear ||
                    +toValue > maxAllowedYear
                  ) {
                    // setError("Enter year less than current year");
                    setError(
                      `Entered year cannot be greater than ${maxAllowedYear}`
                    );
                  } else {
                    setError("");
                  }
                }}
              />
            </Stack>
            {error ? (
              <Typography fontSize={12} sx={{ color: "#e02424" }}>
                {error}
              </Typography>
            ) : null}
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicationDate;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,

    // boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
