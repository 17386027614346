import { useEffect, useState } from "react";
import api from "../../api/endpoints";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Pagination from "../pagination";
import Spinner from "../spinner";
import { goToTop } from "../../utils/helper";
import { useAppSelector } from "../../store/hooks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { updateLoadingState } from "../../store/slice/filterSlice";
import { useDispatch } from "react-redux";

// Define types for the response data
interface Publisher {
  publisher_name: string;
}

interface InitalData {
  data: Array<Array<{ publisher_list: Publisher[]; no_of_records: number }>>;
}

export const LicensedPublisherData: React.FC = () => {
  const [publisherData, setPublisherData] = useState<Publisher[]>([]);
  const [noOfTotalRecords, setNoOfTotalRecords] = useState<number>(0);

  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Pagination;
  let count = Math.ceil(noOfTotalRecords / 100);
  const isLoading = useAppSelector((state) => state.filterReducer.loading);

  useEffect(() => {
    const fetchLicensedPublishers = async () => {
      dispatch(updateLoadingState(true));
      try {
        const response = await fetch(
          `${api.baseApi}/api/get_licensed_publisher_list/get_licensed_publisher_list?page_no=${page}`
        );

        const data: InitalData = await response.json();
        const publisherData = data?.data[0][0];

        setPublisherData(publisherData?.publisher_list || []);
        setNoOfTotalRecords(publisherData?.no_of_records);
        dispatch(updateLoadingState(false));
      } catch (error) {
        console.error("Error fetching Open Access Journals:", error);
      }
    };

    fetchLicensedPublishers();
  }, [page]);

  async function handlePageChange(p: number) {
    setPage(p);
    goToTop();
  }

  async function getRowsPerPage(value: number) {}

  return (
    <Stack sx={{margin:{xs:"15px",md:0},marginLeft: {md:"50px"} ,}}>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: "1.2rem" }}></ArrowBackIosIcon>
        </IconButton>

        <Typography
          variant="h5"
          alignSelf={"center"}
          fontWeight={"700"}
          fontFamily={"Lora"}
        >
          Licensed Publisher List
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: "18px", background: "#dcdde3" }}>
                Name of the Publisher
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {publisherData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div
                    id="title"
                    dangerouslySetInnerHTML={{
                      __html: row.publisher_name,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={count}
          handlePageChange={handlePageChange}
          rowsPerResult={100}
          getRowsPerPage={getRowsPerPage}
          currentPage={page}
          from="publisherList"
        />
        <Spinner isLoading={isLoading} />
      </Box>
    </Stack>
  );
};
