import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import "../styles/ResetPassword.css";
import Box, { BoxProps } from "@mui/material/Box";
import CommonButton from "./CommonButton";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { resetPasswordApi } from "../api/userApi";
import { useDispatch } from "react-redux";
import { resetpassword } from "../store/slice/loginSlice";
import { usagelogApi, usageReport } from "../api/usageReportApi";

export interface State extends SnackbarOrigin {
  open: boolean;
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      // sx={{
      //   p: 1,
      //   m: 1,
      //   width: 450,
      //   textAlign: "center",
      //   ...sx,
      // }}
      className="item-box"
      {...other}
    />
  );
}

const ResetPassword = ({ setModule }: any) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        // const response = await fetch("https://api.ipify.org?format=json");
        const response = await fetch(
          "https://diskoverapi.jgatenext.com/api/get_client_ip/"
        );
        const data = await response.json();
        setIpAddress(data?.client_ip);
      } catch (error) {
        setIpAddress("");
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const validate = () => {
    let result = true;
    if (email === "" || email === null) {
      result = false;
      setOpen(true);
      setMessage("Please Enter Email");
    } else if (!emailRegex.test(email.trim())) {
      result = false;
      setOpen(true);
      setMessage("Please Enter Valid Email");
    }
    return result;
  };

  const handleReset = async (e: any) => {
    e.preventDefault();
    setErrorFlag(true);
    console.log("Error Flag Status", errorFlag);
    if (validate()) {
      try {
        let userMasterId;
        let customerId;
        const resetPassword: any = await resetPasswordApi({ email });
        console.log("Reset Password", resetPassword);
        userMasterId = resetPassword?.user_id ? resetPassword?.user_id : null;
        customerId = resetPassword?.customer_id
          ? resetPassword?.customer_id
          : null;
        const user_ipv4_address = ipAddress ? ipAddress : null;
        dispatch(resetpassword(resetPassword));
        usagelogApi(
          userMasterId,
          customerId,
          null,
          7,
          null,
          null,
          null,
          null,
          null,
          null,
          user_ipv4_address,
          null,
          null,
          1,
          null,
          0
        );

        // usageReport("7");
        navigate(`/checkemail?email=${email}`);
      } catch (error: any) {
        setOpen(true);
        console.log(error.message);
        if (error.response.data.data) {
          setMessage(error.response.data.data);
        }
      }
    }
  };

  return (
    <>
      <div className="main_container">
        {/* {message && (
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
          </Stack>
        )} */}
        <Grid
          container
          component="main"
          sx={{
            height: "100vh",
            overflowY: "hidden",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box className="resetpassword_container">
            <Item>
              <Typography
                component="h1"
                className="heading"
                fontWeight={700}
                fontSize={34}
                fontFamily={"Lora"}
              >
                Reset Password
              </Typography>
            </Item>

            <Item>
              <Typography className="resetlink_text">
                Enter your email address to receive a password reset link.
              </Typography>
            </Item>
            <Item>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel
                  required
                  sx={{ color: "#9CA3AF", fontWeight: 500, fontSize: "18px" }}
                  htmlFor="outlined-adornment-email"
                >
                  Email
                </InputLabel>

                <OutlinedInput
                  required
                  name="email"
                  autoComplete="off"
                  id="email"
                  sx={{
                    width: "100%",
                    background: "#fffff",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "18px",
                    color: "#111928",
                  }}
                  className="textfield"
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      console.log("Enter key pressed");
                      handleReset(e);
                    }
                  }}
                  label="Email"
                />
              </FormControl>
            </Item>
            <Item>
              <CommonButton
                buttontitle="Send Password Reset Link"
                disabled={email.length === 0}
                onClick={handleReset}
              />
            </Item>
            <Item>
              <Link to="/login" className="cancelbtn">
                Cancel
              </Link>
            </Item>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default ResetPassword;

const Styles = {
  errorMessage: {
    color: "red",
    fontFamily: "Poppins",
    textAlign: "left",
    fontSize: "12px",
  },
};
