import { Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import { usagelogApi } from "../../api/usageReportApi";
import GreyOpenLock from "../../assets/images/open-gray-lock.svg";
import GreenOpenLock from "../../assets/images/open-green-lock.svg";
import { useAppSelector } from "../../store/hooks";

const DownloadButton = (props: any) => {
  const { link, articleData, resultFrom } = props;
  const fullText = useAppSelector((state) => state?.searchReducer?.fullText);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("md"));
  // console.log("fullText", fullText);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const profileData = useAppSelector((state) => state.login);

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  const is_ONOSCustomer = useAppSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;
  const logUsageAccessButtonData = (usageActionID: any, details?: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isArticleONOS = details?.is_onos_resource;
    const isONOS =
      is_ONOSCustomer &&
      (selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue ===
        "ONOSCollections" ||
        selectedCollectionFromBrowseJournalSearch.collectionsValueJournal ===
          "ONOS Collections" ||
        isArticleONOS)
        ? 1
        : 0;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };
  return (
    <Stack direction="row" spacing={2}>
      <Tooltip
        title={
          articleData?.data_type === "Pre-Print"
            ? "Preprint version"
            : articleData?.data_type === "Datasets"
            ? "Access Unknown: Check Availability"
            : "Open Access Article"
        }
      >
        {articleData?.data_type === "Datasets" ? (
          <Button
            sx={{ color: "#6b7280", borderColor: "#6b7280" }}
            variant="outlined"
            href={link && link}
            target="_blank"
            rel="noopener noreferrer"
            download={"true"}
            startIcon={<img src={GreyOpenLock} />}
            onClick={
              fullText
                ? () => logUsageAccessButtonData(35, articleData)
                : () => logUsageAccessButtonData(32, articleData)
            }
            size={isXs ? "small":"medium"}
          >
            Access Full Text
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="outlined"
            href={link && link}
            target="_blank"
            rel="noopener noreferrer"
            download={"true"}
            startIcon={<img src={GreenOpenLock} />}
            onClick={
              articleData?.data_type === "Pre-Print" && fullText
                ? () => logUsageAccessButtonData(35, articleData)
                : () => logUsageAccessButtonData(32, articleData)
            }
            size={isXs ? "small":"medium"}
          >
            Access Full Text
          </Button>
        )}
      </Tooltip>
    </Stack>
  );
};

export default DownloadButton;

const webStyles = {};
